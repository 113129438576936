import { RouterMenuLink } from "@6tm/leeloo-react/component/router/RouterMenuLink";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Header } from "./partial/Header";
import { useSelector } from "@6tm/leeloo-react/component/hook/useSelector";
import { useWriteReducer } from "@6tm/leeloo-react/component/hook/useWriteReducer";
import { ConfigReducerType } from "../../model/store/reducers/ConfigReducerType";
import { ReducerEnum } from "../../enum/store/ReducerEnum";
import { StateType } from "../../model/store/StateType";
import { AppAside } from "./partial/AppAside";

type Props = {
	children?: JSX.Element | JSX.Element[];
	composantHead?: JSX.Element | JSX.Element[];
};

/**
 * Containeur de base de toute les pages
 */
export const BaseContainer = (props: Props) => {
	const { currentLabelRoute } = useRouter();
	const { appAsideFolded, appAsideOpened } = useSelector((s: StateType) => s.config);
	const dispatch = useWriteReducer<ConfigReducerType>(ReducerEnum.config);

	return (
		<div className={"body-wrapper layout-app-aside " + ((appAsideFolded) ? "app-aside-folded " : " ")}>
			<AppAside
				onFoldAppAside={() => dispatch.set("appAsideFolded", !appAsideFolded)}
				appAsideFolded={appAsideFolded}
				onCloseAppAside={() => {
					dispatch.set("appAsideForceUnfolded", false);
					dispatch.set("appAsideFolded", false);
					dispatch.set("appAsideOpened", false);

				}}
				appAsideOpened={appAsideOpened}
			/>
			<Header onOpenAppAside={() => dispatch.set("appAsideOpened", true)} />
			<main>
				<div className="container-fluid">
					{props.composantHead}
					{props.children}
				</div>
			</main>

		</div>
	);
};
