import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum";
import { LoginFormType } from "@6tm/leeloo-react/model/authProvider/LoginFormType";

import { Alert } from "react-bootstrap";
import { SimpleContainer } from "../../layout/SimpleContainer";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import logo from "./../../../img/logo.png";

/**
 * Surchagre du visuel du login de Leeloo
 * @param props
 * @returns
 */
export const LoginForm = (props: LoginFormType) => {
	return (
		<SimpleContainer>
			<div className="text-center mb-4">
				<img src={logo} alt="Normetec" width={100}></img>
			</div>
			<div className="card card-md">
				<div className="card-body">
					<h2 className="card-title text-center mb-3">Connectez-vous à votre espace client</h2>
					{props.errorMsg && <Alert variant="danger">{props.errorMsg}</Alert>}
					<InputField label="Identifiant" name="username" className="mb-3" />

					<InputField label="Mot de passe" type="password" name="password" className="mb-2" />

					<CheckField className="mb-2" name="SeSouvenirDeMoi" label="Se souvenir de moi"></CheckField>

					<div className="card card-md">
						<div className="card-body">
							<SubmitBtn className="btn btn-primary w-100" label="Connexion" />
						</div>
					</div>
					<div className="mb-3">
						<RouterLink routeName={RouteLeelooEnum.requestForgotPassword} className="form-text mb-3">
							Mot de passe oublié ?
						</RouterLink>
					</div>
				</div>
			</div>
		</SimpleContainer>
	);
};
