import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { ClickableColumn } from "@6tm/leeloo-react/component/dataTable/column/ClickableColumn";
import { DateColumn } from "@6tm/leeloo-react/component/dataTable/column/DateColumn";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ContextForm, Form } from "@6tm/leeloo-react/component/form/Form";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { ControleDto, ListeTypeControleDto, UtilisateurSiteDto } from "../../../../_generated/clientdto.generated";
import { NomCompletColumn } from "../../../customColumn/NomCompletUtilisateur";
import { useAuth } from "../../../hook/useAuth";
import { SelectCheckRadioField } from "@6tm/leeloo-react/component/form/field/SelectCheckRadioField";
import { ControleSectionTypeEnum } from "../../../../enum/actions/ControleSectionTypeEnum";
import { ControleTypeEnum } from "../../../../enum/actions/ControleTypeEnum";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useContext, useState } from "react";
import { FormHead } from "../../partiel/FormHead";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { useModal } from "@6tm/leeloo-react/component/hook/useModal";

type Props = {
    consultationMode: boolean
    isSignature: boolean
    maintenanceUrl: string
    maintenanceMode: boolean
    isValidForm: boolean
    maintenanceId: number
    dataValidation: ControleDto
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
}
export const ControleEditValidationSignatureForm = (props: Props) => {

    const { currentParamsRoute, goTo } = useRouter();
    const form = useContext(ContextForm);
    const auth = useAuth();
    const router = useRouter();

    const { t } = useTrans();
    const toast = useToast();
    const { confirm } = useModal();
    const onTransmettreClick = async () => {
        const response = await confirm(textConfirmation());
        if (response) {
            form.submitBtnTag!.current = "valider"
            form.onChangeFieldsValues({ isValid: true })
            form.handleSubmit()
        }
    };
    const onMaintenanceClick = async () => {
        if (!form.values?.ficheMaintenances) {
            form.handleSubmit()
        }
        goTo(props.maintenanceUrl,
            {
                dossierId: currentParamsRoute.dossierId,
                siteId: currentParamsRoute.siteId,
                espaceId: currentParamsRoute.espaceId,
                EqmtId: currentParamsRoute.EqmtId,
                CtrlID: currentParamsRoute.id,
                id: props.maintenanceId
            })
    };

    function nameUser() {

        if (!form.values?.isValid) {
            return ""
        }
        if (props.dataValidation?.utilisateurSite) {
            return props.dataValidation?.utilisateurSite?.nom;
        }
        else if (form.values?.utilisateurSite?.nom) {
            return form.values?.utilisateurSite?.nom
        }
        else if (props.isSignature && oneUserData?.nom) {
            return oneUserData?.nom
        }
    }
    function organisationLabel() {

        if (!form.values?.isValid) {
            return ""
        }
        if (props.dataValidation?.utilisateurSite?.organisation?.libelle) {
            return props.dataValidation?.utilisateurSite?.organisation?.libelle;
        }
        else if (form.values?.utilisateurSite?.organisation?.libelle) {
            return form.values?.utilisateurSite?.organisation?.libelle
        }
        else if (props.isSignature && oneUserData?.organisation?.libelle) {
            return oneUserData?.organisation?.libelle
        }
    }

    // Récupérer les infos de l'utilisateur connecté
    const dsValidation = useDataSource<UtilisateurSiteDto>({
        resource: ResourceDataProviderEnum.USER_CONNECTE,
        params: {
        },
        singleResult: true,
    });
    const oneUserData = (dsValidation.oneData as UtilisateurSiteDto);
    const [useMaintenance, setUseMaintenance] = useState<boolean>(false);

    function textConfirmation() {
        const value = form.values.dateControle
        const confirmText = `Êtes-vous  sûr de vouloir signer le contrôle en date du ${new Date(value).toLocaleDateString()} ?`
        return <div>
            {confirmText}
            <dl className="row">
                <dt className="col-sm-3 form-label">Par M.</dt>
                <dd className="col-sm-9">{oneUserData.nom}</dd>

                <dt className="col-sm-3 form-label">Organisation</dt>
                <dd className="col-sm-9">
                    {oneUserData.organisation?.libelle}
                </dd>
            </dl>
        </div>
    }

    return (

        <>
            <FormHead
                enabledNextBtn={false}
                prependSaveComponent={<>
                    {/* L'invalidation d'un contrôle n'est possible que pour un administrateur  */}
                    {props.consultationMode && <SubmitBtn className="btn btn-brand-primary" icon="save" label=" Invalider" tagSubmit="invalider" />}
                    {
                        !props.consultationMode &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label=" Enregistrer" />

                    }

                    {!props.consultationMode && props.isValidForm && <button className="btn btn-brand-primary"
                        onClick={() => onTransmettreClick()}> Signer </button>
                    }
                    {
                        !props.consultationMode && !props.isValidForm &&
                        <span className="d-inline-block" data-toggle="tooltip" title="Veuillez compléter la zone d’observation et/ou la zone d’analyse">
                            <button className="btn btn-brand-primary" type="button" disabled>Signer</button>
                        </span>

                    }

                    {/* La création d'une fiche de maintenance n'est possible que si :  
                          La case à cocher Maintenance corrective est sélectionnée 
                          Le contrôle est enregistré 
                          Une maintenance n'existe pas encore pour ce contrôle  */}
                    {(useMaintenance || props.maintenanceMode) && auth.canEditCreateMaintenance(currentParamsRoute.dossierId) && < button className="btn btn-brand-primary" onClick={() => onMaintenanceClick()}><Icon name="uil uil-setting" /> Maintenance</button>}
                </>} />



            <div className="row">


                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Conformité</h3>
                        </div>
                        <div className="card-body">

                            <SelectCheckRadioField className="col-sm-9" name="conforme" label="" optionsList={[
                                { label: "Conforme", value: true },
                                { label: "Non conforme", value: false }]} disabled={props.consultationMode} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Avis du responsable</h3>
                        </div>
                        <div className="card-body">
                            <CheckField label="Mise hors service" className="mt-3" name="miseHorsService" disabled={props.consultationMode} />
                            <CheckField label="Maintenance à effectuer" className="mt-3"
                                name="maintenance" disabled={props.consultationMode || form.values.ficheMaintenances}
                                onChangeValue={(v) => {
                                    setUseMaintenance(v);
                                }} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Intervenant</h3>
                        </div>
                        <div className="card-body">
                            <DatePickerField<ControleDto>
                                label="Contrôle effectué le"
                                name="dateControle"
                                className="col-md-6 mb-3"
                                disabled={props.consultationMode}
                            />

                            <DatePickerField
                                label="Contrôle validé le"
                                name="dateSignature"
                                className="col-md-6 mb-3"
                                disabled={props.consultationMode}
                            />
                            <InputField value={nameUser} label="Par M." disabled></InputField>
                            <InputField value={organisationLabel} label="Organisation" disabled></InputField>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}