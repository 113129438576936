import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useAuth } from "../../hook/useAuth";
import { ControleAddFiles } from "../controle/actions/ControleAddFiles";
import { ControleEditAnalyse } from "../controle/actions/ControleEditAnalyse";
import { ControleEditMecanique } from "../controle/actions/ControleEditMecanique";
import { ControleEditValidationSignature } from "../controle/actions/ControleEditValidationSignature";
import { ControleEditZoneAnalyse } from "../controle/actions/ControleEditZoneAnalyse";
import { ControleEditZoneObservation } from "../controle/actions/ControleEditZoneObservation";
import { ControleDto } from "../../../_generated/clientdto.generated";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string
    canEdit: boolean
    controle?: ControleDto
}

function ControleActionNav(props: Props) {

    const { currentParamsRoute, currentRouteName, currentRoute } = useRouter();
    let current = "mecanique";
    const auth = useAuth();
    if (props.controleEdit != RouteEnum.CONTROLE_EDIT) {
        current = "observation";
    }
    if (window.location && window.location.href && window.location.href.includes('ori=validation')) {
        current = "validation";
    }
    const [currentTab, setCurrentTab] = useState(current);
    const [consultationMode, setConsultationMode] = useState(!props.canEdit);
    const [isValidFormZoneAnalyse, setisValidFormZoneAnalyse] = useState(false)
    const [isValidFormZoneOnservation, setisValidFormZoneOnservation,] = useState(false)
    var isValidForm = isValidFormZoneAnalyse && isValidFormZoneOnservation;
    const setSubmitForm = useRef<(typeAction: string) => Promise<void>>(
        async () => { }
    );

    const onSelect = async (k: any) => {

        if (!consultationMode) {
            let urlValidForm = ""
            switch (currentTab) {
                case "mecanique":
                    urlValidForm = props.resourceDataProviderEnum + "/Mecanique";
                    break;
                case "observation":
                    urlValidForm = props.resourceDataProviderEnum + "/ZoneObservation";
                    break;
                case "analyse":
                    urlValidForm = props.resourceDataProviderEnum + "/Analyses";
                    break;
                case "zoneAnalyse":
                    urlValidForm = props.resourceDataProviderEnum + "/ZoneAnalyse";
                    break;
                case "photos":
                    urlValidForm = props.resourceDataProviderEnum + "/Photo";
                    break;
                case "validation":
                    urlValidForm = props.resourceDataProviderEnum + "/ValidationSignature";
                    break;
                default:
                    break;
            }
            if (urlValidForm != "") {
                await setSubmitForm.current(urlValidForm);
            }
        }

        setCurrentTab(k);
    }
    return (
        <Tabs
            id="controlled-tab-action"
            activeKey={currentTab}
            onSelect={(k) => onSelect(k)}
            className="mb-3 bg-white"
        >
            {props.controleEdit == RouteEnum.CONTROLE_EDIT &&

                <Tab eventKey="mecanique" title="Contrôles mécaniques">
                    <ControleEditMecanique
                        resourceDataProviderEnum={props.resourceDataProviderEnum + "/Mecanique"}
                        controleEdit={props.controleEdit}
                        isEditPage={consultationMode}
                        setSubmitForm={setSubmitForm}
                        controle={props.controle}
                        onNextAction={(e) => {
                            setCurrentTab(e);
                        }} />
                </Tab>
            }
            <Tab eventKey="observation" title="Observations" tabClassName={!isValidFormZoneOnservation == true ? "text-warning" : ""}>
                <ControleEditZoneObservation
                    resourceDataProviderEnum={props.resourceDataProviderEnum + "/ZoneObservation"}
                    controleEdit={props.controleEdit}
                    isEditPage={consultationMode}
                    setSubmitForm={setSubmitForm}
                    onNextAction={(e) => {
                        setCurrentTab(e);
                    }}
                    onValidForm={(e) => {
                        setisValidFormZoneOnservation(e)
                    }} />
            </Tab>

            <Tab eventKey="analyse" title="Contrôles d’analyse">
                <ControleEditAnalyse
                    resourceDataProviderEnum={props.resourceDataProviderEnum + "/Analyses"}
                    controleEdit={props.controleEdit}
                    isEditPage={consultationMode}
                    setSubmitForm={setSubmitForm}
                    onNextAction={(e) => {
                        setCurrentTab(e);
                    }} />
            </Tab>
            <Tab eventKey="zoneAnalyse" title="Analyses" tabClassName={!isValidFormZoneAnalyse == true ? "text-warning" : ""}>
                <ControleEditZoneAnalyse
                    resourceDataProviderEnum={props.resourceDataProviderEnum + "/ZoneAnalyse"}
                    controleEdit={props.controleEdit}
                    isEditPage={consultationMode}
                    setSubmitForm={setSubmitForm}
                    onNextAction={(e) => {
                        setCurrentTab(e);
                    }}
                    onValidForm={(e) => {
                        setisValidFormZoneAnalyse(e)
                    }} />
            </Tab>

            <Tab eventKey="photos" title="Ajout de photos">
                <ControleAddFiles
                    resourceDataProviderEnum={props.resourceDataProviderEnum}
                    controleEdit={props.controleEdit}
                    isEditPage={consultationMode}
                    setSubmitForm={setSubmitForm}
                    onNextAction={(e) => {
                        setCurrentTab(e);
                    }} />
            </Tab>

            <Tab eventKey="validation" title="Validation/Signature">
                <ControleEditValidationSignature
                    resourceDataProviderEnum={props.resourceDataProviderEnum}
                    controleEdit={props.controleEdit}
                    isValidForm={isValidForm}
                    setSubmitForm={setSubmitForm}
                    onConsultation={(e) => {
                        if (props.canEdit) {
                            setConsultationMode(e);
                        }
                    }} />
            </Tab>
        </Tabs>
    );
}

export default ControleActionNav;