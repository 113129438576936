import { RouteConfig } from "./config/RouteConfig";
import { MenuRouteConfig } from "./config/MenuRouteConfig";
import { LeelooSpa } from "@6tm/leeloo-react/LeelooSpa";
// STYLE VISUEL
import "./scss/main.scss";
import { StoreConfig } from "./config/StoreConfig";
import { LoginForm } from "./components/page/auth/LoginForm";
import { ODataProvider } from "@6tm/leeloo-react/dataProvider/ODataProvider";
import { NormetecAuthProvider } from "./authProvider/NormetecAuthProvider";
import { MonAppFr } from './config/traduction/MonAppFr';
import { UniconIconRender } from '@6tm/leeloo-react/component/display/iconRender/UniconIconRender';
import { LangAvailableEnum } from "./enum/LangAvailableEnum";
import { LocalTranslatorProvider } from "@6tm/leeloo-react/translatorProvider/LocalTranslatorProvider";
import { RequestForgotPasswordForm } from "./components/page/auth/RequestForgotPasswordForm";
import { RouteEnum } from "./enum/RouteEnum";
import { LoginType } from "@6tm/leeloo-react/model/authProvider/LoginType";
import { ResetForgotPasswordForm } from "./components/page/auth/ResetForgotPasswordForm";
import { JwtAuthProvider } from "@6tm/leeloo-react/authProvider/JwtAuthProvider";
import { LangLeelooAvailableEnum } from "@6tm/leeloo-react/enum/lang/LangLeelooAvailableEnum";

function App() {

	const test = NormetecAuthProvider;
	return (
		<div className="App">
			<LeelooSpa
				apiUrl={process.env.REACT_APP_API_URL!}
				authProvider={NormetecAuthProvider}
				routeConfig={RouteConfig}
				storeConfig={StoreConfig}
				menuConfig={MenuRouteConfig}
				dataProvider={ODataProvider}
				componentConfig={{
					iconRender: UniconIconRender // exemple usage de la lib unicon
				}}
				// url de login externe a l'APP pour l'authentification
				autoConnect={{
					externalUrlRedirectLoginForm: process.env.REACT_APP_URL!,
					resourceApiLogin: "/auth/login/connectAsUser",
				}}
				loginForm={LoginForm}
				// définition des données de traduction
				translator={{
					defaultLang: LangLeelooAvailableEnum.fr, //Utilser "en-GB" au lieu de juste "en" pour avoir le format des date en DD/MM/YYYY
					langAvailable: [LangLeelooAvailableEnum.fr],
					provider: LocalTranslatorProvider,
					configOfflineTrad: {
						[LangLeelooAvailableEnum.fr]: MonAppFr,
					},
				}}
				// translator={{
				// 	defaultLang: LangAvailableEnum.FR,
				// 	langAvailable: [
				// 		LangAvailableEnum.FR
				// 	],
				// 	provider: LocalTranslatorProvider,
				// 	configOfflineTrad: {
				// 		[LangAvailableEnum.FR]: MonAppFr
				// 	}
				// }}
				defaultPrivateRoute={(d: any) => {
					if (d.isFirstConnection) {
						return RouteEnum.UPDATE_PASSWORD;
					}

					return RouteEnum.APP_HOME;
				}}
				requestForgotPasswordForm={RequestForgotPasswordForm}
				resetForgotPasswordForm={ResetForgotPasswordForm}
			/>
		</div>
	);
}
export default App;
