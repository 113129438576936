import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { ControleDto } from "../../../../_generated/clientdto.generated";
import { useAuth } from "../../../hook/useAuth";
import { SelectCheckRadioField } from "@6tm/leeloo-react/component/form/field/SelectCheckRadioField";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { CollectionFormFieldMecanique } from "./CollectionFormFieldMecanique";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { FormHead } from "../../partiel/FormHead";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string
    isEditPage: boolean
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
    controle?: ControleDto
    onNextAction: (nextAction: string) => void

}

export const ControleEditMecanique = (props: Props) => {

    const { currentParamsRoute, currentRouteName, goTo } = useRouter();
    const breadcrumb = useBreadcrumb();
    const auth = useAuth();
    const router = useRouter();

    const { t } = useTrans();
    const toast = useToast();
    const [saveInfo, setSaveInfo] = useState<ControleDto>({})
    const isFontionnel = props.controleEdit == RouteEnum.CONTROLE_EDIT_OPERATIONNEL ? true : false;
    const isAnnuel = props.controleEdit == RouteEnum.CONTROLE_EDIT ? true : false;
    const [changeFormulaire, setChangeFormulaire] = useState(false);

    return (

        <Form
            initialValue={saveInfo}
            id={currentParamsRoute?.id}
            profilerName="formControleMecanique"
            resource={props.resourceDataProviderEnum}
            onChangeData={(e, ret) => {
                setChangeFormulaire(true);
                setSaveInfo(e)
            }}
            onSubmit={async (e, ret) => {
                if (!e?.eqmtId) {
                    e!.eqmtId = currentParamsRoute.EqmtId;
                }
            }}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(props.controleEdit,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: ret.newId.toString()
                        });
                }
                setChangeFormulaire(false);
                toast("form.infosUpdated", "success");
                if (ret.submitTag != "setSubmitForm") {
                    props.onNextAction("observation");
                }
            }}
            onDataApiLoaded={async (d: ControleDto | undefined) => {

            }}
            validationSchema={{

            }}
        >

            <FormHead
                enabledNextBtn={!props.isEditPage}
                changeForm={changeFormulaire}
                setSubmitForm={props.setSubmitForm}
                prependSaveComponent={<>
                    {props.isEditPage && <button className="btn btn-brand-primary" onClick={() => props.onNextAction("analyse")}>
                        {"Suivant "} <Icon name="uil uil-angle-right-b" />
                    </button>}
                </>} />
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"></h3>
                        </div>
                        <div className="card-body" style={{ display: isAnnuel == true ? "" : "none" }}>
                            <InputField<ControleDto> className="mb-2" label="Hauteur avant essai" name="hauteurAvtEssai" disabled={props.isEditPage} />
                            <InputField className="mb-2" label="Hauteur après essai" name="hauteurApEssai" disabled={props.isEditPage} />
                        </div>
                    </div>
                </div>
                {
                    isAnnuel &&
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Contrôles mécaniques</h3>
                            </div>
                            <div className="card-body">
                                <CollectionFormFieldMecanique
                                    resourceDataProviderEnum={props.resourceDataProviderEnum}
                                    nameFieldOutput="controlesMecaniques"
                                    disabled={props.isEditPage}
                                    controle={props.controle}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>
        </Form>
    );
}