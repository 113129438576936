import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { ColumnType } from "@6tm/leeloo-react/model/dataTable/ColumnType";

/**
 * Afficher l'adresse postable complète
 * @param props 
 * @returns 
 */
export function BooleanColumnIcon<T>(props: ColumnType<T>) {

    if (!props.row) {
        return <></>;
    }

    let value = props.row![props.field!] as boolean

    if (props.field == "isole" && value) {
        value = false
    }
    else if (props.field == "isole" && !value) {
        value = true
    }
    return <>
        <BooleanColumn field={props.field} title={props.title}
            sortable iconFalse={"uil uil-times-circle"}
            iconTrue={"uil uil-check-circle"}
            value={value} />
    </>;
};

export function BooleanColumnMaintenance<T>(props: ColumnType<T>) {

    let value = props.value as boolean

    if (props.field == "maintenance" && value) {
        value = false
    }
    else if (props.field == "maintenance" && !value) {
        value = true
    }
    return <>
        <BooleanColumn field={props.field} title={props.title}
            sortable iconFalse={"uil uil-check-circle"}
            iconTrue={"uil uil-times-circle"}
            value={value}
        />
    </>;
};