import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { SiteDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect } from "react";
import { AdressePostaleComplete } from "../../customColumn/AdressePostaleCompleteColumn";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";

export const SiteConsultationPage = () => {

    const { currentParamsRoute } = useRouter();

    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });
    const oneDataSite = (dsSite.oneData as SiteDto);

    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb

        if (breadcrumb.isReady && oneDataSite) {

            const routes = [
                {
                    route: RouteEnum.APP_HOME,
                    icon: "home"
                },
                {
                    textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: `Site : ${oneDataSite?.nom}`, route: RouteEnum.SITES_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.id,
                        dossierId: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: "Consultation",
                }
            ];

            breadcrumb.resetAndSet(routes);
        }

    }, [breadcrumb.isReady && oneDataSite])

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.SITES_CONSULTATION}
                                        params={{
                                            dossierId: currentParamsRoute.dossierId, id: currentParamsRoute?.id
                                        }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ESPACES_LISTE}
                                        params={{
                                            dossierId: currentParamsRoute.dossierId, id: currentParamsRoute?.id
                                        }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                <div className="sticky-actions-inner">
                    <div className="d-flex gap-2 align-items-center">
                        <div className="btn-list ms-auto">
                            <RouterLink className="btn" routeName={RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION}
                                params={{
                                    id: currentParamsRoute?.dossierId
                                }}><i className="uil uil-angle-left" />Retour</RouterLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Informations du site</h3>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="form-label info">Nom du site</label>
                                    <label>{oneDataSite?.nom}</label>
                                    <label className="form-label info">Numéro du site</label>
                                    <label>{oneDataSite?.numero}</label>

                                    <label className="form-label info">Numero de site client</label>
                                    <label>{oneDataSite?.numeroClient}</label>
                                </div>

                                <div className="col-sm-6">
                                    <label className="form-label info">Adresse</label>
                                    <AdressePostaleComplete adresse={oneDataSite?.adresse} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card" style={{ height: "100%" }}>
                        <div className="card-header">
                            <h3 className="card-title">Plan</h3>
                        </div>
                        <div className="card-body" style={{ textAlign: "center" }}>
                            {oneDataSite?.urlPlan == null &&

                                "Aucune image"
                            }
                            {oneDataSite?.urlPlan &&
                                <img
                                    className="img1"
                                    src={oneDataSite?.urlPlan}
                                    style={{ height: "100%" }}
                                    onClick={() => openInNewTab(oneDataSite?.urlPlan)}

                                />}

                        </div>
                    </div>
                </div>
            </div>
        </BaseContainer >
    );
}