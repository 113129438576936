import React, { useEffect, useMemo, useState } from "react";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useAuthProvider } from "@6tm/leeloo-react/component/hook/useAuthProvider";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { LeelooConfig } from "@6tm/leeloo-react/Leeloo";
import { Logo } from "./Logo";
import { LogoutLink } from "@6tm/leeloo-react/component/auth/logout/LogoutLink";
import { ThumbDisplay } from "../../display/ThumbDisplay";
import { Breadcrumb } from "@6tm/leeloo-react/component/display/Breadcrumb";

/**
 * @returns
 */
export const Header = (props: { onOpenAppAside: () => void }) => {
	const { currentLabelRoute } = useRouter();
	const { authData, checkAccess } = useAuthProvider()! as any;

	const { breadcrumb, initBreadcrumb, isReady } = useBreadcrumb();

	useEffect(initBreadcrumb, []);

	const previousRouteConfigCurrent = useMemo(() => {
		if (breadcrumb.length > 1) {
			return LeelooConfig.routeConfig.find((rc) => rc.routeUrl == breadcrumb[0].route);
		} else {
			return null;
		}
	}, [breadcrumb]);

	return (
		<header className="app-header">
			<div className="container-fluid">
				<a className="toggle-app-aside" href="#" onClick={props.onOpenAppAside}>
					<i className="uil uil-bars"></i>
				</a>

				<Logo />
				<Breadcrumb />
				<span className="ms-auto"></span>
				<div className="d-flex me-2">
					{/* <a href="#" className="nav-link px-1">
						<i className="uil uil-envelope fs-3"></i>
					</a> */}
					<div className="nav-item dropdown">
						<a href="#" className="nav-link px-1" data-bs-toggle="dropdown" aria-label="Afficher les notifications" aria-expanded="false" style={{ display: "none" }}>
							<i className="uil uil-bell fs-3"></i>
							{/* <span className="badge bg-red"></span> */}
							<span className="badge"></span>
						</a>
					</div>
				</div>

				<div className="nav-item dropdown">
					{/* <button className="nav-link d-flex lh-1" data-bs-toggle="dropdown" aria-label="Ouvrir le menu utilisateur">
						<ThumbDisplay text={[authData.firstname, authData.lastname]} />

						<div className="d-none d-xl-block ps-2">
							<div className="fw-bold">
								{authData.firstname} {authData.lastname}
							</div>
						</div>
					</button> */}
					<a href="#" className="nav-link d-flex lh-1" data-bs-toggle="dropdown" aria-label="Ouvrir le menu utilisateur" style={{ paddingRight: 10 }}>
						<ThumbDisplay text={[authData.firstname, authData.lastname]} />

						<div className="d-none d-xl-block ps-2">
							<div className="fw-bold">
								{authData.firstname} {authData.lastname}
							</div>
							{/* <div className="mt-1 small text-muted">Administrateur</div> */}
						</div>
					</a>
				</div>

				<LogoutLink className="btn-logout cursor-pointer">
					<i className="uil  uil-sign-out-alt ms-auto">&nbsp;</i>
				</LogoutLink>
			</div>
		</header>
	);
};
