import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useEffectDsReady } from "@6tm/leeloo-react/component/hook/useEffectDsReady";
import { useEffect, useState } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { VilleDto } from "../../../_generated/clientdto.generated";

type Props = {
    villeId: number,
}

export const AdresseField = (props: Props) => {

    const dsVille = useDataSource<VilleDto>({ resource: ResourceDataProviderEnum.USERS_ALL_CITY, params: { expand: { field: ["Departement/Region"] } }, profilerName: "ville" });

    const [dptState, setDptState] = useState<string | null | undefined>("");
    const [regionState, setRegionState] = useState<string | null | undefined>("");

    useEffect(() => {
        if (dsVille.isReady) {
            const ville = dsVille?.dataList?.find(s => s.id == props.villeId);
            setDptState(ville?.departement?.libelle);
            setRegionState(ville?.departement?.region?.libelle);
        }
    }, [dsVille.isReady])

    return (
        <>
            <InputField className="mb-2" label="form.adresse.adresse1" name="adresse.adressel1" />
            <InputField className="mb-2" label="form.adresse.adresse2" name="adresse.adressel2" />
            <InputField className="mb-2" label="form.adresse.adresse3" name="adresse.adressel3" />
            <InputField className="mb-2" label="form.adresse.adresse4" name="adresse.adressel4" />
            <InputField className="mb-2" label="form.adresse.tel" name="adresse.adressetel1" />
            <SelectField
                className="mb-3"
                name="adresse.villeId"
                label="adresse.ville"
                isSearchable={true}
                onChangeValue={(d) => {
                    const ville = dsVille?.dataList?.find(s => s.id == d);
                    setDptState(ville?.departement?.libelle);
                    setRegionState(ville?.departement?.region?.libelle);
                }}
                // la resource externe sous forme de dataSource pour charger les données
                externalResource={{ dataSource: dsVille, labelKey: "libellePlusCp", valueKey: "id" }}
            />
            <div>Département : {dptState}</div>
            <div>Région : {regionState}</div>
        </>
    );
}