import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EspaceDto, SiteDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { useAuth } from "../../hook/useAuth";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";

export const ListeEspacesPage = () => {

    const { currentParamsRoute, currentRouteName } = useRouter();
    const dsEspaces = useDataSource({ resource: ResourceDataProviderEnum.ESPACES, params: { pagination: { page: 1, take: 50 }, expand: { field: ["TypeZone", "TypeSol"] }, filter: { siteId: currentParamsRoute?.id } }, profilerName: "numero" });

    const [consultation, setConsultation] = useState(false);

    useEffect(() => {
        if (currentRouteName === RouteEnum.SITES_CONSULTATION) {
            setConsultation(true);
        } else {
            setConsultation(false);
        }
    }, [])

    const router = useRouter();
    const auth = useAuth();
    const breadcrumb = useBreadcrumb();
    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });
    const oneDataSite = (dsSite.oneData as SiteDto);
    useEffect(() => {
        if (breadcrumb.isReady && oneDataSite) {

            const routes = [
                {
                    route: RouteEnum.APP_HOME,
                    icon: "home"
                },
                {
                    textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: `Site : ${oneDataSite?.nom}`, route: RouteEnum.SITES_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.id,
                        dossierId: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: 'Liste des espaces',
                }
            ];

            breadcrumb.resetAndSet(routes);
        }

    }, [breadcrumb.isReady && oneDataSite])
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <RouterLink className="nav-link"
                                        routeName={RouteEnum.SITES_CONSULTATION}
                                        params={{
                                            id: currentParamsRoute?.id,
                                            dossierId: currentParamsRoute.dossierId
                                        }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={consultation === false ? RouteEnum.ESPACES_LISTE : RouteEnum.LIST_EQUIPEMENT}
                                        params={{
                                            siteId: currentParamsRoute?.id,
                                            dossierId: currentParamsRoute.dossierId,
                                            id: currentParamsRoute.dossierId,
                                        }}>
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="card">
                <div className="card-body centerHeader">
                    <DataTable
                        dataSource={dsEspaces}
                        // filter={
                        //     <Filter>
                        //         <InputField label="Rechercher" name="numero" typeFilter="equal" type="number" />
                        //     </Filter>
                        // }
                        actionRow={(r) => [
                            <LinkColumnAction
                                label="Consulter"
                                route={RouteEnum.ESPACES_CONSULTATION}
                                params={{
                                    id: r.id,
                                    siteId: currentParamsRoute?.id,
                                    dossierId: currentParamsRoute.dossierId,
                                }}
                                className="btn btn-sm btn-outline-primary"
                                icon="uil uil-eye"
                            />,
                            ...auth.canEdit(currentParamsRoute.dossierId) == true ?
                                [<LinkColumnAction
                                    label="Editer"
                                    route={RouteEnum.ESPACES_EDIT}
                                    params={{ id: r.id, dossierId: currentParamsRoute.dossierId, siteId: currentParamsRoute?.id }}
                                    className="btn btn-sm btn-outline-primary"
                                    icon="uil uil-edit"
                                />] : []
                        ]
                        }
                        allowDelete={(row) => auth.isAdmim() == true}
                        addRoute={auth.canAdd(currentParamsRoute.dossierId) == true ?
                            router.generatePath(RouteEnum.ESPACES_ADD, RouteEnum.ESPACES_ADD, {
                                dossierId: currentParamsRoute.dossierId,
                                siteId: currentParamsRoute?.id,
                            }) : undefined}
                    >
                        <SimpleColumn<EspaceDto> field="numero" title="Numéro" sortable />
                        <SimpleColumn field="typeZone.libelle" title="Type Zone" sortable />
                        <SimpleColumn field="typeSol.libelle" title="Type Sol" sortable />
                    </DataTable>
                </div>
            </div>
        </BaseContainer>

    );
}