
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { useAuth } from "../../hook/useAuth";
import { ControleByType } from "../controle/ControleByType";


function ControleNav() {

    const auth = useAuth();
    const { currentParamsRoute } = useRouter();
    const [key, setKey] = useState('principal');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k !== null ? k : "principal")}
            className="mb-3 bg-white"
        >

            <Tab eventKey="principal" title="Contrôles principaux">
                <ControleByType typeAction={ControleTypeEnum.ControlePrincipal}
                    canEdit={auth.canShowCa(currentParamsRoute.dossierId)} />
            </Tab>

            <Tab eventKey="operationnel" title="Contrôles opérationnels">
                <ControleByType typeAction={ControleTypeEnum.ControleOperationnel}
                    canEdit={auth.canShowCf(currentParamsRoute.dossierId)} />
            </Tab>

            <Tab eventKey="routine" title="Contrôles de routine">
                <ControleByType typeAction={ControleTypeEnum.ControleRoutine}
                    canEdit={auth.canShowCr(currentParamsRoute.dossierId)} />
            </Tab>

        </Tabs>
    );
}

export default ControleNav;
