import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { FabriquantDto, VilleDto } from "../../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import * as Yup from 'yup';
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { AdresseField } from "../../../form/field/AdresseField";

export const FabriquantEditAddPage = () => {

    const { currentParamsRoute, goTo } = useRouter();
    const { t } = useTrans();
    const toast = useToast();
    const [villeId, setVilleId] = useState(0);
    const [initValue, setInitValue] = useState({})
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_FABRIQUANTS_EDIT} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <Form
                initialValue={initValue}
                resource={ResourceDataProviderEnum.FABRIQUANTS}
                id={currentParamsRoute?.id}
                onSubmitEndUpdate={(e, ret) => {
                    // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(RouteEnum.ADMIN_FABRIQUANTS_EDIT.replace(":mode", "new").replace(":id", ret.newId.toString()));
                    }
                    toast("form.infosUpdated", "success");
                }}
                onDataApiLoaded={async (d: FabriquantDto | undefined) => {
                    if (d?.adresse?.villeId != null && d?.adresse?.villeId != undefined) {
                        setVilleId(d?.adresse?.villeId)
                    }
                }}
                onChangeData={(e, ret) => {
                    setInitValue(e!)
                }}
                paramsResource={{ expand: { field: ["Adresse"] } }}
                validationSchema={{
                    nom: Yup.string().required("Le nom est requis"),
                    adresse: Yup.object().shape({
                        villeId: Yup.number().required("La ville est requise"),
                    }),
                }}
            >
                <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                    <div className="sticky-actions-inner">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn-list ms-auto">
                                <RouterLink className="btn" routeName={RouteEnum.ADMIN_FABRIQUANTS_LIST} params={{ id: currentParamsRoute?.id }}><i className="uil uil-angle-left"></i></RouterLink>
                                <SubmitBtn className="btn btn-primary" >
                                    {t("actions.save")}
                                </SubmitBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Informations du fabriquant</h3>
                            </div>
                            <div className="card-body">
                                <InputField<FabriquantDto> className="mb-2" label="form.nom" name="nom" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Adresse</h3>
                            </div>
                            <div className="card-body">
                                <AdresseField villeId={villeId} />
                            </div>

                        </div>
                    </div>
                </div>
            </Form>
        </BaseContainer>

    );
}