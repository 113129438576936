
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import moment from "moment";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { useState } from "react";
import { CustomRenderColumn } from "@6tm/leeloo-react/component/dataTable/column/CustomRenderColumn";
import { ClickableColumn } from "@6tm/leeloo-react/component/dataTable/column/ClickableColumn";
import { RegistreSecuriteDto } from "../../../_generated/clientdto.generated";
import { DateColumn } from "@6tm/leeloo-react/component/dataTable/column/DateColumn";
import { RouteEnum } from "../../../enum/RouteEnum";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { BooleanColumnIcon, BooleanColumnMaintenance } from "../../customColumn/BooleanColumnIcon";


export const RegistreSecuriteListPage = () => {

    const { currentParamsRoute } = useRouter();


    const [conforme, setConforme] = useState(null)

    const dsRegistreSecurite = useDataSource({
        resource: ResourceDataProviderEnum.REGISTRE_SECURITE, params: {
            id: currentParamsRoute?.id, pagination: { page: 1, take: 50 },
            filter: { dateControle: moment().format(), conforme: conforme }
        },
        // avant la requete on bind le uriParameters sur les filter
        applyParamsBeforeRefresh: (p) => {
            p.uriParameters = { ...p.filter };
            delete p.filter;
        },
        profilerName: "registre"
    }
    );


    const router = useRouter();

    const onClickMaintenance = (value: RegistreSecuriteDto) => {
        let url = "";
        switch (value.typeControle) {
            case ControleTypeEnum.ControlePrincipal:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_ANNUEL_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_ANNUEL_EDIT;
                }
                break;
            case ControleTypeEnum.ControleOperationnel:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT;
                }
                break;
            case ControleTypeEnum.ControleRoutine:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_ROUTINE_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_ROUTINE_EDIT;
                }
                break
            default:
                return 'Contrôle inconnu';
        }
        router.goTo(url, {
            dossierId: currentParamsRoute.id,
            siteId: value?.eqmt?.espace?.site?.id,
            espaceId: value?.eqmt?.espace?.id,
            EqmtId: value?.eqmt?.id,
            CtrlID: value?.id,
            id: value?.ficheMaintenances?.id
        })

    }

    const onClickControle = (value: RegistreSecuriteDto) => {
        let url = "";
        switch (value.typeControle) {
            case ControleTypeEnum.ControlePrincipal:
                url = RouteEnum.CONTROLE_EDIT;
                break;
            case ControleTypeEnum.ControleOperationnel:
                url = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;
                break;
            case ControleTypeEnum.ControleRoutine:
                url = RouteEnum.CONTROLE_EDIT_ROUTINE;
                break
            default:
                return 'Contrôle inconnu';
        }
        router.goTo(url, {
            dossierId: currentParamsRoute.id,
            siteId: value?.eqmt?.espace?.site?.id,
            espaceId: value?.eqmt?.espace?.id,
            EqmtId: value?.eqmt?.id,
            id: value?.id
        })

    }
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Registre de sécurité</h3>
                        </div>
                        <div className="card-body centerHeader">
                            <DataTable
                                dataSource={dsRegistreSecurite}
                                filter={
                                    <Filter>
                                        <DatePickerField label="Registre de sécurité à la date" name="dateControle" />
                                        <SelectField
                                            label="Conformité"
                                            name="conforme"
                                            optionsList={[
                                                { label: "Conforme", value: true },
                                                { label: "Non Conforme", value: false }
                                            ]}
                                            onChangeValue={(d) => { setConforme(d) }}
                                        />
                                    </Filter>
                                }
                            >
                                <ClickableColumn
                                    title="Site"
                                    field="eqmt.espace.site.nom"
                                    columnElement={<SimpleColumn />}
                                    onClick={(r, v) => {
                                        const value = r as RegistreSecuriteDto;
                                        router.goTo(router.generatePath(RouteEnum.SITES_CONSULTATION, RouteEnum.SITES_CONSULTATION, {
                                            dossierId: currentParamsRoute.id,
                                            id: value.eqmt?.espace?.site?.id
                                        }))
                                    }
                                    }
                                />
                                <SimpleColumn field="eqmt.espace.numero" title="Espace" sortable />
                                <ClickableColumn
                                    title="Immat."
                                    field="eqmt.reference"
                                    columnElement={<SimpleColumn />}
                                    onClick={(r, v) => {
                                        const value = r as RegistreSecuriteDto;
                                        router.goTo(router.generatePath(RouteEnum.LIST_CONTROLE, RouteEnum.LIST_CONTROLE, {
                                            dossierId: currentParamsRoute.id,
                                            siteId: value?.eqmt?.espace?.site?.id,
                                            espaceId: value?.eqmt?.espace?.id,
                                            id: value?.eqmt?.id
                                        }))
                                    }
                                    }
                                />
                                <CustomRenderColumn<RegistreSecuriteDto>
                                    title="Dernier contrôle"
                                    field="typeControle"
                                    render={(row, value: number) => {
                                        switch (value) {
                                            case ControleTypeEnum.ControlePrincipal:
                                                return "Contrôle principal"
                                            case ControleTypeEnum.ControleOperationnel:
                                                return "Contrôle opérationnel";
                                            case ControleTypeEnum.ControleRoutine:
                                                return "Contrôle de routine";
                                            default:
                                                return 'Contrôle inconnu';
                                        }
                                    }}
                                />
                                <ClickableColumn
                                    title="Réalisé le"
                                    field="dateControle"
                                    columnElement={<DateColumn format="DD/MM/YYYY" />}
                                    onClick={(r) => onClickControle(r)
                                    }
                                />
                                <DateColumn field="dateSignature" title="Signé le" sortable format="DD/MM/YYYY" />
                                <ClickableColumn
                                    title="Maintenance"
                                    field="maintenance"
                                    columnElement={

                                        <BooleanColumnMaintenance field="maintenance" />
                                    }
                                    allowClickable={(r) => r.maintenance == true}
                                    onClick={(r) => onClickMaintenance(r)
                                    }
                                />

                                <ClickableColumn
                                    title="Maint. transmise le"
                                    field="ficheMaintenances.dateTransmission"
                                    columnElement={<DateColumn format="DD/MM/YYYY" />}
                                    allowClickable={(r) => r != null}
                                    onClick={(r) => onClickMaintenance(r)
                                    }
                                />
                                <BooleanColumnIcon field="conforme" title="Conforme" sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}