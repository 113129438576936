import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { DossierDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { AdressePostaleComplete } from "../../customColumn/AdressePostaleCompleteColumn";
import { RegistreSecuriteListPage } from "./RegistreSecuriteListPage";
import { useEffect } from "react";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";

export const DossierConsultationPage = () => {

    const { currentParamsRoute } = useRouter();

    const dsDossier = useDataSource<DossierDto>({
        resource: ResourceDataProviderEnum.DOSSIER,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });

    const oneDataDossier = (dsDossier.oneData as DossierDto);

    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb
        if (breadcrumb.isReady && oneDataDossier) {
            breadcrumb.updateNode(1, {
                textCustom: `Dossier : ${oneDataDossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.id
                }
            });
        }

    }, [breadcrumb.isReady && dsDossier.isReady])

    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>

                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOCUMENTS} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                <div className="sticky-actions-inner">
                    <div className="d-flex gap-2 align-items-center">
                        <div className="btn-list ms-auto">
                            <RouterLink className="btn" routeName={RouteEnum.DOSSIERS_LISTE}><i className="uil uil-angle-left" />Retour</RouterLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Informations du dossier</h3>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="form-label info">Référence du dossier</label>
                                    <label>{oneDataDossier?.reference}</label>
                                    <br></br>
                                    <label className="form-label info">Nom</label>
                                    <label>{oneDataDossier?.nom}</label>

                                    <label className="form-label info">Interlocuteur</label>
                                    <label>{oneDataDossier?.interloc}</label>

                                    <label className="form-label info">Type de client</label>
                                    <label>{oneDataDossier?.typeClient?.libelleWithInterlocuteur}</label>

                                    <label className="form-label info">Date de création</label>
                                    <label>{oneDataDossier?.dateCreation && new Date(oneDataDossier?.dateCreation).toLocaleDateString()}</label>
                                </div>

                                <div className="col-sm-6">
                                    <label className="form-label info">Contrat</label>
                                    <label>{oneDataDossier?.contrat?.typeContrat?.libelle}</label>

                                    <label className="form-label info">Date de début du contrat</label>
                                    <label>{oneDataDossier?.contrat?.dateDebut && new Date(oneDataDossier?.contrat?.dateDebut).toLocaleDateString()}</label>


                                    <label className="form-label info">Date de fin du contrat</label>
                                    <label>{oneDataDossier?.contrat?.dateFin && new Date(oneDataDossier?.contrat?.dateFin).toLocaleDateString()}</label>

                                    <label className="form-label info">Adresse</label>
                                    <AdressePostaleComplete adresse={oneDataDossier?.adresse} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RegistreSecuriteListPage />
        </BaseContainer >
    );
}