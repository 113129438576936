import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { SiteDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { useAuth } from "../../hook/useAuth";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { AdressePostaleCompleteColumn } from "../../customColumn/AdressePostaleCompleteColumn";

export const DossierListSitesPage = () => {

    const { currentParamsRoute, currentRouteName, goBack } = useRouter();
    const dsSites = useDataSource({ resource: ResourceDataProviderEnum.SITES, params: { pagination: { page: 1, take: 50 }, expand: { field: ["Dossier"] }, filter: { dossierId: currentParamsRoute?.id } }, profilerName: "nom" });

    const [consultation, setConsultation] = useState(false);
    useEffect(() => {
        if (currentRouteName === RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION) {
            setConsultation(true);
        } else {
            setConsultation(false);
        }
    })
    const auth = useAuth();
    const router = useRouter();

    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb
        if (breadcrumb.isReady && dsSites.dataList && dsSites.dataList.length > 0) {
            const oneDataSite = (dsSites.dataList[0] as SiteDto);
            breadcrumb.updateNode(1, {
                textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.id
                }
            });
        }

    }, [breadcrumb.isReady && dsSites.isReady])

    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={consultation === false ? RouteEnum.DOSSIERS_EDIT : RouteEnum.DOSSIERS_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={consultation === false ? RouteEnum.DOSSIERS_LIST_SITES : RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOCUMENTS} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="card">
                <div className="card-body centerHeader">
                    <DataTable
                        dataSource={dsSites}
                        filter={
                            <Filter>
                                <InputField label="Rechercher" name="nom" typeFilter="like" />
                            </Filter>
                        }
                        actionRow={(r) => [
                            <LinkColumnAction
                                label="Consulter"
                                route={RouteEnum.SITES_CONSULTATION}
                                params={{ id: r.id, dossierId: currentParamsRoute.id }}
                                className="btn btn-sm btn-outline-primary"
                                icon="uil uil-eye"
                            />,
                            ...auth.canEdit(currentParamsRoute.id) === true ?
                                [<LinkColumnAction
                                    label="Editer"
                                    route={RouteEnum.SITES_EDIT}
                                    params={{ id: r.id, dossierId: currentParamsRoute.id }}
                                    className="btn btn-sm btn-outline-primary"
                                    icon="uil uil-edit"
                                />] : []
                        ]}
                        allowDelete={() => auth.isAdmim() == true}
                        addRoute={auth.isAdmim() == true ? router.generatePath(RouteEnum.SITES_ADD, RouteEnum.SITES_ADD, {
                            dossierId: currentParamsRoute.id
                        }) : undefined}
                    >
                        <SimpleColumn<SiteDto> field="numero" title="Numéro" sortable />
                        <SimpleColumn<SiteDto> field="nom" title="Nom" sortable />
                        <AdressePostaleCompleteColumn<SiteDto> field="adresse" title="Adresse" sortable />
                        <SimpleColumn<SiteDto> field="nbEquipements" title="Equipements" sortable />
                        <SimpleColumn<SiteDto> field="nbEquipementsIsole" title="Equipements isolés" sortable />
                    </DataTable>
                </div>
            </div>
        </BaseContainer>

    );
}