import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EquipementDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Card } from "react-bootstrap";
import ControleNav from "../partiel/ControleNavHead";
import { ControleInformationHead } from "./ControleInformationHead";

export const ListeControlesPage = () => {

    const { currentParamsRoute } = useRouter();

    const dsEquipement = useDataSource<EquipementDto>({
        resource: ResourceDataProviderEnum.EQUIPEMENT,
        params: { id: currentParamsRoute.id },
        singleResult: true
    });

    const oneData = (dsEquipement.oneData as EquipementDto);

    return (

        <BaseContainer>

            <ControleInformationHead
                controle={{}}
                eqmtId={currentParamsRoute.id}
                typeControle={null}
                label="Liste des contrôles" />

            <div className="row">
                <div className="col-md-3" style={{ order: 1 }}>
                    <Card className="card">
                        <Card.Header>
                            <div className="card-title">Description de l'équipement</div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="col-sm-6">

                                    <label className="form-label info">Fixation</label>
                                    <label>{oneData?.fixation}</label>

                                    <label className="form-label info">Ossature</label>
                                    <label>{oneData?.ossature}</label>

                                    <label className="form-label info">Dimensions</label>
                                    <label>{oneData?.dimensions}</label>
                                </div>
                                <div className="col-sm-6">

                                    <label className="form-label info">Hauteur</label>
                                    <label>{oneData?.hauteur}</label>

                                    <label className="form-label info">Déport</label>
                                    <label>{oneData?.deport}</label>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                </div>
                <div className="col-sm">
                    <ControleNav />
                </div>

            </div>
        </BaseContainer>

    );
}