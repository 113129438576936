import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { ContextForm } from "@6tm/leeloo-react/component/form/Form";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useContext, useState } from "react"
import { RouteEnum } from "../../../enum/RouteEnum";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";

/**
 * Composant partiel de page pour afficher un header générique dans les formulaires
 * @returns 
 */
export const FormHead = (props: { prependSaveComponent?: any, enabledNextBtn?: boolean, changeForm?: boolean, setSubmitForm?: React.MutableRefObject<(typeForm: string) => Promise<void>>; }) => {
    const { currentLabelRoute, currentRouteName, goBack } = useRouter();

    const form = useContext(ContextForm);

    /*
     * Permet de lancer la création avec les valeurs par défaut
    */

    useEffect(() => {
        if (form.id == undefined && currentRouteName == RouteEnum.CONTROLE_CREATE && form.values && form.resource == "controleAnnuel/Mecanique" && (form.values.hauteurApEssai || form.values.hauteurAvtEssai)
            && form.values.controlesMecaniques && form.values.controlesMecaniques.length > 0) {

            if (form.submitBtnTag!.current != "setSubmitForm") {
                form.submitBtnTag!.current = "setSubmitForm"
                form.handleSubmit()
            }

        }
        // controleOperationnel/Analyses
        else if (form.id == undefined && currentRouteName == RouteEnum.CONTROLE_CREATE_OPERATIONNEL && form.values && form.resource == "controleOperationnel/Analyses"
            && form.values.analyses && form.values.analyses.length > 0) {

            if (form.submitBtnTag!.current != "setSubmitForm") {
                form.submitBtnTag!.current = "setSubmitForm"
                form.handleSubmit()
            }
        }
        // controleRoutine/Analyses
        else if (form.id == undefined && currentRouteName == RouteEnum.CONTROLE_CREATE_ROUTINE && form.values && form.resource == "controleRoutine/Analyses"
            && form.values.analyses && form.values.analyses.length > 0) {

            if (form.submitBtnTag!.current != "setSubmitForm") {
                form.submitBtnTag!.current = "setSubmitForm"
                form.handleSubmit()
            }
        }
    });

    useEffect(() => {

        if (props.setSubmitForm && form.values) {
            props.setSubmitForm.current = async (typeForm: string) => {
                if (typeForm == form.resource) {
                    form.submitBtnTag!.current = "setSubmitForm"
                    form.handleSubmit()
                }
            };
        }
    }, [props.setSubmitForm, form.values]);

    return (

        <div className="d-flex align-items-center gap-1 mb-3">
            <span className="ms-auto">&nbsp;</span>
            {props.prependSaveComponent}
            {
                props.enabledNextBtn &&
                <SubmitBtn className={props.changeForm ? "btn btn-primary" : "btn btn-brand-primary"} icon="save" label=" Suivant >" />

            }
        </div>

    )
}