import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import logo from "./../../../img/logo.png";

export const Logo = () => {
	return (
		<RouterLink routeName={RouteEnum.HOME} className="logo">
			<img src={logo} alt="Normetec"></img>
		</RouterLink>
	);
};
