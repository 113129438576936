/**
 * Représente le container de base des pages simple sans auth
 * (page login, mdp oublié etc....)
 * @returns
 */
export const SimpleContainer = (props: { children?: any }) => {
	return (
		<div className="border-top-wide border-brand-primary d-flex flex-column">
			<div className="page page-center">
				<div className="container-tight py-4">{props.children}</div>
			</div>
		</div>
	);
};
