import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ControleDto } from "../../../_generated/clientdto.generated";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { BaseContainer } from "../../layout/BaseContainer";
import { RouteEnum } from "../../../enum/RouteEnum";
import ControleActionNav from "../partiel/ControleActionNav";
import { ControleInformationHead } from "./ControleInformationHead";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { useAuth } from "../../hook/useAuth";


export const ControleRoutineEditOrAdd = () => {

    const { currentParamsRoute } = useRouter();

    const dsControle = useDataSource<ControleDto>({
        resource: ResourceDataProviderEnum.CONTROLE_ROUTINE_DATE_CONTROLE,
        params: {
            id: currentParamsRoute.id > 0 ? currentParamsRoute.id : 0
        },
        singleResult: true
    });

    const oneDataControle = (dsControle.oneData as ControleDto);
    const auth = useAuth();
    return (

        <BaseContainer>
            <ControleInformationHead
                controle={oneDataControle}
                eqmtId={currentParamsRoute.EqmtId}
                typeControle={ControleTypeEnum.ControleRoutine}
                label={null} />

            <ControleActionNav
                resourceDataProviderEnum={ResourceDataProviderEnum.CONTROLE_ROUTINE}
                controleEdit={RouteEnum.CONTROLE_EDIT_ROUTINE}
                canEdit={auth.canShowCr(currentParamsRoute.dossierId)} />
        </BaseContainer>

    );
}




