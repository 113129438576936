
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ControleDto, EquipementDto, EspaceDto, SiteDto } from "../../../_generated/clientdto.generated";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { Card } from "react-bootstrap";
import { AdressePostaleComplete } from "../../customColumn/AdressePostaleCompleteColumn";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { useEffect } from "react";
import { RouteEnum } from "../../../enum/RouteEnum";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";

type Props = {
    eqmtId: number
    controle: ControleDto
    label: string | null
    typeControle: ControleTypeEnum | null

}
export const ControleInformationHead = (props: Props) => {

    const { currentParamsRoute, currentRouteName, goBack, goTo, generatePath } = useRouter();

    const dsEquipement = useDataSource<EquipementDto>({
        resource: ResourceDataProviderEnum.EQUIPEMENT,
        params: { id: props.eqmtId },
        singleResult: true
    });

    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.siteId
        },
        singleResult: true
    });

    const dsEspace = useDataSource<EspaceDto>({
        resource: ResourceDataProviderEnum.ESPACES,
        params: {
            id: currentParamsRoute.espaceId
        },
        singleResult: true
    });

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    const oneData = (dsEquipement.oneData as EquipementDto);
    const oneDataEspace = (dsEspace.oneData as EspaceDto);
    const oneDataSite = (dsSite.oneData as SiteDto);

    let isExisteDateControle = props.controle?.dateControle ? true : false;

    const breadcrumb = useBreadcrumb();

    const isReady = (breadcrumb: any) => {

        if (props.eqmtId > 0 && !props.controle) {
            return breadcrumb.isReady && oneDataSite && oneDataEspace && oneData
        }
        if (props.controle) {
            return breadcrumb.isReady && oneDataSite && oneDataEspace && oneData && props.controle
        }
        return breadcrumb.isReady && oneDataSite && oneDataEspace

    }

    let label = props.label ?? "";
    useEffect(() => {
        if (breadcrumb.isReady == true && oneDataSite && oneDataEspace) {
            breadcrumb.resetAndSet([{
                textCustom: `Dossiers`, route: RouteEnum.APP_HOME
            },
            {
                textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.dossierId
                }
            },
            {
                textCustom: `Site : ${oneDataSite?.nom}`, route: RouteEnum.SITES_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.siteId,
                    dossierId: currentParamsRoute.dossierId
                }
            },
            {
                textCustom: `Espace : ${oneDataEspace?.numero}`, route: RouteEnum.ESPACES_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.espaceId,
                    dossierId: currentParamsRoute.dossierId,
                    siteId: currentParamsRoute.siteId,
                }
            },
            ]);
            let urlControle = "";
            let prefixeLabelControle = "";
            if (props.controle) {
                switch (props.typeControle) {
                    case ControleTypeEnum.ControlePrincipal:
                        label = `Contrôle principal du ${new Date(props.controle?.dateControle!).toLocaleDateString()}`
                        urlControle = RouteEnum.CONTROLE_EDIT;
                        prefixeLabelControle = "CA"
                        break;
                    case ControleTypeEnum.ControleOperationnel:
                        label = `Contrôle opérationnel du ${new Date(props.controle?.dateControle!).toLocaleDateString()}`
                        urlControle = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;
                        prefixeLabelControle = "CF"
                        break;
                    case ControleTypeEnum.ControleRoutine:
                        label = `Contrôle de routine du ${new Date(props.controle?.dateControle!).toLocaleDateString()}`
                        urlControle = RouteEnum.CONTROLE_EDIT_ROUTINE;
                        prefixeLabelControle = "CR"
                        break;
                    default:
                        if (currentRouteName == RouteEnum.MAINTENANCE_ANNUEL_EDIT || currentRouteName == RouteEnum.MAINTENANCE_ANNUEL_CREATE) {
                            prefixeLabelControle = "CA"
                            urlControle = RouteEnum.CONTROLE_EDIT;
                        } else if (currentRouteName == RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT || currentRouteName == RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE) {
                            prefixeLabelControle = "CF"
                            urlControle = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;
                        } else if (currentRouteName == RouteEnum.MAINTENANCE_ROUTINE_EDIT || currentRouteName == RouteEnum.MAINTENANCE_ROUTINE_CREATE) {
                            prefixeLabelControle = "CR"
                            urlControle = RouteEnum.CONTROLE_EDIT_ROUTINE;
                        }
                        break;
                }
            }

            breadcrumb.addNode({
                textCustom: `${label}`, route: undefined
            })
            if (oneData) {
                breadcrumb.addNodeBeforeCurrent
                    ({
                        textCustom: `Equipement : ${oneData?.reference}`, route: RouteEnum.LIST_CONTROLE,
                        routeParams: {
                            id: oneData.id,
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId
                        }
                    })
            }

            if (props.controle?.id! > 0) {

                breadcrumb.addNodeBeforeCurrent
                    ({
                        textCustom: `${prefixeLabelControle} : ${new Date(props.controle?.dateControle!).toLocaleDateString()}`, route: urlControle,
                        routeParams: {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: props.controle?.id,
                        }
                    })
            }

        }
        // le isReady permet de laisser le breadcrumb de base de la page se charger convenablement
    }, [isReady(breadcrumb)])

    let urlControle = "";
    let labelEqui = "";
    if (oneData && oneData.typeControleId && oneData.typeControleId > 0) {
        switch (oneData.typeControleId) {
            case ControleTypeEnum.ControlePrincipal:
                labelEqui = `CA ${new Date(oneData?.dateControle!).toLocaleDateString()}`
                urlControle = RouteEnum.CONTROLE_EDIT;

                break;
            case ControleTypeEnum.ControleOperationnel:
                labelEqui = `CF ${new Date(oneData?.dateControle!).toLocaleDateString()}`
                urlControle = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;

                break;
            case ControleTypeEnum.ControleRoutine:
                labelEqui = `CR ${new Date(oneData?.dateControle!).toLocaleDateString()}`
                urlControle = RouteEnum.CONTROLE_EDIT_ROUTINE;
                break
            default:
                break;
        }

        if (urlControle != "") {

            let eqmtId = currentParamsRoute.EqmtId;
            if (!eqmtId) {
                eqmtId = currentParamsRoute.id;
            }

            urlControle = urlControle.replace(":dossierId", currentParamsRoute.dossierId)
                .replace(":siteId", currentParamsRoute.siteId)
                .replace(":espaceId", currentParamsRoute.espaceId)
                .replace(":EqmtId", eqmtId)
                .replace(":id", oneData?.controleId!.toString());
        }
    }
    return (
        <>
            <div className="row d-flex align-items-center gap-2 mb-3">

                <div className="row bg-white" style={{ marginLeft: 1 }}>

                    <div className="col-sm-3">
                        <label className="form-label info">Site</label>
                        <RouterLink className="nav-link dt-clickable-cell" routeName={RouteEnum.SITES_CONSULTATION}
                            params={{
                                id: currentParamsRoute.siteId,
                                dossierId: currentParamsRoute.dossierId
                            }}
                        >{oneDataSite?.nom}</RouterLink>
                    </div>
                    <div className="col-sm-3">
                        <label className="form-label info">N° d'espace</label>
                        <RouterLink className="nav-link dt-clickable-cell" routeName={RouteEnum.ESPACES_CONSULTATION}
                            params={{
                                id: currentParamsRoute.espaceId,
                                dossierId: currentParamsRoute.dossierId,
                                siteId: currentParamsRoute.siteId,
                            }}
                        >{oneDataEspace?.numero}</RouterLink>
                    </div>
                    <div className="col-sm-3">
                        <label className="form-label info">Zone d'évolution</label>
                        <label>{oneDataEspace?.typeZone?.libelle}</label>
                    </div>
                    {
                        props.eqmtId > 0 &&
                        <div className="col-sm-3">
                            <label className="form-label info">Immatriculation</label>
                            <RouterLink className="nav-link dt-clickable-cell" routeName={RouteEnum.LIST_CONTROLE}
                                params={{
                                    id: oneData?.id!,
                                    dossierId: currentParamsRoute.dossierId,
                                    siteId: currentParamsRoute.siteId,
                                    espaceId: currentParamsRoute.espaceId
                                }}
                            >{oneData?.reference}</RouterLink>
                        </div>
                    }


                </div>
                {
                    props.eqmtId > 0 &&
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Identification</h3>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <label className="form-label info">Statut de l’équipement</label>

                                            {
                                                oneData && <div className="leader" style={{
                                                    width: "100%",
                                                    margin: 2,
                                                    padding: 2,
                                                    backgroundRepeat: "no-repeat",
                                                    minHeight: 55,
                                                }}>
                                                    <div className="ranking" style={{
                                                        float: "left",
                                                        margin: 5,
                                                        padding: 10,
                                                        color: "white",
                                                        backgroundColor: oneData?.conforme == true ? "green" : "red"
                                                    }}>
                                                        {oneData?.conforme == true ? "Conforme" : "Non conforme"}
                                                    </div>
                                                    <div className="details" style={{
                                                        float: "left",
                                                        paddingTop: 12
                                                    }}>
                                                        <a rel="opener"
                                                            className="nav-link dt-clickable-cell"
                                                            href={urlControle}>{labelEqui}
                                                        </a>
                                                    </div>

                                                </div>
                                            }
                                            <label className="form-label info">Equipement isolé</label>
                                            <label style={{ color: oneData?.isole == true ? "red" : "" }}>{oneData?.isole == true ? "Oui" : "Non"}</label>
                                            {
                                                oneData?.isole &&
                                                <div>
                                                    <label className="form-label info">Commentaire d'isolement</label>
                                                    <label style={{ color: "red" }}>{oneData?.isoleMemo}</label>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-sm-5">

                                            <label className="form-label info">Catégorie d'équipement</label>
                                            <label>{oneData?.typeCategorie?.libelle}</label>

                                            <label className="form-label info">Type d'équipement</label>
                                            <label>{oneData?.typeEquipement?.libelle}</label>

                                            <label className="form-label info">Date de mise en service</label>
                                            <label>{oneData?.miseService}</label>

                                            <label className="form-label info">Date de fabrication</label>
                                            <label>{oneData?.fabrication}</label>
                                            {
                                                isExisteDateControle &&
                                                <div>
                                                    <label className="form-label info">Contrôle effectué le</label>
                                                    <label>{new Date(props.controle?.dateControle!).toLocaleDateString()}</label>
                                                </div>

                                            }

                                        </div>
                                        <div className="col-sm-4">
                                            <label className="form-label info">N° client</label>
                                            <label>{oneData?.numeroClient}</label>

                                            <label className="form-label info">Nom du fabricant</label>
                                            <label>{oneData?.fabriquant?.nom}</label>

                                            <label className="form-label info">Norme de référence</label>
                                            <label>{oneData?.typeEquipement?.norme}</label>

                                            <label className="form-label info">Adresse</label>
                                            <AdressePostaleComplete adresse={oneData?.fabriquant?.adresse} />
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="col-md-3">
                            <div className="card" style={{ height: "100%" }}>
                                <div className="card-header">
                                    <h3 className="card-title">Plan</h3>
                                </div>
                                <div className="card-body" style={{ textAlign: "center" }}>
                                    {oneData?.urlPhoto == null &&

                                        "Aucune image"

                                    }
                                    {oneData?.urlPhoto &&
                                        <img
                                            className="img1"
                                            src={oneData?.urlPhoto}
                                            style={{ height: "100%" }}
                                            onClick={() => openInNewTab(oneData?.urlPhoto)}

                                        />}

                                </div>

                            </div>

                        </div>
                    </div>
                }
            </div >

        </>
    );
}




