import { SimpleContainer } from "../../layout/SimpleContainer";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { ResetPasswordForm } from "./ResetPasswordForm";




export const RequestUpdatePasswordForm = () => {

    return (
        <SimpleContainer>

            <ResetPasswordForm
                displayPassOublie={true}
                resourceDataProviderEnum={ResourceDataProviderEnum.RESET_PASSWORD}
                routeEnum={RouteEnum.APP_HOME} />
        </SimpleContainer>
    );
}