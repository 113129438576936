import { ResetPasswordFormType } from "@6tm/leeloo-react/model/authProvider/ResetPasswordFormType";
import { SimpleContainer } from "../../layout/SimpleContainer";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum";


export const ResetForgotPasswordForm = (props: ResetPasswordFormType) => {

    return (
        <SimpleContainer>
            <ResetPasswordForm
                displayPassOublie={false}
                resourceDataProviderEnum={ResourceDataProviderEnum.RESET_PASSWORD_MAIL}
                routeEnum={RouteLeelooEnum.login} />
        </SimpleContainer>
    );
}