import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { DossierDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import * as Yup from 'yup';
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField";
import { AdresseField } from "../../form/field/AdresseField";

export const DossierEditAddPage = () => {

    const { currentParamsRoute, currentRouteName, goTo } = useRouter();
    const dsTypeClient = useDataSource({ resource: ResourceDataProviderEnum.TYPE_CLIENTS, params: {}, profilerName: "TYPE_CLIENTS" });
    const dsTypeContrat = useDataSource({ resource: ResourceDataProviderEnum.TYPE_CONTRATS, params: {}, profilerName: "TYPE_CONTRATS" });

    const { t } = useTrans();
    const toast = useToast();

    const [villeId, setVilleId] = useState(0);
    const [consultation, setConsultation] = useState(false);

    useEffect(() => {
        if (currentRouteName === RouteEnum.DOSSIERS_CONSULTATION) {
            setConsultation(true);
        } else {
            setConsultation(false);
        }
    })
    const isCreate = currentParamsRoute?.id > 0 ? false : true;
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_EDIT} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>

                                <li className="nav-item" style={{ pointerEvents: isCreate ? "none" : "auto", opacity: isCreate ? 0.5 : 1 }}>
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_LIST_SITES}
                                        params={{ id: currentParamsRoute?.id }}
                                    ></RouterLink>
                                </li>

                                {/* <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_LIST_SITES} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <Form
                disabled={consultation}
                initialValue={{}}
                resource={ResourceDataProviderEnum.DOSSIER}
                id={currentParamsRoute?.id}
                onSubmitEndUpdate={(e, ret) => {
                    // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(RouteEnum.DOSSIERS_EDIT.replace(":mode", "new").replace(":id", ret.newId.toString()));
                    }

                    toast("form.infosUpdated", "success");
                }}
                onDataApiLoaded={async (d: DossierDto | undefined) => {
                    if (d?.adresse?.villeId != null && d?.adresse?.villeId != undefined) {
                        setVilleId(d?.adresse?.villeId)
                    }
                }}
                paramsResource={{ expand: { field: ["Adresse", "TypeClient", "Contrat/TypeContrat"] } }}
                validationSchema={{
                    nom: Yup.string().required("Le nom est requis"),
                    reference: Yup.string().required("La référence est requise"),
                    interloc: Yup.string().required("L'interlocuteur est requis"),
                    adresse: Yup.object().shape({
                        villeId: Yup.number().required("La ville est requise"),
                    }),
                    contrat: Yup.object().shape({
                        typeContratId: Yup.number().required("Le type de contrat est requis"),
                        dateDebut: Yup.date().required("La date de debut de contrat est requise"),
                        // dateFin: Yup.date().required("La date de fin de contrat est requise"),
                        dateFin: Yup.date().required("La date de fin de contrat est requise").min(
                            Yup.ref('dateDebut'),
                            "La date de fin ne peut pas être antérieure à la date de début"
                        )

                    }),
                }}
            >
                <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                    <div className="sticky-actions-inner">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn-list ms-auto">
                                <RouterLink className="btn" routeName={RouteEnum.DOSSIERS_LISTE}><i className="uil uil-angle-left" />Retour</RouterLink>
                                <SubmitBtn className="btn btn-primary" >
                                    {t("actions.save")}
                                </SubmitBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Informations du dossier</h3>
                            </div>
                            <div className="card-body">
                                <InputField<DossierDto> className="mb-2" label="Référence du dossier" name="reference" />
                                <InputField<DossierDto> className="mb-2" label="Nom" name="nom" />
                                <InputField<DossierDto> className="mb-2" label="Interlocuteur" name="interloc" />

                                <SelectField
                                    className="mb-2"
                                    name="typeClientId"
                                    label="Type de client"
                                    isSearchable={true}
                                    // la resource externe sous forme de dataSource pour charger les données
                                    externalResource={{ dataSource: dsTypeClient, labelKey: "libelleWithInterlocuteur", valueKey: "id" }}
                                />
                                <SelectField
                                    className="mb-2"
                                    name="contrat.typeContratId"
                                    label="Contrat"
                                    isSearchable={true}
                                    // la resource externe sous forme de dataSource pour charger les données
                                    externalResource={{ dataSource: dsTypeContrat, labelKey: "libelle", valueKey: "id" }}
                                />
                                <DatePickerField className="mb-2" label="Date de début du contrat" name="contrat.dateDebut" />
                                <DatePickerField className="mb-2" label="Date de fin du contrat" name="contrat.dateFin" />

                                {(currentParamsRoute?.id != undefined) &&
                                    <>

                                        <InputField<DossierDto> className="mb-2" label="Date de création" name="dateCreationLibelle" disabled />
                                        <InputField<DossierDto> className="mb-2" label="Dernière mise à jour" name="dateMajLibelle" disabled />
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Adresse</h3>
                            </div>
                            <div className="card-body">
                                <AdresseField villeId={villeId} />
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </BaseContainer >
    );
}