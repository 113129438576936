
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { UtilisateurSiteDto } from "../../../../_generated/clientdto.generated";
import { useAuth } from "../../../hook/useAuth";
import { BaseContainer } from "../../../layout/BaseContainer";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";

export const UtilisateurListPage = () => {

	const dsUsers = useDataSource({ resource: ResourceDataProviderEnum.USERS_SITE, params: { pagination: { page: 1, take: 50 }, expand: { field: ["Organisation"] } }, profilerName: "user" });

	const auth = useAuth();
	return (
		<BaseContainer>
			<div className="card">
				<div className="card-body centerHeader">
					<DataTable
						dataSource={dsUsers}
						filter={
							<Filter>
								<InputField label="Rechercher" name="search" typeFilter="like" />
							</Filter>
						}

						actionRow={(r) => [

							<LinkColumnAction
								label="Connexion en tant que"
								route={RouteLeelooEnum.autoConnect.replace(":data", r?.id)}
								params={{ id: r.id }}
								className="btn btn-sm btn-outline-primary"
								icon="uil uil-user-arrows"
							/>,
							...auth.isAdmim() === true ?
								[<LinkColumnAction
									label="Editer"
									route={RouteEnum.ADMIN_UTILISATEURS_EDIT}
									params={{ id: r.id, dossierId: r.id }}
									className="btn btn-sm btn-outline-primary"
									icon="uil uil-edit"
								/>] : []
						]}
						addRoute={auth.isAdmim() == true ? RouteEnum.ADMIN_UTILISATEURS_ADD : undefined}
						allowDelete={() => auth.isAdmim() == true}
					>
						<SimpleColumn field="organisation.libelle" sortableField="organisation/libelle" title="Organisation" sortable />
						<SimpleColumn<UtilisateurSiteDto> field="nom" title="Nom" sortable />
						<SimpleColumn<UtilisateurSiteDto> field="prenom" title="Prenom" sortable />
						<SimpleColumn<UtilisateurSiteDto> field="email" title="Email" sortable />
						<SimpleColumn field="adresse.adressetel1" title="Téléphone" sortable />
						<SimpleColumn<UtilisateurSiteDto> field="newLogin" title="Login" sortable />
						<SimpleColumn<UtilisateurSiteDto> field="fonction" title="Fonction" sortable />
					</DataTable>
				</div>
			</div>

		</BaseContainer>
	);
};
