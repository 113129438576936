import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { ResetPasswordFormType } from "@6tm/leeloo-react/model/authProvider/ResetPasswordFormType";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { SimpleContainer } from "../../layout/SimpleContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { ForgotPasswordDto } from "../../../_generated/clientdto.generated";
import logo from "./../../../img/logo.png";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum";
/**
 * Page basique pour le formulaire "mot de passe oublié"
 * Peut etre surchargée via le paramètre requestForgotPasswordForm
 * @returns 
 */
export const RequestForgotPasswordForm = (props: ResetPasswordFormType) => {


    const [isSendMail, setIsSendMail] = useState(false)

    return (
        <SimpleContainer>
            <Form<ForgotPasswordDto>
                initialValue={{}}
                resource={ResourceDataProviderEnum.FORGOT_PASSWORD}

                onSubmitEndUpdate={(e, ret) => {
                    setIsSendMail(true)

                }}
            >

                <div className="text-center mb-4">
                    <img src={logo} alt="Normetec" width={100}></img>
                </div>

                <div className="text-center display-6 fw-bold text-brand-primary mb-4">
                    Normetec
                </div>
                <div className="card card-md">
                    <div className="card-body">
                        <div className="card-title mb-3">Demande de réinitialisation de votre mot de passe</div>
                        {isSendMail && <Alert variant="success">{"Vérifiez votre boite mail pour obtenir votre lien de réinitialisation."}</Alert>}
                        <InputField label="Identifiant" name="login" className="mb-3" />
                        {
                            isSendMail &&
                            <RouterLink routeName={RouteLeelooEnum.login} className="btn btn-primary w-100">
                                Retour à l'écran de connexion
                            </RouterLink>
                        }
                        {
                            !isSendMail &&

                            <SubmitBtn className="btn btn-primary w-100" label="Valider" />
                        }

                    </div>
                </div>
            </Form>
        </SimpleContainer >
    );
}