import { StoreConfigType } from "@6tm/leeloo-react/model/store/StoreConfigType";
import { ReducerEnum } from "../enum/store/ReducerEnum";

/**
 * Configuration simple des reducers ici
 * Les CRUD seront automatiquement disponible et utilisable avec le hook useWriteRedux
 */
export const StoreConfig: StoreConfigType = {
    reducers: [
        {
            name: ReducerEnum.actionType,
            initialState: {}
        },
        {
            name: ReducerEnum.config,
            initialState: { appAsideOpened: false, appAsideFolded: true, appAsideForceUnfolded: false }
        }
    ],
};