import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { OrganisationDto } from "../../../../_generated/clientdto.generated";
import { BaseContainer } from "../../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { StructureAddForm } from "./StructureAddForm";
import { useState } from "react";
import * as Yup from 'yup';
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { RouteEnum } from "../../../../enum/RouteEnum";

export const StructureAddPage = () => {

    const { currentParamsRoute, goTo } = useRouter();
    const toast = useToast();
    const [saveInfo, setSaveInfo] = useState<OrganisationDto>({})
    const dsOrganisation = useDataSource<OrganisationDto>({
        resource: ResourceDataProviderEnum.ORGANISATIONS,
        params: {
        }
    });
    return (
        <BaseContainer>
            <Form
                initialValue={saveInfo}
                resource={ResourceDataProviderEnum.ORGANISATIONS}
                id={currentParamsRoute?.id}
                onChangeData={(e, ret) => {
                    if (e) {
                        setSaveInfo(e)
                    }
                }}
                onSubmitEndUpdate={(e, ret) => {

                    // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(RouteEnum.ADMIN_UTILISATEURS_LIST);
                    }
                    toast("form.infosUpdated", "success");

                    // dsOrganisation.refreshData();
                    // toast("form.infosUpdated", "success");

                }}
                validationSchema={{
                    libelle: Yup.string().required("Le nom est requis")
                }}
            >

                <StructureAddForm
                    data={dsOrganisation.dataList}
                />

                <div className="row">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Organisation</h3>
                        </div>
                        <div className="card-body">
                            <InputField<OrganisationDto> label="form.nom" name="libelle" />
                        </div>
                    </div>
                </div>
            </Form>
        </BaseContainer>

    );
}