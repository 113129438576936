
import { useAuthProvider } from "@6tm/leeloo-react/component/hook/useAuthProvider";
import { UserHabilitationEnum } from "../../enum/UserHabilitationEnum";
import { UtilisateurDossierDto } from "../../_generated/clientdto.generated";

/**
 * Vérifier si l'utilisateur courant est un admin
 * @returns
 */
export function useAuth() {

    const authProvider = useAuthProvider();
    const { authData } = useAuthProvider()! as any;
    const checkAccessDossier = (d: string, dossierId: number) => {
        if (authData.utilisateurDossiers) {

            switch (d) {
                case "maintanance":
                    return authData.utilisateurDossiers.some((item: UtilisateurDossierDto) => item.isMaintenance == true && item.dossierId == dossierId);
                case "ca":
                    return authData.utilisateurDossiers.some((item: UtilisateurDossierDto) => item.isCa == true && item.dossierId == dossierId);
                case "cf":
                    return authData.utilisateurDossiers.some((item: UtilisateurDossierDto) => item.isCf == true && item.dossierId == dossierId);
                case "cr":
                    return authData.utilisateurDossiers.some((item: UtilisateurDossierDto) => item.isCr == true && item.dossierId == dossierId);
                default:
                    return false;
            }

        } else {
            return false;
        }

    }
    const canEdit = (ossierId: number) => {
        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]);

    }

    const canEditCreateMaintenance = (dossierId: number) => {

        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]) || checkAccessDossier("maintanance", dossierId)

    }
    const canAdd = (dossierId: number) => {

        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN])

    }
    const canShowCr = (dossierId: number) => {
        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]) || checkAccessDossier("cr", dossierId)
    }
    const canShowCa = (dossierId: number) => {
        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]) || checkAccessDossier("ca", dossierId)
    }
    const canShowCf = (dossierId: number) => {
        return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]) || checkAccessDossier("cf", dossierId)
    }

    return {
        isAdmim: () => { return authProvider?.checkAccess([UserHabilitationEnum.ROLE_ADMIN]) },
        isMaintanance: (dossierId: number) => { return checkAccessDossier("maintanance", dossierId) },
        isCa: (dossierId: number) => { return checkAccessDossier("ca", dossierId) },
        isCf: (dossierId: number) => { return checkAccessDossier("cf", dossierId) },
        isCr: (dossierId: number) => { return checkAccessDossier("cr", dossierId) },
        canEdit: (dossierId: number) => {
            return canEdit(dossierId);
        },
        canAdd: (dossierId: number) => { return canAdd(dossierId) },
        canShowCf: (dossierId: number) => { return canShowCf(dossierId) },
        canShowCa: (dossierId: number) => { return canShowCa(dossierId) },
        canShowCr: (dossierId: number) => { return canShowCr(dossierId) },
        canEditCreateMaintenance: (dossierId: number) => { return canEditCreateMaintenance(dossierId) }
    }

}