
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { FabriquantDto } from "../../../../_generated/clientdto.generated";
import { AdressePostaleCompleteColumn } from "../../../customColumn/AdressePostaleCompleteColumn";
import { useAuth } from "../../../hook/useAuth";
import { BaseContainer } from "../../../layout/BaseContainer";

export const FabriquantListePage = () => {

    const dsFabriquants = useDataSource({ resource: ResourceDataProviderEnum.FABRIQUANTS, params: { pagination: { page: 1, take: 50 } }, profilerName: "user" });
    const auth = useAuth();
    return (
        <BaseContainer>
            <div className="card">
                <div className="card-body centerHeader">
                    <DataTable
                        dataSource={dsFabriquants}
                        filter={
                            <Filter>
                                <InputField label="Rechercher" name="search" typeFilter="like" />
                            </Filter>
                        }
                        allowDelete={() => auth.isAdmim() == true}
                        addRoute={RouteEnum.ADMIN_FABRIQUANTS_ADD}
                        editRoute={RouteEnum.ADMIN_FABRIQUANTS_EDIT}
                    >
                        <SimpleColumn<FabriquantDto> field="nom" title="Nom" sortable />
                        <AdressePostaleCompleteColumn field="adresse" title="Adresse" sortable />
                    </DataTable>
                </div>
            </div>

        </BaseContainer>
    );
};
