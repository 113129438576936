
import { RouteEnum } from "../../enum/RouteEnum";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ResourceDataProviderEnum } from "../../enum/ResourceDataProviderEnum";
import { useEffect } from "react";
import { DossierDto } from "../../_generated/clientdto.generated";

export const AppHomePage = () => {

	const dsDossiers = useDataSource({ resource: ResourceDataProviderEnum.DOSSIER, params: { pagination: { page: 1, take: 50 } }, profilerName: "dossier" });

	// Redirection vers la liste des dossiers si plusieurs ou consultation si un seul dossier
	const { goTo } = useRouter();
	useEffect(() => {
		if (dsDossiers.isReady && dsDossiers.dataList && dsDossiers.dataList.length == 1) {
			const dsDossier = dsDossiers.dataList[0] as DossierDto;
			const id = dsDossier.id;
			goTo(RouteEnum.DOSSIERS_CONSULTATION.replace(":mode", "new").replace(":id", id!.toString()));
		}
		else if (dsDossiers.isReady) {
			goTo(RouteEnum.DOSSIERS_LISTE);
		}
	}, [dsDossiers.isReady])

	return (
		<></>
	);
};
