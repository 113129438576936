export enum ResourceDataProviderEnum {
	LOGIN = "auth/login/SeConnecter",
	FORGOT_PASSWORD = "auth/login/ForgotPassword",
	RESET_PASSWORD = "auth/login/ResetPassword",
	RESET_PASSWORD_MAIL = "auth/login/ResetPasswordMail",
	USERS_SITE = "utilisateurSite",
	USER_CONNECTE = "utilisateurSite/UserConnecte",
	FABRIQUANTS = "fabriquant",
	USER_ACCESS = "utilisateurDossier",
	USERS_ALL_CITY = "ville",
	ORGANISATIONS = "organisation",
	TYPE_CLIENTS = "typeClient",
	TYPE_CATEGORIE = "typeCategorie",
	TYPE_EQUIPEMENT = "TypeEquipement",
	CONTRATS = "contrat",
	TYPE_CONTRATS = "typeContrat",
	TYPE_SOLS = "typeSol",
	TYPE_ZONES = "typeZone",
	DOSSIER = "dossier",
	SITES = "site",
	SITES_FILE = "site/file",
	ESPACES = "espace",
	ESPACES_FILE = "espace/file",
	FILES = "files",
	REGISTRE_SECURITE = "registreSecurite",
	DOCUMENT = "document",
	DOCUMENT_DOWNLOAD = "document/download",
	APPLY_ALL_FOLDER = "utilisateurDossier/applyToAllFolder",
	EQUIPEMENT = "equipement",
	EQUIPEMENT_FILE = "equipement/file",
	TYPE_CONTROLE = "typeControle",
	CONTROLE = "controle",
	CONTROLE_ANNUEL = "controleAnnuel",
	CONTROLE_ANNUEL_MECANIQUE = "controleAnnuel/ListeControle",
	CONTROLE_ANNUEL_FILE = "controleAnnuel/files",
	CONTROLE_ANNUEL_DATE_CONTROLE = "controleAnnuel/DateControle",


	CONTROLE_OPERATIONNEL = "controleOperationnel",
	CONTROLE_ROUTINE = "controleRoutine",
	TYPE_COMMENTAIRE_BY_EQUIPEMENT = "typeCommentaire/equipement",
	CONTROLE_OPERATIONNEL_MECANIQUE = "controleOperationnel/ListeControle",
	CONTROLE_ROUTINE_MECANIQUE = "controleRoutine/ListeControle",
	CONTROLE_OPERATIONNEL_FILE = "controleOperationnel/files",
	CONTROLE_ROUTINE_FILE = "controleRoutine/files",
	CONTROLE_ROUTINE_DATE_CONTROLE = "controleRoutine/DateControle",
	CONTROLE_OPERATIONNEL_DATE_CONTROLE = "controleOperationnel/DateControle",

	FOURNISSEUR = "fournisseur",

	MAINTENANCE = "maintenance"
}
