
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { ControleDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouteEnum } from "../../../enum/RouteEnum";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { useAuth } from "../../hook/useAuth";
import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { DateColumn } from "@6tm/leeloo-react/component/dataTable/column/DateColumn";
import { NomCompletColumn } from "../../customColumn/NomCompletUtilisateur";
import { ClickableColumn } from "@6tm/leeloo-react/component/dataTable/column/ClickableColumn";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { GoBackButton } from "../partiel/GoBackButton";
import { BooleanColumnIcon } from "../../customColumn/BooleanColumnIcon";

export const ControleByType = (props: { typeAction: ControleTypeEnum, canEdit: boolean }) => {

    const { currentParamsRoute } = useRouter();

    const dict = {
        1: ResourceDataProviderEnum.CONTROLE_OPERATIONNEL,
        2: ResourceDataProviderEnum.CONTROLE_ANNUEL,
        3: ResourceDataProviderEnum.CONTROLE_ROUTINE,
    };


    const dictRouteCreate = {
        1: RouteEnum.CONTROLE_CREATE_OPERATIONNEL,
        2: RouteEnum.CONTROLE_CREATE,
        3: RouteEnum.CONTROLE_CREATE_ROUTINE,
    };

    const dictRouteEdit = {
        1: RouteEnum.CONTROLE_EDIT_OPERATIONNEL,
        2: RouteEnum.CONTROLE_EDIT,
        3: RouteEnum.CONTROLE_EDIT_ROUTINE,
    };
    const dictRouteMaintenanceEdit = {
        1: RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT,
        2: RouteEnum.MAINTENANCE_ANNUEL_EDIT,
        3: RouteEnum.MAINTENANCE_ROUTINE_EDIT,
    };
    const auth = useAuth();
    const router = useRouter();
    const dsControles = useDataSource({
        resource: dict[props.typeAction],
        params: {
            pagination: { page: 1, take: 50 },
            expand: { field: ["FicheMaintenances", "UtilisateurSite"] },
            filter: { "EqmtId": currentParamsRoute?.id },
            sort: { field: "DateControle", order: "desc" }
        },
        profilerName: "espace"
    });

    const urlCreate = router.generatePath(dictRouteCreate[props.typeAction], dictRouteCreate[props.typeAction], {
        siteId: currentParamsRoute.siteId,
        dossierId: currentParamsRoute.dossierId,
        espaceId: currentParamsRoute.espaceId,
        EqmtId: currentParamsRoute.id,
    })

    const onClickMaint = (r: any, url: string) => {

        var controle = r as ControleDto;

        router.goTo(router.generatePath(url, url, {
            siteId: currentParamsRoute.siteId,
            dossierId: currentParamsRoute.dossierId,
            espaceId: currentParamsRoute.espaceId,
            EqmtId: currentParamsRoute.id,
            CtrlID: controle.id,
            id: controle.ficheMaintenances?.id
        }))
    }
    return (


        <div className="card">

            <div className="card-body">
                <DataTable
                    dataSource={dsControles}

                    allowDelete={(row) => auth.isAdmim() == true ? true : false}
                    actionRow={(r) => [
                        <LinkColumnAction
                            label="Consulter"
                            route={dictRouteEdit[props.typeAction]}
                            params={{
                                dossierId: currentParamsRoute.dossierId,
                                siteId: currentParamsRoute.siteId,
                                espaceId: currentParamsRoute.espaceId,
                                EqmtId: currentParamsRoute.id,
                                id: r.id
                            }}
                            className="btn btn-sm btn-outline-primary"
                            icon="uil uil-eye"
                        />
                    ]}
                    addRoute={props.canEdit == true ? urlCreate : undefined}
                    actionHeader={
                        <GoBackButton
                            routeName={RouteEnum.LIST_EQUIPEMENT}
                            params={{
                                dossierId: currentParamsRoute.dossierId,
                                siteId: currentParamsRoute.siteId,
                                id: currentParamsRoute.espaceId

                            }} />
                    }
                >
                    <ClickableColumn<ControleDto>
                        title="Date de contrôle"
                        field="dateControle"
                        columnElement={<DateColumn format="DD/MM/YYYY" />}
                        onClick={(r, v) =>
                            router.goTo(router.generatePath(dictRouteEdit[props.typeAction], dictRouteEdit[props.typeAction], {
                                siteId: currentParamsRoute.siteId,
                                dossierId: currentParamsRoute.dossierId,
                                espaceId: currentParamsRoute.espaceId,
                                EqmtId: currentParamsRoute.id,
                                id: r.id
                            }))}
                    />
                    <ClickableColumn
                        title="Date de transmission"
                        field="ficheMaintenances.dateTransmission"
                        columnElement={<DateColumn format="DD/MM/YYYY" />}
                        onClick={(r, v) => onClickMaint(r, dictRouteMaintenanceEdit[props.typeAction])
                        }
                    />
                    <NomCompletColumn field="utilisateurSite" title="Effectué par" />
                    <BooleanColumnIcon field="isValid" title="Validé" />
                    <BooleanColumnIcon field="conforme" title="Conformité" sortable />
                </DataTable>
            </div>
        </div>

    );
}
