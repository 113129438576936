import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { ControleDto } from "../../../../_generated/clientdto.generated";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { CollectionFormFieldMecanique } from "./CollectionFormFieldMecanique";
import { useState } from "react";
import { FormHead } from "../../partiel/FormHead";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { SelectCheckRadioField } from "@6tm/leeloo-react/component/form/field/SelectCheckRadioField";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string
    isEditPage: boolean
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
    onNextAction: (nextAction: string) => void
}

export const ControleEditAnalyse = (props: Props) => {

    const { currentParamsRoute, goTo } = useRouter();

    const { t } = useTrans();
    const toast = useToast();
    const [saveInfo, setSaveInfo] = useState({})
    const [changeFormulaire, setChangeFormulaire] = useState(false);

    const isFontionnel = props.controleEdit == RouteEnum.CONTROLE_EDIT_OPERATIONNEL ? true : false;
    return (

        <Form
            initialValue={saveInfo}
            profilerName="formControleAnalayse"
            resource={props.resourceDataProviderEnum}
            id={currentParamsRoute?.id}
            onChangeData={(e, ret) => {
                setSaveInfo(e!)
                setChangeFormulaire(true);
            }}
            onSubmit={async (e, ret) => {
                if (!e?.eqmtId) {
                    e!.eqmtId = currentParamsRoute.EqmtId;
                }
                e!.isUpdateAnalyses = true
            }}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(props.controleEdit,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: ret.newId.toString()
                        });
                }
                setChangeFormulaire(false);
                toast("form.infosUpdated", "success");
                if (ret.submitTag != "setSubmitForm") {
                    props.onNextAction("zoneAnalyse");
                }

            }}
            onDataApiLoaded={async (d: ControleDto | undefined) => {

            }}
            validationSchema={{
            }}
        >

            <FormHead
                enabledNextBtn={!props.isEditPage}
                changeForm={changeFormulaire}
                setSubmitForm={props.setSubmitForm}
                prependSaveComponent={<>
                    {props.isEditPage && <button className="btn btn-brand-primary" onClick={() => props.onNextAction("zoneAnalyse")}>
                        {"Suivant "} <Icon name="uil uil-angle-right-b" />
                    </button>}
                </>} />

            <div className="row">
                <div className={isFontionnel == true ? "col-md-6" : "col-md-12"} >
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Analyses</h3>
                        </div>
                        <div className="card-body">

                            <CollectionFormFieldMecanique
                                resourceDataProviderEnum={props.resourceDataProviderEnum}
                                nameFieldOutput="analyses"
                                disabled={props.isEditPage} />
                        </div>

                    </div>
                </div>

                {
                    isFontionnel &&
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title"></h3>
                            </div>
                            <div className="card-body">
                                <SelectCheckRadioField className="col-sm-9" name="marquage" label="Marquage" optionsList={[
                                    { label: "Oui", value: true },
                                    { label: "Non", value: false }]} disabled={props.isEditPage} />

                                <CheckField label="Installé avant 96" className="mt-3" name="marquageAvt96" disabled={props.isEditPage} />
                            </div>
                        </div>
                    </div>
                }

            </div>
        </Form>
    );
}