
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EspaceDto, SiteDto, TypeSolDto, TypeZoneDto, VilleDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import * as Yup from 'yup';
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { UploadFileField } from "@6tm/leeloo-react/component/form/field/UploadFileField";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { MimeTypeEnum } from "@6tm/leeloo-react/enum/MimeTypeEnum";
import { LoadDefaultUploadFileConfig } from "../../../config/LoadDefaultUploadFileConfig";
export const EspaceEditAddPage = () => {

    const { currentParamsRoute, goTo } = useRouter();

    const dsTypeZone = useDataSource<TypeZoneDto>({
        resource: ResourceDataProviderEnum.TYPE_ZONES,
        params: { expand: { field: [] } }, profilerName: "libelle"
    });

    const dsTypeSol = useDataSource<TypeSolDto>({
        resource: ResourceDataProviderEnum.TYPE_SOLS,
        params: { expand: { field: [] } }, profilerName: "libelle"
    });

    const { t } = useTrans();
    const toast = useToast();

    const breadcrumb = useBreadcrumb();
    const [espaceNumero, setEspaceNumero] = useState(0);

    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.siteId
        },
        singleResult: true
    });
    const oneDataSite = (dsSite.oneData as SiteDto);

    useEffect(() => {
        // on met l'id du dossier dans le breacrumb
        if (breadcrumb.isReady && oneDataSite) {

            const routes = [
                {
                    route: RouteEnum.APP_HOME,
                    icon: "home"
                },
                {
                    textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`,
                    route: RouteEnum.DOSSIERS_CONSULTATION.replace(":id", currentParamsRoute.dossierId)
                },
                {
                    textCustom: `Site : ${oneDataSite?.nom}`,
                    route: RouteEnum.SITES_CONSULTATION.replace(":id", currentParamsRoute.siteId).replace(":dossierId", currentParamsRoute.dossierId)
                }
            ];
            if (espaceNumero > 0) {
                routes.push({
                    textCustom: `Espace : ${espaceNumero}`,
                    route: RouteEnum.ESPACES_CONSULTATION.replace(":id", currentParamsRoute.id)
                        .replace(":dossierId", currentParamsRoute.dossierId)
                        .replace(":siteId", currentParamsRoute.siteId)
                },
                    {
                        textCustom: `Edition d'un espace`,
                        route: ""
                    }
                )
            }
            else {
                routes.push({
                    textCustom: `Création d'un espace`,
                    route: ""
                })
            }

            breadcrumb.resetAndSet(routes);
        }

    }, [breadcrumb.isReady && oneDataSite && espaceNumero])

    const [initValue, setInitValue] = useState<EspaceDto>({ siteId: currentParamsRoute.siteId })
    const isCreate = currentParamsRoute?.id > 0 ? false : true;
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={currentParamsRoute?.id > 0 ? RouteEnum.ESPACES_EDIT : RouteEnum.ESPACES_ADD}
                                        params={{
                                            id: currentParamsRoute?.id,
                                            siteId: currentParamsRoute.siteId,
                                            dossierId: currentParamsRoute.dossierId
                                        }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item" style={{ pointerEvents: isCreate ? "none" : "auto", opacity: isCreate ? 0.5 : 1 }}>
                                    <RouterLink className="nav-link" routeName={RouteEnum.LIST_EQUIPEMENT}
                                        params={{
                                            id: currentParamsRoute?.id,
                                            siteId: currentParamsRoute?.siteId,
                                            dossierId: currentParamsRoute.dossierId
                                        }}
                                    ></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <Form
                initialValue={initValue}
                resource={ResourceDataProviderEnum.ESPACES}
                id={currentParamsRoute?.id}
                onSubmit={async (e, ret) => {
                    e!.urlPlan = null
                }}
                onSubmitEndUpdate={(e, ret) => {
                    // si on a le newId c'est qu'on est sur la page de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        // /dossiers/:dossierId/sites/:siteId/espaces/edit/:id
                        goTo(RouteEnum.ESPACES_EDIT, {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            id: ret?.newId
                        });
                    }

                    toast("form.infosUpdated", "success");
                }}
                onDataApiLoaded={async (d: EspaceDto | undefined) => {

                    if (d) {
                        setEspaceNumero(d?.numero!)
                    }
                }}
                //paramsResource={{ expand: { field: ["Adresse"] } }}
                validationSchema={{
                    numero: Yup.number().min(1, "Ce champ doit être supérieur à 0").required("Le numéro est requis"),
                    typeSol: Yup.object().shape({
                        id: Yup.number().required("Le type de sol est requis").nullable(),
                    }),
                    typeZone: Yup.object().shape({
                        id: Yup.number().required("Le type de zone est requis").nullable(),

                    }),
                }}
            >
                <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                    <div className="sticky-actions-inner">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn-list ms-auto">
                                <RouterLink className="btn" routeName={RouteEnum.ESPACES_LISTE}
                                    params={{
                                        id: currentParamsRoute.siteId,
                                        dossierId: currentParamsRoute.dossierId
                                    }}><i className="uil uil-angle-left" /> Retour
                                </RouterLink>
                                <SubmitBtn className="btn btn-primary" >
                                    {t("actions.save")}
                                </SubmitBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Informations</h3>
                            </div>
                            <div className="card-body">
                                <InputField<EspaceDto> className="mb-2" label="Numéro" name="numero" type="number" />
                                <SelectField
                                    className="mb-3"
                                    name="typeZone.id"
                                    label="Type zone"
                                    isSearchable={true}
                                    // la resource externe sous forme de dataSource pour charger les données
                                    externalResource={{ dataSource: dsTypeZone, labelKey: "libelle", valueKey: "id" }}
                                />

                                <SelectField
                                    className="mb-3"
                                    name="typeSol.id"
                                    label="Type sol"
                                    isSearchable={true}
                                    // la resource externe sous forme de dataSource pour charger les données
                                    externalResource={{ dataSource: dsTypeSol, labelKey: "libelle", valueKey: "id" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Plan</h3>
                            </div>
                            <div className="card-body">
                                <div style={{ pointerEvents: isCreate ? "none" : "auto", opacity: isCreate ? 0.5 : 1 }}>
                                    {
                                        isCreate &&
                                        <h3 style={{ textAlign: "center" }} >Veuillez enregistrer l'espace avant d’ajouter un plan</h3>
                                    }
                                    <UploadFileField
                                        name="idFile"
                                        fileType={[MimeTypeEnum.imageAll]}
                                        resourceFile={ResourceDataProviderEnum.ESPACES_FILE}
                                        paramsComplementFile={{ id: currentParamsRoute.id }}
                                        disabledDeleteApi={true}
                                        loadFiles={LoadDefaultUploadFileConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </BaseContainer >

    );
}