import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap"
import { ResourceDataProviderEnum } from "../../enum/ResourceDataProviderEnum";
import { TypeCommentaireDto } from "../../_generated/clientdto.generated";
import { TextareaField } from "@6tm/leeloo-react/component/form/field/TextareaField";
import { ContextForm } from "@6tm/leeloo-react/component/form/Form";
import Select, { } from 'react-select'
import { ThemeCommentaireType } from "../../model/theme/ThemeCommentaireType";
import "bootstrap/dist/css/bootstrap.css";
import { ThemeOptionType } from "../../model/theme/ThemeOptionType";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";

type Props = {
    idEquipement: number | null,
    nameRefKpi: string | null,
    nameFieldOutput: string,
    show: boolean,
    handleClose: () => void
    onNewComment: (comment: string) => void,
}


export const ZoneObservationComOffCanvas = (props: Props) => {

    const form = useContext(ContextForm);
    const dsComment = useDataSource<TypeCommentaireDto>({
        resource: ResourceDataProviderEnum.TYPE_COMMENTAIRE_BY_EQUIPEMENT,
        params: { id: props.idEquipement }
    });

    const [options, setOptions] = useState<ThemeCommentaireType[]>([]);

    useEffect(() => {
        if (dsComment.sourceData) {
            let newData: ThemeCommentaireType[] = []

            for (const i in dsComment.sourceData.value) {

                const comment = dsComment.sourceData.value[i] as TypeCommentaireDto;
                if (newData.length > 0) {
                    const oneData = newData.find((v: any) => v.label == comment.theme);
                    if (oneData) {

                        const arr = [...oneData.options, {
                            value: comment.libelle,
                            label: comment.code ? comment.libelle + " (" + comment.code + ")" : comment.libelle,
                            id: comment.id
                        }]
                        oneData.options = arr;
                    }
                    else {
                        newData.push({
                            label: comment.theme!,
                            options: [{
                                value: comment.libelle,
                                label: comment.code ? comment.libelle + " (" + comment.code + ")" : comment.libelle,
                                id: comment.id
                            }]
                        })
                    }
                }
                else {
                    newData.push({
                        label: comment.theme!,
                        options: [{
                            value: comment.libelle,
                            label: comment.code ? comment.libelle + " (" + comment.code + ")" : comment.libelle,
                            id: comment.id
                        }]
                    })
                }
            }
            setOptions(newData)
        }


    }, [!dsComment.isLoading]);


    const [commentsRef, setCommentsRef] = useState<{
        title: string | null,
        code: string | null,
        constat: string | null,
        analyse: string | null,
        mesureCorrective: string | null,
        resultats: string | null
    }>({
        title: null,
        code: null,
        constat: null,
        analyse: null,
        mesureCorrective: null,
        resultats: null,
    });

    const handleChange = (event: any) => {
        var option = event as ThemeOptionType;

        if (option) {
            const oneData = dsComment.dataList.find((v: TypeCommentaireDto) => v.id == option.id);
            if (oneData) {
                setCommentsRef({
                    title: oneData?.libelle ?? null,
                    code: oneData!.code ?? null,
                    constat: oneData?.texte ?? null,
                    analyse: oneData?.analyse ?? null,
                    mesureCorrective: oneData?.mesure ?? null,
                    resultats: oneData?.resultat ?? null,
                })
            }
            else {
                setCommentsRef({
                    title: null,
                    code: null,
                    constat: null,
                    analyse: null,
                    mesureCorrective: null,
                    resultats: null,
                })
            }

        }
        else {

            setCommentsRef({
                title: null,
                code: null,
                constat: null,
                analyse: null,
                mesureCorrective: null,
                resultats: null,
            })
        }

    };

    const changeObservation = (comment: string, isDelete: boolean) => {

        const data = form.values[props.nameFieldOutput] as string;
        let newComment = data;

        if (!newComment) {
            newComment = "";
        }
        if (isDelete) {
            newComment = newComment?.replace(" " + comment, "");
        } else {
            newComment += " " + comment;
        }
        props.onNewComment(newComment)
        form.onChangeFieldsValues({ [props.nameFieldOutput]: newComment })
    }


    const groupBadgeStyles: CSSProperties = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: "#db5400"
    };

    const formatGroupLabel = (data: any) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const styles = {
        height: '33px !important',
        marginTop: '30px',
        paddingLeft: '10px',
        width: 47
    }
    const stylesIcon = {
        fontSize: 22
    }
    return (
        <Offcanvas show={props.show} onHide={() => props.handleClose()} placement="end" backdrop={true} scroll={true}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <span>{props.nameRefKpi}</span>
                </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Select
                    options={options}
                    isClearable={true}
                    onChange={handleChange}
                    formatGroupLabel={formatGroupLabel}
                />
                <div className="row">
                    <TextareaField className="col-10" label="Libellé" value={commentsRef.title} disabled />
                    {/* {
                        commentsRef.title &&
                        <button className="btn btn-brand-primary col-1" style={styles} onClick={() => changeObservation(commentsRef.title!, false)}>
                            +</button>
                    } */}
                </div>
                <InputField className="col-10" label="Code" value={commentsRef.code} disabled />
                <div className="row">
                    <TextareaField className="col-10" label="Observation" value={commentsRef.constat} disabled />
                    {
                        commentsRef.constat &&
                        <button title="Insérer ce commentaire" className="btn btn-brand-primary col-1" style={styles} onClick={() => changeObservation(commentsRef.constat!, false)}>
                            <i className="uil uil-file-import" style={stylesIcon}></i></button>
                    }
                </div>
                <div className="row">
                    <TextareaField className="col-10 4" label="Analyse" value={commentsRef.analyse} disabled />
                    {
                        commentsRef.analyse &&
                        <button title="Insérer ce commentaire" className="btn btn-brand-primary col-1" style={styles} onClick={() => changeObservation(commentsRef.analyse!, false)}>
                            <i className="uil uil-file-import" style={stylesIcon}></i></button>
                    }
                </div>
                <div className="row">
                    <TextareaField className="col-10 5" label="Mesures correctives" value={commentsRef.mesureCorrective} disabled />
                    {
                        commentsRef.mesureCorrective &&
                        <button title="Insérer ce commentaire" className="btn btn-brand-primary col-1" style={styles} onClick={() => changeObservation(commentsRef.mesureCorrective!, false)}>
                            <i className="uil uil-file-import" style={stylesIcon}></i></button>
                    }
                </div>
                <div className="row">
                    <TextareaField className="col-10 6" label="Résultats des mesures correctives" value={commentsRef.resultats} disabled />
                    {
                        commentsRef.resultats &&
                        <button title="Insérer ce commentaire" className="btn btn-brand-primary col-1" style={styles} onClick={() => changeObservation(commentsRef.resultats!, false)}>
                            <i className="uil uil-file-import" style={stylesIcon}></i></button>
                    }
                </div>

            </Offcanvas.Body>

        </Offcanvas >
    )
}