import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";

export const GoBackButton = (props: {
    routeName: string,
    params?: {
        [key in string | number]: string | number;
    }
}) => {
    return (

        <RouterLink className="btn" routeName={props.routeName}
            params={props.params}>
            <i className="uil uil-angle-left" />Retour
        </RouterLink>

    )
}