import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { BaseContainer } from "../../layout/BaseContainer";
import { ControleInformationHead } from "../controle/ControleInformationHead";
import { EquipementFormPage } from "./EquipementFormPage";

export const EquipementEditPage = () => {

    const { currentParamsRoute } = useRouter();


    return (

        <BaseContainer>

            <ControleInformationHead
                controle={{}}
                eqmtId={currentParamsRoute.id}
                label="Edition d'un équipement"
                typeControle={null} />

            <EquipementFormPage
                isCreate={false} />
        </BaseContainer>

    );
}