import { CollectionFormField } from "@6tm/leeloo-react/component/form/field/CollectionFormField";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectCheckRadioField } from "@6tm/leeloo-react/component/form/field/SelectCheckRadioField";
import { ContextForm } from "@6tm/leeloo-react/component/form/Form";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useContext, useEffect } from "react";
import { ControleTypeEnum } from "../../../../enum/actions/ControleTypeEnum";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { ControleDto } from "../../../../_generated/clientdto.generated";

type Props = {

    resourceDataProviderEnum: string
    nameFieldOutput: string
    disabled: boolean
    controle?: ControleDto
}

export const CollectionFormFieldMecanique = (props: Props) => {

    const { currentParamsRoute, currentRouteName } = useRouter();
    const form = useContext(ContextForm);
    const isCreate = (currentRouteName == RouteEnum.CONTROLE_CREATE
        || currentRouteName == RouteEnum.CONTROLE_CREATE_ROUTINE
        || currentRouteName == RouteEnum.CONTROLE_CREATE_OPERATIONNEL) ? true : false;

    let url = ResourceDataProviderEnum.CONTROLE_ANNUEL_MECANIQUE;
    let typeControle = ControleTypeEnum.ControlePrincipal;

    if (props.resourceDataProviderEnum.startsWith(ResourceDataProviderEnum.CONTROLE_OPERATIONNEL)) {
        url = ResourceDataProviderEnum.CONTROLE_OPERATIONNEL_MECANIQUE
        typeControle = ControleTypeEnum.ControleOperationnel
    }
    else if (props.resourceDataProviderEnum.startsWith(ResourceDataProviderEnum.CONTROLE_ROUTINE)) {
        url = ResourceDataProviderEnum.CONTROLE_ROUTINE_MECANIQUE
        typeControle = ControleTypeEnum.ControleRoutine
    }


    const { oneData, isReady } = useDataSource<ControleDto>({
        resource: url,
        params: {
            uriParameters: {
                eqmtId: currentParamsRoute?.EqmtId,
                typeControle: typeControle,
                checkDefault: isCreate == true ? true : null
            }
        },
        singleResult: true
    });

    useEffect(() => {
        if (isCreate && oneData) {
            form.onChangeFieldsValues({ [props.nameFieldOutput]: props.nameFieldOutput == "controlesMecaniques" ? oneData.controlesMecaniques! : oneData.analyses! })
        }
    }, [isReady && isCreate && oneData])

    useEffect(() => {

        if (props.controle && currentRouteName == RouteEnum.CONTROLE_CREATE) {
            form.onChangeFieldsValues({ hauteurAvtEssai: props.controle?.hauteurAvtEssai, hauteurApEssai: props.controle?.hauteurApEssai })

        }
    }, [props.controle && currentRouteName == RouteEnum.CONTROLE_CREATE])
    return (

        <>
            <CollectionFormField
                name={props.nameFieldOutput}
                disabledAddBtn
                disabledDeleteBtn
            >
                <InputField name="libelle" className="col-sm-3" disabled />
                <SelectCheckRadioField className="col-sm-9" name="ok" optionsList={[
                    { label: "Bon", value: true },
                    { label: "Mauvais", value: false },
                    { label: "Non renseigné", value: null }]} disabled={props.disabled} />
            </CollectionFormField>
        </>
    )

}