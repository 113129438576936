

import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { ControleDto } from "../../../../_generated/clientdto.generated";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { UploadFileField } from "@6tm/leeloo-react/component/form/field/UploadFileField";
import { FormHead } from "../../partiel/FormHead";
import { useState } from "react";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string
    isEditPage: boolean
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
    onNextAction: (nextAction: string) => void
}
export const ControleAddFiles = (props: Props) => {

    const { currentParamsRoute, goTo } = useRouter();
    const { t } = useTrans();
    const toast = useToast();

    let url = ResourceDataProviderEnum.CONTROLE_ANNUEL_FILE;

    if (props.resourceDataProviderEnum == ResourceDataProviderEnum.CONTROLE_OPERATIONNEL) {
        url = ResourceDataProviderEnum.CONTROLE_OPERATIONNEL_FILE
    }
    else if (props.resourceDataProviderEnum == ResourceDataProviderEnum.CONTROLE_ROUTINE) {
        url = ResourceDataProviderEnum.CONTROLE_ROUTINE_FILE
    }
    const isCreate = currentParamsRoute?.id > 0 ? false : true;
    const [changeFormulaire, setChangeFormulaire] = useState(false);
    return (

        <Form
            initialValue={{}}
            resource={props.resourceDataProviderEnum + "/Photo"}
            id={currentParamsRoute?.id}
            profilerName="formControleAddFile"
            onChangeData={(e, ret) => {
                setChangeFormulaire(true);
            }}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(props.controleEdit,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: ret.newId.toString()
                        });
                }
                setChangeFormulaire(false)
                toast("form.infosUpdated", "success");

                if (!isCreate) {

                    if (ret.submitTag != "setSubmitForm") {
                        props.onNextAction("validation")
                    }

                }
            }}
            onDataApiLoaded={async (d: ControleDto | undefined) => {
            }}
            onSubmit={async (e, ret) => {
                if (!e?.eqmtId) {
                    e!.eqmtId = currentParamsRoute.EqmtId;
                }
            }}
            validationSchema={{

            }}
        >
            <FormHead
                enabledNextBtn={!props.isEditPage && !isCreate}
                changeForm={changeFormulaire}
                setSubmitForm={props.setSubmitForm}
                prependSaveComponent={<>
                    {props.isEditPage && <button className="btn btn-brand-primary" onClick={() => props.onNextAction("validation")}>
                        {"Suivant "} <Icon name="uil uil-angle-right-b" />
                    </button>}

                    {isCreate &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Enregistrer" />
                    }
                </>} />


            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"></h3>
                        </div>
                        <div className="card-body" style={{ pointerEvents: isCreate || props.isEditPage ? "none" : "auto", opacity: isCreate || props.isEditPage ? 0.5 : 1 }}>
                            {
                                isCreate &&
                                <h3 style={{ textAlign: "center" }} >Veuillez enregistrer le contrôle avant d’ajouter des images</h3>
                            }
                            <UploadFileField
                                name="fileIds"
                                isMultiple
                                resourceFile={url}
                                enableSortable={true}
                                paramsComplementFile={{ id: currentParamsRoute?.id }}
                                disabled={props.isEditPage}
                                disabledDeleteApi={true}
                                loadFiles={{
                                    keyNameFile: "name",
                                    keyUrlImgFile: "url",
                                    useApi: true
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Form>

    );
}