import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { ControleDto } from "../../../../_generated/clientdto.generated";
import * as Yup from 'yup';
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { ZoneObservationComOffCanvas } from "../../../offCanvas/ZoneObservationComOffCanvas";
import { useState } from "react";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { TextareaField } from "@6tm/leeloo-react/component/form/field/TextareaField";
import { FormHead } from "../../partiel/FormHead";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string
    isEditPage: boolean
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
    onNextAction: (nextAction: string) => void
    onValidForm: (validForm: boolean) => void
}
export const ControleEditZoneObservation = (props: Props) => {

    const { currentParamsRoute, goTo } = useRouter();
    const toast = useToast();

    const [offCanvasCommentRef, setOffCanvasCommentRef] = useState<{
        show: boolean,
        nameRefKpi: string | null
    }>({
        show: false,
        nameRefKpi: null
    });

    const handleShow = () => setOffCanvasCommentRef({
        show: true,
        nameRefKpi: offCanvasCommentRef.nameRefKpi
    });

    const [saveInfo, setSaveInfo] = useState({})
    const [changeFormulaire, setChangeFormulaire] = useState(false);
    return (

        <Form
            initialValue={saveInfo}
            resource={props.resourceDataProviderEnum}
            profilerName="formControleObservation"
            id={currentParamsRoute?.id}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(props.controleEdit,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: ret.newId.toString()
                        });
                }
                setChangeFormulaire(false);
                toast("form.infosUpdated", "success");

                if (ret.submitTag != "setSubmitForm") {
                    props.onNextAction("analyse");
                }
                props.onValidForm(true)
            }}
            onDataApiLoaded={async (d: ControleDto | undefined) => {
                if (d && d.observation) {
                    props.onValidForm(true)
                }
                else {
                    props.onValidForm(false)
                }
            }}
            onSubmit={async (e, ret) => {
                if (!e?.eqmtId) {
                    e!.eqmtId = currentParamsRoute.EqmtId;
                }
                if (e && e.observation) {
                    props.onValidForm(true)
                }
                else {
                    props.onValidForm(false)
                }
            }}
            onChangeData={(e, ret) => {
                setSaveInfo(e!)
                setChangeFormulaire(true);

            }}
            validationSchema={{
                observation: Yup.string().trim().required("Ce champ est requis").nullable()
            }}
        >

            <FormHead
                enabledNextBtn={!props.isEditPage}
                changeForm={changeFormulaire}
                setSubmitForm={props.setSubmitForm}
                prependSaveComponent={<>
                    {props.isEditPage && <button className="btn btn-brand-primary" onClick={() => props.onNextAction("zoneAnalyse")}>
                        {"Suivant "} <Icon name="uil uil-angle-right-b" />
                    </button>}
                    {!props.isEditPage && <button className="btn btn-brand-primary" onClick={handleShow}><Icon name="uil uil-list-ul" /> Commentaire</button>}
                </>} />

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Observation</h3>
                        </div>
                        <div className="card-body">
                            <TextareaField<ControleDto> name="observation" disabled={props.isEditPage} minRows={4} />
                        </div>

                    </div>
                </div>
            </div>

            <ZoneObservationComOffCanvas
                {...offCanvasCommentRef}
                idEquipement={currentParamsRoute.EqmtId}
                nameRefKpi="Bibliothèque de commentaires"
                nameFieldOutput="observation"
                handleClose={() => setOffCanvasCommentRef({
                    show: false,
                    nameRefKpi: "Bibliothèque de commentaires"
                })}
                onNewComment={(comment) => {
                    //setSaveInfo({ observation: comment })
                }}
            />
        </Form>

    );
}