import { AuthReducerType } from "../../../model/AuthReducerType";
import { AuthReducerType as AuthLeelooReducerType } from "@6tm/leeloo-react/model/store/reducer/AuthReducerType";
import { useAuthProvider } from "@6tm/leeloo-react/component/hook/useAuthProvider";
import { Menu } from "./Menu";
import { Logo } from "./Logo";
import { useWriteReducer } from "@6tm/leeloo-react/component/hook/useWriteReducer";
import { useSelector } from "@6tm/leeloo-react/component/hook/useSelector";
import { ReducerEnum } from "../../../enum/store/ReducerEnum";
import { ConfigReducerType } from "../../../model/store/reducers/ConfigReducerType";
import { StateType } from "../../../model/store/StateType";

/**
 * @returns
 */
export const AppAside = (props: { appAsideOpened: boolean; onCloseAppAside: () => void; appAsideFolded: boolean; onFoldAppAside: () => void }) => {
	const {
		authData,
	}: {
		authData: AuthReducerType & AuthLeelooReducerType;
		checkAccess: (roles: string[]) => boolean;
	} = useAuthProvider()! as any;

	const { appAsideForceUnfolded } = useSelector((s: StateType) => s.config);
	const dispatch = useWriteReducer<ConfigReducerType>(ReducerEnum.config);

	const handleMouseEnter = () => {
		if (props.appAsideFolded) {
			dispatch.set("appAsideForceUnfolded", true);
		}
	};

	const handleMouseLeave = () => {
		if (props.appAsideFolded) {
			dispatch.set("appAsideForceUnfolded", false);
		}
	};

	return (
		<div className={"app-aside " + (props.appAsideOpened ? "opened " : " ") + (props.appAsideFolded && !appAsideForceUnfolded ? "folded " : " ")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className="app-aside-inner">
				<div className="app-aside-header">
					<button className="fold-aside" onClick={props.onFoldAppAside}>
						<i className="uil uil-angle-double-left">&nbsp;</i>
					</button>
					<Logo />
					<a className="toggle-app-aside" href="#" onClick={props.onCloseAppAside}>
						<i className="uil uil-times">&nbsp;</i>
					</a>
					<span className="badge badge-env bg-pink">Espace Client</span>
				</div>
				<div className="app-aside-body">
					{/*<a className="nav-head" href="#">
						<i className="uil uil-angle-left">&nbsp;</i>
						<i className="uil uil-chart-line">&nbsp;</i>
						Piloter
					</a>*/}

					<Menu />
				</div>
				{/* <div className="app-aside-footer">
					<div className="auth-status">
						<div className="avatar rounded-circle">
							<ThumbDisplay text={[authData.firstname, authData.lastname]} />
						</div>
						<div className="content">
							<div className="strong">
								{authData.firstname} {authData.lastname}
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};
