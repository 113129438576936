import logo from "./../../img/logo.png";
import bannerIllu from "./../../img/banner-illus.png";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum";

export const HomePage = () => {
	return (
		<div className="body-wrapper layout-public">
			<header className="public-header">
				<div className="container-xxl">

					<a href="/" className="logo">
						<img src={logo} alt="Normetec" />
					</a>

					<ul>
						<li><a href="#metier">Notre métier</a></li>
						<li><a href="#contact">Nous contacter</a></li>
					</ul>

					<RouterLink routeName={RouteLeelooEnum.login} className="btn btn-outline-primary">
						<i className="uil uil-user"></i> Mon espace client
					</RouterLink>

				</div>
			</header>
			<main>
				<div className="banner">
					<div className="container-xxl">
						<img src={bannerIllu} alt="" className="illus" />
						<h1><strong>Normetec</strong><br />Le contrôle d'équipements sportifs<br />& de jeux d'enfants
						</h1>
					</div>
				</div>
				<div className="part" id="metier">
					<div className="container-narrow">
						<h2>Notre métier</h2>
						<p>
							<strong>Normetec est une société indépendante de contrôle qui effectue les vérifications et
								essais d’équipements de sports et de jeux pour enfants</strong> et détermine leur
							conformité par rapport à des exigences spécifiques fixées par des textes réglementaires.
							Elle établit en fonction des règles édictées et sur la base d’un jugement professionnel, un
							rapport de
							vérification.
						</p>
						<p><strong>Normétec est une société socialement et juridiquement indépendante.</strong> Elle
							n’appartient pas et n’est liée à aucune autre entité.</p>
						<p>Son métier est exclusivement constitué par le :</p>
						<ul>
							<li>contrôle des équipements sportifs,</li>
							<li>contrôle des jeux et aires de jeux,</li>
							<li>la coordination pour la sécurité et la protection de la santé (S.P.S.)</li>
						</ul>
						<p><strong>Normétec répond parfaitement aux critères d’indépendance et d’impartialité</strong>,
							cela lui permet de ne pas être juge et partie dans son domaine d’activité.</p>
						<p><strong>Normétec est une société structurellement, financièrement et commercialement
							indépendante</strong>, elle n’est donc pas soumise à une collectivité ou un autre organe qui
							est susceptible d’exercer une pression commerciale ou financière pouvant influencer son
							jugement.</p>
						<p>Le personnel de Normétec n’exerce aucune autre activité que celle du contrôle technique ce
							qui lui permet de ne pas être influencé lors de l’établissement des rapports et la
							fourniture des résultats.</p>
						<p>Les contrôles sont effectués de manière impartiale et intègre.</p>
						<p>Normétec remplit totalement les critères d’indépendance pour un organisme d’inspection :</p>
						<ul>
							<li>elle est indépendante des parties engagées,</li>
							<li>le personnel n’exerce aucune autre activité que le contrôle technique,</li>
							<li>toutes les parties intéressées ont accès aux services de Normétec, il n’existe aucune
								condition financière ou autre inacceptable, les procédures d’inspection sont gérées de
								façon non discriminatoire,
							</li>
							<li>Normétec et son personnel responsable ne sont ni fabricant, ni concepteur, ni
								fournisseur, ni installateur, ni acheteur, ni propriétaire, ni utilisateur, ni
								réparateur des matériels inspectés.
							</li>
						</ul>
						<p>Le personnel qui a en charge la responsabilité des vérifications et l’établissement des
							rapports possède :</p>
						<ul>
							<li>une bonne connaissance des normes applicables,</li>
							<li>une bonne connaissance des matériels à vérifier,</li>
							<li>effectue une veille technologique et réglementaire en continu,</li>
							<li>une formation interne adéquate,</li>
							<li>une connaissance pratique des matériels permettant notamment les examens d’adéquation.
							</li>
						</ul>
					</div>
				</div>
				<div className="part part-contact" id="contact">
					<div className="container-xxl">
						<h2>Nous contacter</h2>
						<div className="row">
							<div className="col-lg-4 mb-3">
								<h3 className="mb-3">Un devis, un renseignement sur le logiciel ?</h3>
								<p>Contactez <strong>Muriel</strong></p>
								<p>Tél. : 02 97 75 88 88</p>
								<p>Email : <a href="mailto:info@normetec.fr">info@normetec.fr</a></p>
							</div>
							<div className="col-lg-4 mb-3">
								<h3 className="mb-3">Un conseil, un renseignement sur les réglementations ?</h3>
								<p>Contactez <strong>M. Duchesne</strong></p>
								<p>Tél. : 06 17 45 11 72</p>
								<p>Email : <a href="mailto:michel.duchesne@normetec.fr">michel.duchesne@normetec.fr</a>
								</p>
							</div>
							<div className="col-lg-4 mb-3">
								<h3 className="mb-3">Pour toute autre demande :</h3>
								<p><strong>NORMETEC</strong><br />ZI Pré Chenot<br />56380 Beignon</p>
								<p>Email : <a href="mailto:michel.duchesne@normetec.fr">info@normetec.fr</a></p>
								<p>Fax. : 02 97 75 86 87</p>
							</div>
						</div>
					</div>
				</div>
			</main>
			<div className="public-footer">
				<div className="container-xxl">© Normetec 2023</div>
			</div>
		</div>
	);
};
