import { ContextForm } from "@6tm/leeloo-react/component/form/Form"
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField"
import { DataSourceType } from "@6tm/leeloo-react/component/hook/useDataSource"
import { useContext } from "react"
import { OrganisationDto } from "../../../../_generated/clientdto.generated"

type Props = {
    data: DataSourceType
}
export const EditOrganisationForm = (props: Props) => {

    const form = useContext(ContextForm);
    return (

        <>
            <SelectField
                className="mb-2"
                name="Id"
                label="Organisation"
                isSearchable={true}
                onChangeValue={(s) => {

                    const tableauSelected = props.data.dataList.find((t: any) => t.id == s) as OrganisationDto;

                    form.onChangeFieldsValues({ libelle: tableauSelected.libelle, id: tableauSelected.id })
                }}
                // la resource externe sous forme de dataSource pour charger les données
                externalResource={{ dataSource: props.data, labelKey: "libelle", valueKey: "id" }}
            />
        </>
    )

}