import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { UtilisateurDossierDto } from "../../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { ModalForm } from "@6tm/leeloo-react/component/form/ModalForm";
import { Modal } from "react-bootstrap";
import { useDataProvider } from "@6tm/leeloo-react/component/hook/useDataProvider";
import { useAuth } from "../../../hook/useAuth";
import * as Yup from 'yup';
import { useState } from "react";
import { BooleanColumnIcon } from "../../../customColumn/BooleanColumnIcon";

export const UtilisateurEditAccessPage = () => {

    const { currentParamsRoute, goBack } = useRouter();
    const dsAccess = useDataSource({ resource: ResourceDataProviderEnum.USER_ACCESS, params: { pagination: { page: 1, take: 50 }, expand: { field: ["Dossier"] }, filter: { utilisateurSiteId: currentParamsRoute?.id } }, profilerName: "access" });
    const dsDossier = useDataSource({ resource: ResourceDataProviderEnum.DOSSIER, params: {}, profilerName: "dossier" });
    const dataProvider = useDataProvider();
    const toast = useToast();
    const auth = useAuth();
    const [saveInfo, setSaveInfo] = useState<UtilisateurDossierDto>(
        {
            utilisateurSiteId: currentParamsRoute.id,
            isCompteActif: false,
            isCr: false,
            isCf: false,
            isCa: false,
            isMaintenance: false,
        }
    )
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item ">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_UTILISATEURS_EDIT} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_UTILISATEURS_GESTION_ACCES} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>


            <div className="card">
                <div className="card-body centerHeader">
                    <DataTable
                        dataSource={dsAccess}
                        filter={
                            <Filter>
                                <InputField label="Rechercher" name="search" typeFilter="like" />
                            </Filter>
                        }
                        allowDelete={(r: UtilisateurDossierDto) => auth.isAdmim() == true && r.canDelete == true}
                        actionRow={(r: UtilisateurDossierDto) => [

                            <button className="btn btn-sm btn-outline-primary" onClick={async () => {
                                await dataProvider.update!(ResourceDataProviderEnum.APPLY_ALL_FOLDER, { id: r.id });
                                dsAccess.refreshData();
                                toast("form.infosUpdated", "success");
                            }} >
                                Appliquer à tous les dossiers
                            </button>

                        ]}
                        modalForm={
                            <ModalForm<UtilisateurDossierDto>
                                customHeader={<Modal.Title>Ajouter un dossier</Modal.Title>}
                                initialValue={saveInfo}
                                validationSchema={{
                                    isCompteActif: Yup.bool(),
                                    isCr: Yup.bool(),
                                    isCf: Yup.bool(),
                                    isCa: Yup.bool(),
                                    isMaintenance: Yup.bool(),
                                    dossierId: Yup.number().required("Le dossier est requis").nullable(),

                                }}
                            >
                                <SelectField className="mb-3" name="dossierId" label="Dossier"
                                    isSearchable={true}
                                    externalResource={{ dataSource: dsDossier, labelKey: "reference", valueKey: "id" }} />

                                <CheckField<UtilisateurDossierDto>
                                    name="isCompteActif" label="Compte actif"
                                />
                                <CheckField<UtilisateurDossierDto>
                                    name="isCr" label="CR"
                                />
                                <CheckField<UtilisateurDossierDto>
                                    name="isCf" label="CF"
                                />
                                <CheckField<UtilisateurDossierDto>
                                    name="isCa" label="CA"
                                />
                                <CheckField<UtilisateurDossierDto>
                                    name="isMaintenance" label="Maintenance"
                                />
                            </ModalForm>
                        }
                    >
                        <SimpleColumn field="dossier.reference" title="Référence" sortable />
                        <SimpleColumn field="dossier.nom" title="Libellé" sortable />


                        <BooleanColumnIcon field="isCompteActif" title="Compte actif" />
                        <BooleanColumnIcon field="isCr" title="CR" center />
                        <BooleanColumnIcon field="isCf" title="CF" center />
                        <BooleanColumnIcon field="isCa" title="CA" center />
                        <BooleanColumnIcon field="isMaintenance" title="Maintenance" center />
                    </DataTable>
                </div>
            </div>
        </BaseContainer >

    );
}