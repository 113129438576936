
/**
 * Fournis des fonctions d'affichage
 * @returns 
 */
export function useDisplay() {

    return {
        /**
         * Rend un "thumb" autrement dis : Valentin Renard => VR
         * @param text 
         * @returns 
         */
        thumb: (text: string|null|undefined): string => {
            if (text == null) return "";
            const t = text.split(" ") as string[];
            return t.map(el => el.substring(0,1).toUpperCase()).join("");
        }
    }
}