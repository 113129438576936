import React from "react";
import { ColumnType } from "@6tm/leeloo-react/model/dataTable/ColumnType";
import { AdresseDto, ControleDto, UtilisateurSiteDto } from "../../_generated/clientdto.generated";

/**
 * Afficher le nom et le prénom de l'utilisateur
 * @param props 
 * @returns 
 */
export function NomCompletColumn<T>(props: ColumnType<T>) {

    let nomComplet = "";
    const controle = props.row as ControleDto;

    if (controle.utilisateurSite?.nom) {
        nomComplet = controle.utilisateurSite?.nom?.toUpperCase()
    }

    if (controle.utilisateurSite?.prenom) {
        nomComplet += " " + controle.utilisateurSite?.prenom
    }
    return <>{nomComplet}</>;
};

