import { RouteType } from "@6tm/leeloo-react/model/RouteType";
import { UtilisateurListPage } from "../components/page/admin/users/UtilisateurListPage";
import { UtilisateurEditAddPage } from "../components/page/admin/users/UtilisateurEditAddPage";
import { HomePage } from "../components/page/HomePage";
import { AppHomePage } from "../components/page/AppHomePage";
import { RouteEnum } from "../enum/RouteEnum";
import { UserHabilitationEnum } from "../enum/UserHabilitationEnum";
import { UtilisateurEditAccessPage } from "../components/page/admin/users/UtilisateurEditAccesPage";
import { DossierListPage } from "../components/page/dossier/DossierListPage";
import { StructureAddPage } from "../components/page/admin/structure/StructureAddPage";
import { DossierEditAddPage } from "../components/page/dossier/DossierEditAddPage";
import { DossierListSitesPage } from "../components/page/dossier/DossierListSitesPage";
import { SiteEditAddPage } from "../components/page/site/SiteEditAddPage";
import { ListeEspacesPage } from "../components/page/espace/ListeEspacesPage";
import { FabriquantListePage } from "../components/page/admin/manufacturers/FabriquantListePage";
import { FabriquantEditAddPage } from "../components/page/admin/manufacturers/FabriquantEditAddPage";
import { ListeEquipementsPage } from "../components/page/equipement/ListeEquipementsPage";
import { ListeControlesPage } from "../components/page/controle/ListeControlesPage";
import { ControleEditOrAdd } from "../components/page/controle/ControleEditOrAdd";
import { ControleOperationnelEditOrAdd } from "../components/page/controle/ControleOperationnelEditOrAdd";
import { ControleRoutineEditOrAdd } from "../components/page/controle/ControleRoutineEditOrAdd";
import { MaintenanceEditOrAdd } from "../components/page/maintenance/MaintenanceEditOrAdd";
import { EquipementEditPage } from "../components/page/equipement/EquipementEditPage";
import { EquipementAddPage } from "../components/page/equipement/EquipementAddPage";
import { DossierConsultationPage } from "../components/page/dossier/DossierConsultationPage";
import { SiteConsultationPage } from "../components/page/site/SiteConsultationPage";
import { EspaceEditAddPage } from "../components/page/espace/EspaceEditAddPage";
import { EspaceConsultationPage } from "../components/page/espace/EspaceConsultationPage";
import { GenerateurDocumentsPage } from "../components/page/document/GenerateurDocumentsPage";
import { RequestUpdatePasswordForm } from "../components/page/auth/RequestUpdatePasswordForm";
/**
 * Configuration des routes exprimé ici
 */
export const RouteConfig: RouteType[] = [
	{
		label: "Accueil",
		routeUrl: RouteEnum.HOME,
		pageComponent: HomePage,
	},
	{
		label: "",
		routeUrl: RouteEnum.UPDATE_PASSWORD,
		pageComponent: RequestUpdatePasswordForm,
	},
	{
		label: "Dossiers",
		routeUrl: RouteEnum.APP_HOME,
		pageComponent: AppHomePage,
		rolesAccess: [UserHabilitationEnum.ROLE_CLIENT, UserHabilitationEnum.ROLE_ADMIN],
	},
	{
		label: "Utilisateurs",
		routeUrl: RouteEnum.ADMIN_UTILISATEURS_LIST,
		pageComponent: UtilisateurListPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.APP_HOME,
	},
	{
		label: "Edition d'un utilisateur",
		routeUrl: RouteEnum.ADMIN_UTILISATEURS_EDIT,
		pageComponent: UtilisateurEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_UTILISATEURS_LIST,
	},
	{
		label: "Création d'un utilisateur",
		routeUrl: RouteEnum.ADMIN_UTILISATEURS_ADD,
		pageComponent: UtilisateurEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_UTILISATEURS_LIST,
	},
	{
		label: "Gestion des accès",
		routeUrl: RouteEnum.ADMIN_UTILISATEURS_GESTION_ACCES,
		pageComponent: UtilisateurEditAccessPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_UTILISATEURS_LIST,
	},
	// SECTION MON DOSSIER
	{
		label: "Liste des dossiers",
		routeUrl: RouteEnum.DOSSIERS_LISTE,
		pageComponent: DossierListPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.APP_HOME,
	},
	{
		label: "Ajout d'une organisation",
		routeUrl: RouteEnum.ADMIN_STRUCTURE_CREATE,
		pageComponent: StructureAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_UTILISATEURS_LIST,
	},
	{
		label: "Création d'un dossier",
		routeUrl: RouteEnum.DOSSIERS_ADD,
		pageComponent: DossierEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.DOSSIERS_LISTE,
	},
	{
		label: "Edition d'un dossier",
		routeUrl: RouteEnum.DOSSIERS_EDIT,
		pageComponent: DossierEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LISTE,
	},
	{
		label: "Consultation",
		routeUrl: RouteEnum.DOSSIERS_CONSULTATION,
		pageComponent: DossierConsultationPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LISTE,
	},
	{
		label: "Générateur de documents",
		routeUrl: RouteEnum.DOCUMENTS,
		pageComponent: GenerateurDocumentsPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LISTE,
	},
	{
		label: "Liste des sites",
		routeUrl: RouteEnum.DOSSIERS_LIST_SITES,
		pageComponent: DossierListSitesPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LISTE,
	},
	{
		label: "Liste des sites",
		routeUrl: RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION,
		pageComponent: DossierListSitesPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LISTE,
	},

	{
		label: "Création d'un site",
		routeUrl: RouteEnum.SITES_ADD,
		pageComponent: SiteEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION,
	},
	{
		label: "Edition d'un site",
		routeUrl: RouteEnum.SITES_EDIT,
		pageComponent: SiteEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION,
	},
	{
		label: "Consultation",
		routeUrl: RouteEnum.SITES_CONSULTATION,
		pageComponent: SiteConsultationPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION,
	},
	// espace
	{
		label: "Liste des espaces",
		routeUrl: RouteEnum.ESPACES_LISTE,
		pageComponent: ListeEspacesPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_EDIT,
	},
	{
		label: "Création d'un espace",
		routeUrl: RouteEnum.ESPACES_ADD,
		pageComponent: EspaceEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.ESPACES_LISTE,
	},
	{
		label: "Edition d'un espace",
		routeUrl: RouteEnum.ESPACES_EDIT,
		pageComponent: EspaceEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.ESPACES_LISTE,
	},
	{
		label: "Consultation d'un espace",
		routeUrl: RouteEnum.ESPACES_CONSULTATION,
		pageComponent: EspaceConsultationPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.ESPACES_LISTE,
	},
	// Equipement
	{
		label: "Liste des équipements",
		routeUrl: RouteEnum.LIST_EQUIPEMENT,
		pageComponent: ListeEquipementsPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.ESPACES_LISTE,
	},
	{
		label: "Edition d'un équipement",
		routeUrl: RouteEnum.EQUIPEMENT_EDIT,
		pageComponent: EquipementEditPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_EQUIPEMENT,
	},
	{
		label: "Création d'un equipement",
		routeUrl: RouteEnum.EQUIPEMENT_CREATE,
		pageComponent: EquipementAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_EQUIPEMENT,
	},
	{
		label: "Fabriquants",
		routeUrl: RouteEnum.ADMIN_FABRIQUANTS_LIST,
		pageComponent: FabriquantListePage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.APP_HOME,
	},
	{
		label: "Création d'un fabriquant",
		routeUrl: RouteEnum.ADMIN_FABRIQUANTS_ADD,
		pageComponent: FabriquantEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_FABRIQUANTS_LIST,
	},
	{
		label: "Edition d'un fabriquant",
		routeUrl: RouteEnum.ADMIN_FABRIQUANTS_EDIT,
		pageComponent: FabriquantEditAddPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN],
		parent: RouteEnum.ADMIN_FABRIQUANTS_LIST,
	},
	{
		label: "Consultation des contrôles",
		routeUrl: RouteEnum.LIST_CONTROLE,
		pageComponent: ListeControlesPage,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_EDIT,
	},
	{
		label: "Consultation contrôle",
		routeUrl: RouteEnum.CONTROLE_EDIT,
		pageComponent: ControleEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.DOSSIERS_EDIT,
	},
	{
		label: "Ajout contrôle",
		routeUrl: RouteEnum.CONTROLE_CREATE,
		pageComponent: ControleEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_CONTROLE,
	},
	{
		label: "Contrôle opérationnel",
		routeUrl: RouteEnum.CONTROLE_EDIT_OPERATIONNEL,
		pageComponent: ControleOperationnelEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_CONTROLE,
	},
	{
		label: "Ajout opérationnel",
		routeUrl: RouteEnum.CONTROLE_CREATE_OPERATIONNEL,
		pageComponent: ControleOperationnelEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_CONTROLE,
	},
	{
		label: "Contrôle routine",
		routeUrl: RouteEnum.CONTROLE_EDIT_ROUTINE,
		pageComponent: ControleRoutineEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_CONTROLE,
	},
	{
		label: "Ajout routine",
		routeUrl: RouteEnum.CONTROLE_CREATE_ROUTINE,
		pageComponent: ControleRoutineEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.LIST_CONTROLE,
	},
	// maintenance annuel

	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_ANNUEL_EDIT,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT,
	},
	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_ANNUEL_CREATE,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT,
	},

	// maintenance opérationnel

	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT_OPERATIONNEL,
	},
	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT_OPERATIONNEL,
	},
	// maintenance routine

	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_ROUTINE_EDIT,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT_ROUTINE,
	},
	{
		label: "Fiche de maintenance",
		routeUrl: RouteEnum.MAINTENANCE_ROUTINE_CREATE,
		pageComponent: MaintenanceEditOrAdd,
		rolesAccess: [UserHabilitationEnum.ROLE_ADMIN, UserHabilitationEnum.ROLE_CLIENT],
		parent: RouteEnum.CONTROLE_EDIT_ROUTINE,
	},
];


