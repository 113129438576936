import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { DossierDto, SiteDto, VilleDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import * as Yup from 'yup';
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect, useState } from "react";
import { UploadFileField } from "@6tm/leeloo-react/component/form/field/UploadFileField";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { MimeTypeEnum } from "@6tm/leeloo-react/enum/MimeTypeEnum";
import { LoadDefaultUploadFileConfig } from "../../../config/LoadDefaultUploadFileConfig";
import { AdresseField } from "../../form/field/AdresseField";
export const SiteEditAddPage = () => {

    const { currentParamsRoute, goTo } = useRouter();

    const { t } = useTrans();
    const toast = useToast();

    const [villeId, setVilleId] = useState(0);
    const breadcrumb = useBreadcrumb();
    const [siteName, setSiteName] = useState("");
    const [dossierName, setDossierName] = useState("");
    const isCreate = currentParamsRoute?.id > 0 ? false : true;

    const dsDossier = useDataSource<DossierDto>({
        resource: ResourceDataProviderEnum.DOSSIER,
        params: {
            id: currentParamsRoute.dossierId
        },
        singleResult: true,
        noLoadAfterInit: !isCreate
    });

    const oneDataDossier = (dsDossier.oneData as DossierDto);

    useEffect(() => {

        if (dsDossier.isReady && isCreate && oneDataDossier) {
            setDossierName(oneDataDossier.reference!)
        }

    }, [dsDossier.isReady])

    useEffect(() => {
        // on met l'id du dossier dans le breacrumb

        if (breadcrumb.isReady) {
            const routes = [
                {
                    route: RouteEnum.APP_HOME,
                    icon: "home"
                },
                {
                    textCustom: `Dossier : ${dossierName ?? oneDataDossier.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION.replace(":id", currentParamsRoute.dossierId),
                }
            ];

            if (siteName) {

                routes.push(
                    {
                        textCustom: `Site : ${siteName}`,
                        route: RouteEnum.SITES_CONSULTATION.replace(":id", currentParamsRoute.id)
                            .replace(":dossierId", currentParamsRoute.dossierId),

                    },
                    {
                        textCustom: "Edition d'un site",
                        route: ""
                    }
                )
            }
            else {
                routes.push(
                    {
                        textCustom: "Création d'un site",
                        route: RouteEnum.SITES_ADD
                            .replace(":dossierId", currentParamsRoute.dossierId),
                    }
                )
            }

            breadcrumb.resetAndSet(routes);
        }

    }, [breadcrumb.isReady, dossierName, siteName])


    const [initValue, setInitValue] = useState<SiteDto>({ dossierId: currentParamsRoute.dossierId })
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={currentParamsRoute?.id > 0 ? RouteEnum.SITES_EDIT : RouteEnum.SITES_ADD}
                                        params={{ id: currentParamsRoute?.id, dossierId: currentParamsRoute.dossierId }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item" style={{ pointerEvents: isCreate ? "none" : "auto", opacity: isCreate ? 0.5 : 1 }}>
                                    <RouterLink className="nav-link" routeName={RouteEnum.ESPACES_LISTE}
                                        params={{ id: currentParamsRoute?.id, dossierId: currentParamsRoute.dossierId }}
                                    ></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <Form
                initialValue={initValue}
                resource={ResourceDataProviderEnum.SITES}
                id={currentParamsRoute?.id}
                onSubmit={async (e, ret) => {
                    e!.urlPlan = null
                }}
                onSubmitEndUpdate={(e, ret) => {
                    // si on a le newId c'est qu'on est sur la page de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(RouteEnum.SITES_EDIT, {
                            id: ret?.newId,
                            dossierId: currentParamsRoute.dossierId
                        });
                    }

                    toast("form.infosUpdated", "success");
                }}
                onDataApiLoaded={async (d: SiteDto | undefined) => {
                    if (d?.adresse?.villeId != null && d?.adresse?.villeId != undefined) {
                        setVilleId(d?.adresse?.villeId)
                    }
                    if (d) {
                        setSiteName(d.nom!)
                        setDossierName(d.dossier?.reference!)
                    }
                }}
                paramsResource={{ expand: { field: ["Adresse"] } }}
                validationSchema={{
                    numero: Yup.number().min(1, "Ce champ doit être supérieur à 0").required("Le numéro est requis"),
                    dossierId: Yup.number().required(),
                    nom: Yup.string().required("Le nom est requis"),
                    adresse: Yup.object().shape({
                        villeId: Yup.number().required("La ville est requise"),
                    })
                }}
            >
                <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                    <div className="sticky-actions-inner">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn-list ms-auto">
                                <RouterLink className="btn" routeName={RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION}
                                    params={{ id: currentParamsRoute.dossierId }}><i className="uil uil-angle-left" /> Retour
                                </RouterLink>
                                <SubmitBtn className="btn btn-primary" >
                                    {t("actions.save")}
                                </SubmitBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Informations du site</h3>
                            </div>
                            <div className="card-body">
                                <InputField<SiteDto> className="mb-2" label="Numéro du site" name="numero" type="number" />
                                <InputField<SiteDto> className="mb-2" label="Numero de site client" name="numeroClient" />
                                <InputField<SiteDto> className="mb-2" label="Nom du site" name="nom" />

                                <div style={{ pointerEvents: isCreate ? "none" : "auto", opacity: isCreate ? 0.5 : 1 }}>
                                    {
                                        isCreate &&
                                        <h3 style={{ textAlign: "center" }} >Veuillez enregistrer le site avant d’ajouter une image</h3>
                                    }
                                    <UploadFileField
                                        name="idFile"
                                        fileType={[MimeTypeEnum.imageAll]}
                                        resourceFile={ResourceDataProviderEnum.SITES_FILE}
                                        paramsComplementFile={{ id: currentParamsRoute.id }}
                                        disabledDeleteApi={true}
                                        loadFiles={LoadDefaultUploadFileConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Adresse</h3>
                            </div>
                            <div className="card-body">
                                <AdresseField villeId={villeId}></AdresseField>
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </BaseContainer >

    );
}