import { GroupRouteType } from "@6tm/leeloo-react/model/GroupRouteType";
import { RouteEnum } from "../enum/RouteEnum";

export const MenuRouteConfig: GroupRouteType[] = [
	// {
	// 	label: "Accueil",
	// 	icon: "uil uil-estate",
	// 	routes: [RouteEnum.APP_HOME],
	// },
	{
		label: "Liste des dossiers",
		icon: "uil uil-folder",
		routes: [RouteEnum.DOSSIERS_LISTE],
	},
	{
		label: "Administration",
		icon: "uil uil-user",
		routes: [
			RouteEnum.ADMIN_UTILISATEURS_LIST,
			RouteEnum.ADMIN_FABRIQUANTS_LIST
		],
	},
];
