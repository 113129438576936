import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink"
import { OrganisationDto } from "../../../../_generated/clientdto.generated"
import { RouteEnum } from "../../../../enum/RouteEnum"
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans"
import { Icon } from "@6tm/leeloo-react/component/display/Icon"
import { useContext } from "react"
import { ContextForm } from "@6tm/leeloo-react/component/form/Form"
import { useModal } from "@6tm/leeloo-react/component/hook/useModal"

type Props = {
    data: OrganisationDto[]
}
export const StructureAddForm = (props: Props) => {
    const { t } = useTrans();
    const form = useContext(ContextForm);

    const { confirm } = useModal();

    const getListOrganisationByName = (libelle: string | null) => {

        let organisations = props.data.filter(function (organisation) {
            const values = organisation as OrganisationDto;
            return values.libelle?.toUpperCase() == libelle?.toUpperCase()
        });
        var valueSite = organisations as OrganisationDto[]
        return valueSite;
    }

    const onEnregistrerClick = async () => {
        const value = form.values.libelle;
        if (value?.length == 0) {
            return;
        }
        const doublons = getListOrganisationByName(value);

        if (doublons.length == 0) {
            form.handleSubmit()
        }
        else {
            const response = await confirm(textConfirmation(doublons));
            if (response) {
                form.handleSubmit()
            }
        }


    };
    function textConfirmation(doublons: OrganisationDto[]) {

        const confirmText = `Des doublons potentiels ont été trouvés pour cette organisation '${form.values.libelle}' : `
        return <div>
            {confirmText}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Libelle</th>
                    </tr>
                </thead>
                <tbody>
                    {doublons.map((orga, i) => {
                        return (<tr>
                            <th scope="row">{orga.libelle}</th>
                        </tr>)
                    })}

                </tbody>
            </table>
            {"Souhaitez-vous tout de même créer une nouvelle organisation ?"}
        </div>
    }
    return (

        <>
            <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                <div className="sticky-actions-inner">
                    <div className="d-flex gap-2 align-items-center">
                        <div className="btn-list ms-auto">
                            <RouterLink className="btn" routeName={RouteEnum.ADMIN_UTILISATEURS_LIST}><Icon name="uil uil-angle-left" /></RouterLink>
                            <button className={"btn btn-primary" + ((form.values.libelle?.length == 0) ? " disabled " : " ")}
                                onClick={() => onEnregistrerClick()}> Enregistrer </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}