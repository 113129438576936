import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { OrganisationDto, UtilisateurSiteDto } from "../../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../../layout/BaseContainer";
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import * as Yup from 'yup';
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import { CSSProperties, useState } from "react";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { AdresseField } from "../../../form/field/AdresseField";
import { ModalForm } from "@6tm/leeloo-react/component/form/ModalForm";
import { EditOrganisationForm } from "./EditOrganisationForm";

export const UtilisateurEditAddPage = () => {

    const { currentParamsRoute, currentRoute, goTo } = useRouter();
    const dsOrganisation = useDataSource({
        resource: ResourceDataProviderEnum.ORGANISATIONS, params:
        {
            sort:
            {
                field: "Libelle", order: "asc"
            }
        }, profilerName: "organisation"
    });
    const { t } = useTrans();
    const toast = useToast();
    const [isAdmin, setIsAdmin] = useState(false);
    const [villeId, setVilleId] = useState(0);

    const passwordRegex = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\/-__+.]){1,}).{8,50}$/;
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    const tooltip = (
        <Tooltip id="tooltip">
            Pour votre sécurité, il faut utiliser un mot de passe contenant : <br />
            <strong>
                - Minimum 8 caractères
            </strong>
            <br />
            <strong>
                - Minimum une majuscule
            </strong>
            <br />
            <strong>
                - Minimum une minuscule
            </strong>
            <br />
            <strong>
                - Minimum un chiffre
            </strong>
            <br />
            <strong>
                - Minimum un caractère spécial
            </strong>
        </Tooltip>
    );
    const styleIconIFormation: CSSProperties = {
        width: 37,
        height: 35,
        padding: '7px 6px',
        fontSize: '18px',
        lineHeight: '1.33',
        borderRadius: '25px'
    };

    const [password, setPassword] = useState("");

    const [saveInfo, setSaveInfo] = useState<UtilisateurSiteDto>(
        { isAdmin: false, nom: "", prenom: "", fonction: "", newLogin: "", email: "", password: "" }
    )
    let isUpdate = true;
    if (currentRoute!.routeUrl == RouteEnum.ADMIN_UTILISATEURS_ADD) {
        isUpdate = false;
    }
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    {isUpdate &&
                                        <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_UTILISATEURS_EDIT} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                    }
                                    {!isUpdate &&
                                        <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_UTILISATEURS_ADD}></RouterLink>
                                    }
                                </li>
                                {(isAdmin == false && currentParamsRoute?.id != undefined) &&
                                    <>
                                        <li className="nav-item">
                                            <RouterLink className="nav-link" routeName={RouteEnum.ADMIN_UTILISATEURS_GESTION_ACCES} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>
            <ModalForm<OrganisationDto>
                title="Ajouter une organisation"
                centered
                show={showModal}
                handleClose={() => setShowModal(false)}
                onSubmitEndUpdate={(e, ret) => {
                    dsOrganisation.refreshData();
                    toast("form.infosUpdated", "success");
                }}
                validationSchema={{
                    libelle: Yup.string().required("Ce champ est requis")
                }}
                resource={ResourceDataProviderEnum.ORGANISATIONS}
            >
                <InputField<OrganisationDto> className="mb-2" label="Libellé" name="libelle" />
            </ModalForm>

            <ModalForm<OrganisationDto>
                title="Editer une organisation"
                centered
                show={showModalEdit}
                profilerName="EditerOrganisation"
                handleClose={() => setShowModalEdit(false)}
                onSubmitEndUpdate={(e, ret) => {
                    dsOrganisation.refreshData();
                    toast("form.infosUpdated", "success");
                }}
                validationSchema={{
                    libelle: Yup.string().required("Ce champ est requis")
                }}
                resource={ResourceDataProviderEnum.ORGANISATIONS}
            >

                <EditOrganisationForm
                    data={dsOrganisation} />
                <InputField<OrganisationDto> className="mb-2" label="Libellé" name="libelle" />
            </ModalForm>


            <Form
                initialValue={saveInfo}
                resource={ResourceDataProviderEnum.USERS_SITE}
                id={currentParamsRoute?.id}
                onSubmitEndUpdate={(e, ret) => {
                    setIsAdmin(e?.isAdmin == true);

                    // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(RouteEnum.ADMIN_UTILISATEURS_EDIT.replace(":mode", "new").replace(":id", ret.newId.toString()));
                    }

                    toast("form.infosUpdated", "success");
                }}
                onDataApiLoaded={async (d: UtilisateurSiteDto | undefined) => {
                    setIsAdmin(d?.isAdmin == true);
                    if (d?.adresse?.villeId != null && d?.adresse?.villeId != undefined) {
                        setVilleId(d?.adresse?.villeId)
                    }
                    if (!d?.password) {
                        d!.password = "";
                    }
                }}
                onChangeData={(e, ret) => {
                    if (e) {
                        setSaveInfo(e)
                    }
                    if (e && e.password) {
                        setPassword(e?.password!)
                    }
                    else {
                        setPassword("")
                    }

                }}

                paramsResource={{ expand: { field: ["Adresse"] } }}
                validationSchema={{
                    nom: Yup.string().required("Le nom est requis"),
                    prenom: Yup.string().required("Le prénom est requis"),
                    newLogin: Yup.string().required("Le login est requis"),
                    isPremiereConnexion: Yup.bool(),
                    password: Yup.string().when('isPremiereConnexion', {
                        is: true,
                        then: (schema) => schema.trim().matches(passwordRegex, "Mot de passe invalide").required("Le mot de passe est requis")
                    }),
                    isAdmin: Yup.boolean().required(),
                    email: Yup.string().required("L'email est requis").matches(emailRegex, "L'email est non valide").nullable(),
                    adresse: Yup.object().shape({
                        villeId: Yup.number().required("La ville est requise"),
                    }),

                }}


            >
                <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                    <div className="sticky-actions-inner">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="btn-list ms-auto">
                                <RouterLink className="btn" routeName={RouteEnum.ADMIN_UTILISATEURS_LIST} params={{ id: currentParamsRoute?.id }}><i className="uil uil-angle-left"></i></RouterLink>
                                <SubmitBtn className="btn btn-primary" >
                                    {t("actions.save")}
                                </SubmitBtn>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{t("form.personalInfo")}</h3>
                            </div>
                            <div className="card-body">
                                <InputField<UtilisateurSiteDto> className="mb-2" label="form.nom" name="nom" />
                                <InputField<UtilisateurSiteDto> className="mb-2" label="form.prenom" name="prenom" />
                                <InputField<UtilisateurSiteDto> className="mb-2" label="form.fonction" name="fonction" />
                                <InputField<UtilisateurSiteDto> className="mb-2" label="Login" name="newLogin" />
                                <div className="row">
                                    <div className="col-md-10" style={{ width: "87%" }}>

                                        <InputField<UtilisateurSiteDto> className="mb-2" label="form.password" name="password" type="password" />
                                    </div>

                                    <div className="col-md-1" style={{ paddingTop: 29 }}>

                                        <OverlayTrigger
                                            placement="right" overlay={tooltip}>
                                            <button type="button" style={styleIconIFormation} className="btn btn-secondary btn-circle"><i className="uil uil-info"></i></button>
                                        </OverlayTrigger>
                                    </div>

                                </div>

                                <InputField<UtilisateurSiteDto> className="mb-2" label="form.email" name="email" />

                                <div className="row">
                                    <div className="col-md-10" style={{ width: "87%" }}>

                                        <SelectField
                                            className="mb-2"
                                            name="organisationId"
                                            label="Organisation"
                                            isSearchable={true}
                                            // la resource externe sous forme de dataSource pour charger les données
                                            externalResource={{ dataSource: dsOrganisation, labelKey: "libelle", valueKey: "id" }}
                                        />
                                    </div>

                                    <div className="col-md-1" style={{ paddingTop: 29 }}>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" title="Ajouter ou modifier une organisation">
                                                Action
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setShowModalEdit(true)}>
                                                    <Icon name="edit" /> Editer
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setShowModal(true)}>
                                                    <Icon name="uil uil-plus" /> Ajouter
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <CheckField<UtilisateurSiteDto> label="form.admin" name="isAdmin" className="mt-1" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Adresse</h3>
                            </div>
                            <div className="card-body">
                                <AdresseField villeId={villeId} />
                            </div>

                        </div>
                    </div>
                </div>
            </Form>
        </BaseContainer >

    );
}