import React from "react";
import { ColumnType } from "@6tm/leeloo-react/model/dataTable/ColumnType";
import { AdresseDto } from "../../_generated/clientdto.generated";

/**
 * Afficher l'adresse postable complète
 * @param props 
 * @returns 
 */
export function AdressePostaleCompleteColumn<T>(props: ColumnType<T>) {

    if (!props.row) {
        return <></>;
    }
    const adresse = props.row![props.field!] as AdresseDto
    return <div style={{ whiteSpace: "pre-line" }}>
        {getAdresseComplete(adresse)}
    </div>
};

export function AdressePostaleComplete<T>(props: { adresse: AdresseDto | undefined | null }) {

    if (!props.adresse) {
        return <></>;
    }

    return <div style={{ whiteSpace: "pre-line" }}>
        {getAdresseComplete(props.adresse)}
    </div>


};

export function getAdresseComplete(adresse: AdresseDto) {

    if (!adresse) {
        return "";
    }

    let adresseComplete = "";

    if (adresse?.adressel1) {
        adresseComplete = adresse?.adressel1 + "\n";
    }
    if (adresse?.adressel2) {
        adresseComplete += adresse?.adressel2 + "\n"
    }
    if (adresse?.adressel3) {
        adresseComplete += adresse?.adressel3 + "\n"
    }

    if (adresse?.adressel4) {
        adresseComplete += adresse?.adressel4 + "\n"
    }

    if (adresse?.ville) {
        adresseComplete += adresse?.ville?.cp;
        if (adresse?.ville?.libelle)
            adresseComplete += " " + adresse?.ville?.libelle;

    }

    return adresseComplete
}
