import { ClickableColumn } from "@6tm/leeloo-react/component/dataTable/column/ClickableColumn";
import { CustomRenderColumn } from "@6tm/leeloo-react/component/dataTable/column/CustomRenderColumn";
import { DateColumn } from "@6tm/leeloo-react/component/dataTable/column/DateColumn";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { useDataProvider } from "@6tm/leeloo-react/component/hook/useDataProvider";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouterFileLink } from "@6tm/leeloo-react/component/router/RouterFileLink";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import moment from "moment";
import { useEffect, useState } from "react";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { TypeDocumentEnum } from "../../../enum/document/TypeDocumentEnum";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { SiteDocumentType } from "../../../model/theme/document/SiteDocumentType";
import { DossierDto, EquipementDossierDto, SiteDocumentDto } from "../../../_generated/clientdto.generated";
import { BaseContainer } from "../../layout/BaseContainer";

export const GenerateurDocumentsPage = () => {
    const { currentParamsRoute } = useRouter();


    const [conforme, setConforme] = useState(null)
    const [dateControle, setDateControle] = useState(moment().format())
    // SynthMaint_RegDossier_RegSite_FicEqmt_FicCtrl_FicMaint_A1D249
    const [samaFile, setNameFile] = useState("Document.pdf")

    const dsDocument = useDataSource({
        resource: ResourceDataProviderEnum.DOCUMENT, params: {
            id: currentParamsRoute?.id, pagination: { page: 1, take: 50 },
            filter: { dateControle: dateControle, maintenance: conforme }
        },
        // avant la requete on bind le uriParameters sur les filter
        applyParamsBeforeRefresh: (p) => {
            p.uriParameters = { ...p.filter };
            delete p.filter;
        },
        profilerName: "registre"
    }
    );

    const dsDossier = useDataSource<DossierDto>({
        resource: ResourceDataProviderEnum.DOSSIER,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });

    const oneDataDossier = (dsDossier.oneData as DossierDto);

    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb
        if (breadcrumb.isReady && oneDataDossier) {
            breadcrumb.updateNode(1, {
                textCustom: `Dossier : ${oneDataDossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.id
                }
            });
        }

    }, [breadcrumb.isReady && dsDossier.isReady])

    const router = useRouter();


    const onClickMaintenance = (value: any) => {
        let url = "";
        const data = value as EquipementDossierDto;
        switch (data.typeControle) {
            case ControleTypeEnum.ControlePrincipal:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_ANNUEL_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_ANNUEL_EDIT;
                }
                break;
            case ControleTypeEnum.ControleOperationnel:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT;
                }
                break;
            case ControleTypeEnum.ControleRoutine:
                if (!value.ficheMaintenances) {
                    url = RouteEnum.MAINTENANCE_ROUTINE_CREATE
                } else {
                    url = RouteEnum.MAINTENANCE_ROUTINE_EDIT;
                }
                break
            default:
                return 'Contrôle inconnu';
        }
        router.goTo(url, {
            dossierId: currentParamsRoute.id,
            siteId: getSiteIdByEquipement(data?.eqmtId!),
            espaceId: data?.espaceId,
            EqmtId: data?.eqmtId,
            CtrlID: data?.controleId,
            id: value?.ficheMaintenances?.id
        })

    }

    const getSiteIdByEquipement = (id: number | null) => {

        return getSiteByEquipement(id).id;
    }

    const getSiteByEquipement = (id: number | null) => {

        let sites = dsDocument.dataList.filter(function (siteDossier) {
            const values = siteDossier as SiteDocumentDto;
            return values.equipements?.find(d => d.eqmtId == id)
        });
        var valueSite = sites as SiteDocumentDto[]
        return valueSite[0];
    }
    const onClickControle = (value: EquipementDossierDto) => {
        let url = "";
        switch (value.typeControle) {
            case ControleTypeEnum.ControlePrincipal:
                url = RouteEnum.CONTROLE_EDIT;
                break;
            case ControleTypeEnum.ControleOperationnel:
                url = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;
                break;
            case ControleTypeEnum.ControleRoutine:
                url = RouteEnum.CONTROLE_EDIT_ROUTINE;
                break
            default:
                return 'Contrôle inconnu';
        }
        router.goTo(url, {
            dossierId: currentParamsRoute.id,
            siteId: getSiteIdByEquipement(value?.eqmtId!),
            espaceId: value?.espaceId,
            EqmtId: value?.eqmtId,
            id: value.controleId
        })

    }

    const [typeDocument, SetTypeDocument] = useState([]);
    const [options, setOptions] = useState<SiteDocumentType[]>([]);

    const dp = useDataProvider();

    function getFileName(d: any) {
        let namePdf = "";
        d.sort().map((element: any) => {
            switch (element) {
                case TypeDocumentEnum["Synthèse des maintenances à effectuer"]:
                    namePdf += "SynthMaint_";
                    break;
                case TypeDocumentEnum["Organiser la synthèse par site"]:
                    namePdf += "SynthMaintDS_";
                    break;
                case TypeDocumentEnum["Registre de sécurité du dossier"]:
                    namePdf += "RegDossier_";
                    break;
                case TypeDocumentEnum["Registre de sécurité par site"]:
                    namePdf += "RegSite_";
                    break;
                case TypeDocumentEnum["Plans de sites"]:
                case TypeDocumentEnum["Plans d'espaces"]:
                    break;
                case TypeDocumentEnum["Fiches d'identification des équipements"]:
                    namePdf += "FicEqmt_";
                    break;
                case TypeDocumentEnum["Fiches de contrôle"]:
                    namePdf += "FicCtrl_";
                    break;
                case TypeDocumentEnum["Fiches de maintenance"]:
                    namePdf += "FicMaint_";
                    break;
                default:
                    break;
            }
        })

        if (namePdf) {
            namePdf += ".pdf";
            setNameFile(namePdf)
        }
    }
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOSSIERS_LIST_SITES_CONSULTATION} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>

                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.DOCUMENTS} params={{ id: currentParamsRoute?.id }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="row mt-3">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Générateur de documents</h3>
                        </div>
                        <div className="card-body centerHeader">
                            <DataTable
                                dataSource={dsDocument}
                                filter={
                                    <Filter>
                                        <DatePickerField label="Etat du dossier à la date du" name="dateControle" onChangeValue={(d) => { setDateControle(d) }} />
                                        <SelectField
                                            label="Maintenance"
                                            name="maintenance"
                                            optionsList={[
                                                { label: "Traitée", value: true },
                                                { label: "Non traitée", value: false }
                                            ]}
                                            onChangeValue={(d) => { setConforme(d) }}
                                        />
                                    </Filter>
                                }
                                className={'bg-white'}
                                actionHeader={
                                    <>
                                        <SelectField
                                            label="Type de document"
                                            name="typeDocument"
                                            optionsEnum={TypeDocumentEnum}
                                            isMulti
                                            onChangeValue={(d) => {
                                                SetTypeDocument(d)
                                                getFileName(d);
                                            }}
                                        />

                                        <div style={{ marginTop: 29 }}>
                                            <RouterFileLink

                                                nameFile={samaFile}
                                                resource={ResourceDataProviderEnum.DOCUMENT_DOWNLOAD}
                                                params={{
                                                    id: currentParamsRoute.id,
                                                    data: options,
                                                    uriParameters: {
                                                        typeDocuments: typeDocument,
                                                        dateControle: dateControle
                                                    }
                                                }}
                                                className={typeDocument.length > 0 ? "btn btn-primary" : "btn btn-secondary disabled"}
                                            >
                                                Générer les documents
                                            </RouterFileLink>
                                        </div>
                                    </>

                                }
                                transformCheckedRow={(row: any) => {
                                    return {
                                        id: row.eqmtId || row.id,
                                        siteId: row.id,
                                        eqmts: row.equipements
                                    }
                                }}
                                onChangeCheckedRow={(d: any[], cleanSelection) => {

                                    if (d.length == 0) return;
                                    let newData: SiteDocumentType[] = [];

                                    d.map((element, i) => {

                                        if (element?.siteId == element.id && element.eqmts) {
                                            // le premier element est id du site => on a besoin que des ids equiements 
                                        }
                                        else {
                                            const site = getSiteByEquipement(element.id);

                                            const equipement = site.equipements?.find(s => s.eqmtId == element.id);

                                            if (newData.length == 0) {
                                                newData.push({
                                                    id: site.id!,
                                                    equipements: [{
                                                        id: equipement?.eqmtId,
                                                        typeControle: equipement?.typeControle,
                                                        controleId: equipement?.controleId,
                                                        maintenanceId: equipement?.ficheMaintenances?.id
                                                    }]
                                                })

                                            }
                                            else {
                                                const oneData = newData.find((v: any) => v.id == site.id);

                                                if (oneData) {
                                                    const arr = [...oneData.equipements, {
                                                        id: equipement?.eqmtId,
                                                        typeControle: equipement?.typeControle,
                                                        controleId: equipement?.controleId,
                                                        maintenanceId: equipement?.ficheMaintenances?.id
                                                    }]
                                                    oneData.equipements = arr;
                                                }
                                                else {
                                                    newData.push({
                                                        id: site.id!,
                                                        equipements: [{
                                                            id: equipement?.eqmtId,
                                                            typeControle: equipement?.typeControle,
                                                            controleId: equipement?.controleId,
                                                            maintenanceId: equipement?.ficheMaintenances?.id
                                                        }]
                                                    })
                                                }
                                            }

                                        }
                                    })
                                    setOptions(newData)
                                }}

                                treeChildrenKey="equipements"
                            >
                                <ClickableColumn
                                    title="Num. Site"
                                    field="numero"
                                    columnElement={<SimpleColumn />}
                                    onClick={(r, v) => {
                                        const value = r as SiteDocumentDto;
                                        router.goTo(router.generatePath(RouteEnum.SITES_CONSULTATION, RouteEnum.SITES_CONSULTATION, {
                                            dossierId: currentParamsRoute.id,
                                            id: value.id
                                        }))
                                    }
                                    }
                                />
                                <ClickableColumn
                                    title="Site"
                                    field="nom"
                                    columnElement={<SimpleColumn />}
                                    onClick={(r, v) => {
                                        const value = r as SiteDocumentDto;
                                        router.goTo(router.generatePath(RouteEnum.SITES_CONSULTATION, RouteEnum.SITES_CONSULTATION, {
                                            dossierId: currentParamsRoute.id,
                                            id: value.id
                                        }))
                                    }
                                    }
                                />
                                <SimpleColumn
                                    title="Num. Equipement"
                                    field="eqmtNum"
                                />
                                <ClickableColumn
                                    title="Immat."
                                    field="reference"
                                    columnElement={<SimpleColumn />}
                                    onClick={(r, v) => {
                                        const value = r as EquipementDossierDto;
                                        router.goTo(router.generatePath(RouteEnum.LIST_CONTROLE, RouteEnum.LIST_CONTROLE, {
                                            dossierId: currentParamsRoute.id,
                                            siteId: getSiteIdByEquipement(value?.eqmtId!),
                                            espaceId: value?.espaceId,
                                            id: value?.eqmtId
                                        }))
                                    }
                                    }
                                />
                                <CustomRenderColumn
                                    title="Type contrôle"
                                    field="typeControle"
                                    render={(row, value: number) => {

                                        switch (value) {
                                            case ControleTypeEnum.ControlePrincipal:
                                                return "Contrôle principal"
                                            case ControleTypeEnum.ControleOperationnel:
                                                return "Contrôle opérationnel";
                                            case ControleTypeEnum.ControleRoutine:
                                                return "Contrôle de routine";
                                            default:
                                                return '';
                                        }
                                    }}
                                />
                                <ClickableColumn
                                    title="Réalisé le"
                                    field="dateControle"
                                    columnElement={<DateColumn format="DD/MM/YYYY" />}
                                    onClick={(r) => onClickControle(r)
                                    }
                                />
                                <ClickableColumn
                                    title="Maint. transmise le"
                                    field="ficheMaintenances.dateTransmission"
                                    columnElement={<DateColumn format="DD/MM/YYYY" />}
                                    allowClickable={(r) => r != null}
                                    onClick={(r) => onClickMaintenance(r)
                                    }
                                />
                                <ClickableColumn
                                    title="Maint. signée le"
                                    field="ficheMaintenances.dateSignature"
                                    columnElement={<DateColumn format="DD/MM/YYYY" />}
                                    allowClickable={(r) => r != null}
                                    onClick={(r) => onClickMaintenance(r)
                                    }
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </BaseContainer>
    );
}
