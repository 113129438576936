import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ControleDto } from "../../../_generated/clientdto.generated";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { BaseContainer } from "../../layout/BaseContainer";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import ControleActionNav from "../partiel/ControleActionNav";
import { ControleInformationHead } from "./ControleInformationHead";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { useAuth } from "../../hook/useAuth";

export const ControleEditOrAdd = () => {

    const { currentParamsRoute } = useRouter();

    const dsControle = useDataSource<ControleDto>({
        resource: ResourceDataProviderEnum.CONTROLE_ANNUEL_DATE_CONTROLE,
        params: {
            id: currentParamsRoute.id > 0 ? currentParamsRoute.id : 0,
            uriParameters: {
                eqmtId: currentParamsRoute?.EqmtId
            }
        },
        singleResult: true
    });

    const oneDataControle = (dsControle.oneData as ControleDto);
    const auth = useAuth();
    return (

        <BaseContainer>
            <ControleInformationHead
                controle={oneDataControle}
                eqmtId={currentParamsRoute.EqmtId}
                typeControle={ControleTypeEnum.ControlePrincipal}
                label={null} />

            <ControleActionNav
                resourceDataProviderEnum={ResourceDataProviderEnum.CONTROLE_ANNUEL}
                controleEdit={RouteEnum.CONTROLE_EDIT}
                canEdit={auth.canShowCa(currentParamsRoute.dossierId)}
                controle={oneDataControle} />
        </BaseContainer>

    );



}


