import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { DossierDto } from "../../../_generated/clientdto.generated";
import { BaseContainer } from "../../layout/BaseContainer";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { useAuth } from "../../hook/useAuth";

export const DossierListPage = () => {

    const dsDossiers = useDataSource({ resource: ResourceDataProviderEnum.DOSSIER, params: { pagination: { page: 1, take: 50 } }, profilerName: "dossier" });
    const auth = useAuth();
    return (
        <BaseContainer>
            <div className="card">
                <div className="card-body">
                    <DataTable
                        dataSource={dsDossiers}
                        filter={
                            <Filter>
                                <InputField label="Rechercher" name="search" typeFilter="like" />
                            </Filter>
                        }
                        allowDelete={(row) => auth.isAdmim() == true}
                        actionRow={(r) => [
                            <LinkColumnAction
                                label="Consulter"
                                route={RouteEnum.DOSSIERS_CONSULTATION}
                                params={{ id: r.id }}
                                className="btn btn-sm btn-outline-primary"
                                icon="uil uil-eye"
                            />,
                            ...auth.canEdit(r.id) === true ?
                                [<LinkColumnAction
                                    label="Editer"
                                    route={RouteEnum.DOSSIERS_EDIT}
                                    params={{ id: r.id }}
                                    className="btn btn-sm btn-outline-primary"
                                    icon="uil uil-edit"
                                />] : []

                        ]}
                        addRoute={auth.isAdmim() == true ? RouteEnum.DOSSIERS_ADD : undefined}
                    >
                        <SimpleColumn<DossierDto> field="reference" title="Reference" sortable />
                        <SimpleColumn<DossierDto> field="nom" title="Nom du dossier" sortable />
                        <SimpleColumn<DossierDto> field="interloc" title="Interlocuteur" sortable />
                    </DataTable>
                </div>
            </div>

        </BaseContainer>
    );
};
