

import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EspaceDto, SiteDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouteEnum } from "../../../enum/RouteEnum";
import { useEffect } from "react";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";

export const EspaceConsultationPage = () => {

    const { currentParamsRoute } = useRouter();

    const dsEspace = useDataSource<EspaceDto>({
        resource: ResourceDataProviderEnum.ESPACES,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });
    const oneDataEspace = (dsEspace.oneData as EspaceDto);


    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.siteId
        },
        singleResult: true
    });
    const oneDataSite = (dsSite.oneData as SiteDto);

    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb
        if (breadcrumb.isReady && oneDataSite && oneDataEspace) {

            breadcrumb.updateNode(1, {
                textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.dossierId
                }
            });
            breadcrumb.updateNode(2, {
                textCustom: `Site : ${oneDataSite?.nom}`, route: RouteEnum.SITES_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.siteId,
                    dossierId: currentParamsRoute.dossierId
                }
            });

            breadcrumb.updateNode(3, {
                textCustom: `Espace : ${oneDataEspace?.numero}`, route: RouteEnum.ESPACES_CONSULTATION,
                routeParams: {
                    id: currentParamsRoute.id,
                    dossierId: currentParamsRoute.dossierId,
                    siteId: currentParamsRoute.siteId,
                }
            });

            breadcrumb.updateNode(4, {
                route: RouteEnum.ESPACES_LISTE,
                routeParams: {
                    id: currentParamsRoute.siteId,
                    dossierId: currentParamsRoute.dossierId
                }
            });

        }

    }, [breadcrumb.isReady && oneDataSite])

    const { t } = useTrans();
    const toast = useToast();
    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ESPACES_CONSULTATION}
                                        params={{
                                            dossierId: currentParamsRoute.dossierId,
                                            siteId: currentParamsRoute.siteId,
                                            id: currentParamsRoute?.id
                                        }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.LIST_EQUIPEMENT}
                                        params={{
                                            dossierId: currentParamsRoute.dossierId,
                                            siteId: currentParamsRoute.siteId,
                                            id: currentParamsRoute?.id
                                        }}></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="sticky-actions mb-3" is="sticky-actions" style={{ top: "auto" }}>
                <div className="sticky-actions-inner">
                    <div className="d-flex gap-2 align-items-center">
                        <div className="btn-list ms-auto">
                            <RouterLink className="btn" routeName={RouteEnum.ESPACES_LISTE}
                                params={{
                                    dossierId: currentParamsRoute?.dossierId,
                                    id: currentParamsRoute?.siteId,
                                }}><i className="uil uil-angle-left" />Retour</RouterLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Informations</h3>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="form-label info">Espace numéro</label>
                                    <label>{oneDataEspace?.numero}</label>
                                    <label className="form-label info">Constitution du sol</label>
                                    <label>{oneDataEspace?.typeSol?.libelle}</label>

                                    <label className="form-label info">Zone d'évolution</label>
                                    <label>{oneDataEspace?.typeZone?.libelle}</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card" style={{ height: "100%" }}>
                        <div className="card-header">
                            <h3 className="card-title">Plan</h3>
                        </div>
                        <div className="card-body" style={{ textAlign: "center" }}>
                            {oneDataEspace?.urlPlan == null &&

                                "Aucune image"
                            }
                            {oneDataEspace?.urlPlan &&
                                <img
                                    className="img1"
                                    src={oneDataEspace?.urlPlan}
                                    style={{ height: "100%" }}
                                    onClick={() => openInNewTab(oneDataEspace?.urlPlan)}

                                />}

                        </div>
                    </div>
                </div>
            </div>
        </BaseContainer >
    );
}