export const MonAppFr = {
    actions: {
        validate: "Valider",
        add: "Ajouter",
        edit: "Editer",
        cancel: "Annuler",
        disconnect: "Se déconnecter",
        delete: "Supprimer",
        loginAs: "Se connecter",
        save: "Enregistrer",
        uploadFile: "Télécharger un fichier",
        uploadFileMultiple: "Télécharger vos fichiers",
        dragAndDropImgOrPaste: "Vous pouvez cliquez-Déposez l'image.",
        dragAndDropImgOrPasteMultiple: "Vous pouvez cliquez-Déposez les images."
    },
    table: {
        action: "Actions",
        noData: "Aucune donnée à afficher"
    },
    datatable: {
        totalMultiFound: "résultats",
        totalOneFound: "résultat",
        totalNotFound: "aucun résultat",
        rowsPerPage: "Ligne par page",
        rangeSeparator: "sur",
        noDataDisplay: "Aucun résultat trouvé",
        checkedMultiRowsAction: "éléments sélectionnés",
        checkedRowAction: "élément sélectionné"
    },
    message: {
        deleteSuccess: "Suppression de l'élement réussie",
        confirmDelete: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        updateSuccess: "Mise à jour de l'élément effectuée",
        updateSuccessModal: "Mise à jour de l'élément effectuée",
        apiError: "Un erreur réseau s'est produite",
        errorLoginAs: "Un erreur s'est produite lors de la connexion automatique",
        loginFail: "Identifiant/Mot de passe incorrect",
        warningInactivity: "Vous allez bientôt être déconnecté du à votre inactivité",
        apiFormError: "Formulaire incorrect",
    },
    modal: {
        titleConfirm: "Confirmation",
        titleEditElement: "Modifier un élémént",
        titleAddElement: "Ajouter un élément",
        titleAddUser: "Ajouter un utilisateur",
        titleEditUser: "Modifier un utilisateur"
    },
    placeholder: {
        selectElement: "Selectionner un élément",
        inputTreeAdd: "Ajouter un élément dans l'arbre"
    },
    form: {
        nom: "Nom",
        prenom: "Prenom",
        fonction: "Fonction",
        email: "Email",
        password: "Mot de passe",
        login: "Login",
        personalInfo: "Informations personnelles",
        infosUpdated: "Informations mises à jour !",
        admin: "Administrateur Normetec",
        adresse: {
            adresse1: "Adresse 1",
            adresse2: "Adresse 2",
            adresse3: "Adresse 3",
            adresse4: "Adresse 4",
            ville: "Ville",
            tel: "Téléphone",

        },
        select: {
            noOptionMessage: "Aucun résultat"
        }
    },
    adresse: {
        ville: "Ville"
    },
    errorFallbackPage: {
        title: "Erreur technique inconnue",
        devText: "",
        devActionReload: "Retour",
        prodText: "",
        prodActionReload: "Retour"
    }
};