import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EquipementDto, EspaceDto, SiteDto } from "../../../_generated/clientdto.generated";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { BaseContainer } from "../../layout/BaseContainer";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { RouteEnum } from "../../../enum/RouteEnum";
import { DataTable } from "@6tm/leeloo-react/component/dataTable/DataTable";
import { SimpleColumn } from "@6tm/leeloo-react/component/dataTable/column/SimpleColumn";
import { Filter } from "@6tm/leeloo-react/component/filter/Filter";
import { LinkColumnAction } from "@6tm/leeloo-react/component/dataTable/column/action/LinkColumnAction";
import { BooleanColumn } from "@6tm/leeloo-react/component/dataTable/column/BooleanColumn";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { useBreadcrumb } from "@6tm/leeloo-react/component/hook/useBreadcrumb";
import { useEffect, useState } from "react";
import { useAuth } from "../../hook/useAuth";
import { BooleanColumnIcon } from "../../customColumn/BooleanColumnIcon";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";

export const ListeEquipementsPage = () => {

    const [isole, setIsole] = useState(false)
    const { currentParamsRoute } = useRouter();
    const dsEquipement = useDataSource({
        resource: ResourceDataProviderEnum.EQUIPEMENT,
        params: {
            pagination: { page: 1, take: 50 },
            expand: { field: ["Fabriquant", "TypeCategorie", "TypeEquipement"] },
            filter: { "EspaceId": currentParamsRoute?.id, "isole": isole }
        }, profilerName: "reference"
    });
    const router = useRouter();
    const urlCreate = router.generatePath(RouteEnum.EQUIPEMENT_CREATE, RouteEnum.EQUIPEMENT_CREATE, {
        dossierId: currentParamsRoute.dossierId,
        siteId: currentParamsRoute.siteId,
        espaceId: currentParamsRoute.id
    })

    const dsEspace = useDataSource<EspaceDto>({
        resource: ResourceDataProviderEnum.ESPACES,
        params: {
            id: currentParamsRoute.id
        },
        singleResult: true
    });
    const oneDataEspace = (dsEspace.oneData as EspaceDto);

    const dsSite = useDataSource<SiteDto>({
        resource: ResourceDataProviderEnum.SITES,
        params: {
            id: currentParamsRoute.siteId
        },
        singleResult: true
    });
    const oneDataSite = (dsSite.oneData as SiteDto);
    const breadcrumb = useBreadcrumb();
    useEffect(() => {
        // on met l'id du dossier dans le breacrumb

        if (breadcrumb.isReady && oneDataSite && oneDataEspace) {

            const routes = [
                {
                    route: RouteEnum.APP_HOME,
                    icon: "home"
                },
                {
                    textCustom: `Dossier : ${oneDataSite?.dossier?.reference}`, route: RouteEnum.DOSSIERS_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: `Site : ${oneDataSite?.nom}`, route: RouteEnum.SITES_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.siteId,
                        dossierId: currentParamsRoute.dossierId
                    }
                },
                {
                    textCustom: `Espace : ${oneDataEspace?.numero}`,
                    route: RouteEnum.ESPACES_CONSULTATION,
                    routeParams: {
                        id: currentParamsRoute.id,
                        dossierId: currentParamsRoute.dossierId,
                        siteId: currentParamsRoute.siteId,
                    }
                },
                {
                    textCustom: "Liste des équipements",
                }
            ];

            breadcrumb.resetAndSet(routes);
        }

    }, [breadcrumb.isReady && oneDataSite])
    const auth = useAuth();
    return (
        <BaseContainer composantHead={
            <div className="navbar-expand-md">
                <div className="navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light border-top">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <RouterLink className="nav-link" routeName={RouteEnum.ESPACES_CONSULTATION}
                                        params={{
                                            id: currentParamsRoute?.id,
                                            siteId: currentParamsRoute.siteId,
                                            dossierId: currentParamsRoute.dossierId
                                        }}>

                                    </RouterLink>
                                </li>
                                <li className="nav-item active">
                                    <RouterLink className="nav-link" routeName={RouteEnum.LIST_EQUIPEMENT}
                                        params={{
                                            id: currentParamsRoute?.id,
                                            siteId: currentParamsRoute?.siteId,
                                            dossierId: currentParamsRoute.dossierId
                                        }}
                                    ></RouterLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }>

            <div className="card">
                <div className="card-body centerHeader">
                    <DataTable
                        dataSource={dsEquipement}
                        filter={
                            <Filter>
                                <InputField label="Rechercher" name="reference" typeFilter="like" />
                                <SelectField
                                    label="Isolé"
                                    name="isole"
                                    optionsList={[
                                        { label: "Non isolé", value: false },
                                        { label: "Isolé", value: true }
                                    ]}
                                    onChangeValue={(d) => { setIsole(d) }}
                                />
                            </Filter>
                        }
                        actionRow={(r) => [
                            <LinkColumnAction
                                label="Consulter"
                                route={RouteEnum.LIST_CONTROLE}
                                params={{
                                    id: r.id,
                                    siteId: currentParamsRoute?.siteId,
                                    dossierId: currentParamsRoute?.dossierId,
                                    espaceId: currentParamsRoute?.id,
                                }}
                                className="btn btn-sm btn-outline-primary"
                                icon="uil uil-eye"
                            />,
                            ...auth.canEdit(currentParamsRoute.dossierId) == true ?
                                [<LinkColumnAction
                                    label="Editer"
                                    route={RouteEnum.EQUIPEMENT_EDIT}
                                    params={{
                                        id: r.id,
                                        siteId: currentParamsRoute?.siteId,
                                        dossierId: currentParamsRoute?.dossierId,
                                        espaceId: currentParamsRoute?.id,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    icon="uil uil-edit"
                                />] : []
                        ]}
                        allowDelete={() => auth.isAdmim() == true}
                        addRoute={auth.canAdd(currentParamsRoute.dossierId) == true ? urlCreate : undefined}
                    >
                        <SimpleColumn<EquipementDto> field="numero" title="Numéro" sortable />
                        <SimpleColumn<EquipementDto> field="reference" title="Référence" sortable />
                        <SimpleColumn field="typeEquipement.libelle" title="Type d'équipement" sortable />
                        <SimpleColumn field="fabriquant.nom" title="Fabriquant" sortable />
                        <BooleanColumnIcon field="conforme" title="Conformité" sortable />
                        <BooleanColumnIcon field="isole" title="Isolé" sortable />
                        <SimpleColumn field="typeCategorie.libelle" title="Catégorie" sortable />
                        <SimpleColumn field="typeEquipement.norme" title="Norme" sortable />
                    </DataTable>
                </div>
            </div>
        </BaseContainer>

    );
}