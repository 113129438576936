import { Destination } from "@rpldy/uploady";
import { AxiosStatic } from "@6tm/leeloo-react/node_modules/axios";
import { JwtAuthProvider } from "@6tm/leeloo-react/authProvider/JwtAuthProvider";
import { LeelooConfig } from "@6tm/leeloo-react/Leeloo";
import { LoginType } from "@6tm/leeloo-react/model/authProvider/LoginType";
import { AuthReducerType } from "@6tm/leeloo-react/model/store/reducer/AuthReducerType";
//import { debug } from "webpack";

/**
 * EXEMPLE D'UN AUTH PROVIDER
 * A ADAPTER SELON USAGE
 */
export const NormetecAuthProvider = (apiUrl: string) => {
	return {
		// reprise des fonctions du provider JWT, adapter/surcharger les méthodes selon le besoin
		...JwtAuthProvider(apiUrl),
		/**
		 * Gère l'authentification a l'API + gestion du token ou autre
		 * @return Une donnée pour indiquer si la connection a réussie, renvoi null si échoue
		 * La donnée sera stocké dans le reducer "auth" de Leeloo pour un usage futur
		 */
		login: async (axios: AxiosStatic, loginData: LoginType): Promise<AuthReducerType | null> => {
			let url = "";
			let params = {};

			if (loginData.autoLoginData) {
				// mode auto login detecté
				url = `${apiUrl}${LeelooConfig.autoConnect?.resourceApiLogin}`;
				params = { externalId: loginData.autoLoginData };
			} else if (loginData.username && loginData.password) {
				// mode auth form
				url = `${apiUrl}/auth/login/seConnecter`;
				params = { username: loginData.username, password: loginData.password };
			} else {
				// aucun des deux detecté, le login a probablement échoué
				throw "message.loginFail";
			}

			// tentative de connexion
			try {
				const r = await axios.post(url, params);
				if (r.status == 200) {
					return r.data;
				} else {
					throw "message.loginFail";
				}
			} catch (e) {
				throw "message.loginFail";
			}
		},
		/**
		 * Gère la configuration de l'objet paramsUpload pour l'accès sécurisé au API
		 * Ce cas diffère du précédent car il utilise sont propre gestionnaire de requete API pour l'envoi de fichier
		 * Imposé par le package @rpldy/uploady, en savoir plus sur : https://react-uploady.netlify.app/docs/api/#destination
		 */
		makeUploadCredential: (paramsUpload: Destination, dataAuth: AuthReducerType) => {
			// assignation du token client
			if (!paramsUpload.headers) {
				paramsUpload.headers = {};
			}
			if (dataAuth.token) {
				paramsUpload.headers['Authorization'] = "Bearer " + dataAuth.token;
			}
		},
	};
};
