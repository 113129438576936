import { Form } from "@6tm/leeloo-react/component/form/Form"
import { Icon } from "@6tm/leeloo-react/component/display/Icon";
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn";
import { CheckField } from "@6tm/leeloo-react/component/form/field/CheckField";
import { TextareaField } from "@6tm/leeloo-react/component/form/field/TextareaField";
import { DataSourceType, useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useEffect, useState } from "react";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { EquipementDto, FabriquantDto, TypeCategorieDto, TypeEquipementDto } from "../../../_generated/clientdto.generated";
import * as Yup from 'yup';
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField";
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField";
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField";
import { UploadFileField } from "@6tm/leeloo-react/component/form/field/UploadFileField";
import { RouteEnum } from "../../../enum/RouteEnum";
import { GoBackButton } from "../partiel/GoBackButton";
import { MimeTypeEnum } from "@6tm/leeloo-react/enum/MimeTypeEnum";
import { useRefreshCurrentPage } from "../../hook/useRefreshCurrentPage";

export const EquipementFormPage = (props: { isCreate: boolean }) => {

    const { currentParamsRoute, goTo } = useRouter();
    const [showMemoIsole, setShowMemoIsole] = useState(false)
    const { t } = useTrans();
    const toast = useToast();
    const dsTypeCategorie = useDataSource<TypeCategorieDto>({
        resource: ResourceDataProviderEnum.TYPE_CATEGORIE, params:
            { expand: { field: [] } }, profilerName: "Libelle"
    });

    const dsTypeEquipement = useDataSource<TypeEquipementDto>({
        resource: ResourceDataProviderEnum.TYPE_EQUIPEMENT, params:
            { expand: { field: [] } }, profilerName: "Libelle"
    });
    const dsFabriquand = useDataSource<FabriquantDto>({
        resource: ResourceDataProviderEnum.FABRIQUANTS, params:
            { expand: { field: [] } }, profilerName: "Nom"
    });

    const [initValue, setInitValue] = useState<EquipementDto>({ espaceId: currentParamsRoute.espaceId, dateCreation: new Date().toISOString(), conforme: false })

    const [typeCategorie, setTypeCategorie] = useState<number>(0);
    const [dsTypeEqu, setDsTypeEqu] = useState<DataSourceType>();


    useEffect(() => {
        if (dsTypeEquipement && typeCategorie > 0) {
            const tableauSelected = dsTypeEquipement.dataList.filter((t: TypeEquipementDto) => t.typeCategorieId == typeCategorie)
            dsTypeEqu!.dataList = tableauSelected;
            setDsTypeEqu(dsTypeEqu)
        }
        else {
            setDsTypeEqu(dsTypeEquipement)
        }

    }, [dsTypeEquipement.isReady, typeCategorie])

    const refresh = useRefreshCurrentPage();
    return (

        <Form
            initialValue={initValue}
            profilerName="formEquipement"
            resource={ResourceDataProviderEnum.EQUIPEMENT}
            id={currentParamsRoute?.id}
            onChangeData={(e, ret) => {
                setInitValue(e)
                if (e && e.isole) {
                    setShowMemoIsole(true)
                }
                else {
                    setShowMemoIsole(false)
                }
            }}
            onSubmit={async (e, ret) => {
                e.urlPhoto = null
            }}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(RouteEnum.EQUIPEMENT_EDIT,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            id: ret.newId.toString()
                        });
                }
                if (e && e.isole) {
                    setShowMemoIsole(true)
                }
                else {
                    setShowMemoIsole(false)
                }
                toast("form.infosUpdated", "success");
                refresh.refreshPage()
            }}
            onDataApiLoaded={async (d: EquipementDto | undefined) => {

                if (d && d.typeCategorie && d.typeCategorie.id) {
                    setTypeCategorie(d.typeCategorie.id)
                }
                if (d && d.isole) {
                    setShowMemoIsole(true)
                }
                else {
                    setShowMemoIsole(false)
                }
            }}
            validationSchema={{
                isole: Yup.bool(),
                isoleMemo: Yup.string().when('isole', {
                    is: true,
                    then: (schema) => schema.trim().required('Ce champ est requis')
                }),
                fabriquant: Yup.object().shape({
                    id: Yup.number()
                        .transform((value) => (isNaN(value) ? undefined : value))
                        .required("Ce champ est requis"),
                }),
                typeEquipement: Yup.object().shape({
                    id: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
                        .required("Ce champ est requis")
                }),
                typeCategorie: Yup.object().shape({
                    id: Yup.number()
                        .transform((value) => (isNaN(value) ? undefined : value))
                        .required("Ce champ est requis")
                }),
                reference: Yup.string().required("Ce champ est requis").nullable(),
            }}
        >

            <div className="d-flex align-items-center gap-1 mb-1">
                <span className="ms-auto">&nbsp;</span>
                <GoBackButton
                    routeName={RouteEnum.LIST_EQUIPEMENT}
                    params={{
                        dossierId: currentParamsRoute.dossierId,
                        siteId: currentParamsRoute.siteId,
                        id: currentParamsRoute.espaceId
                    }} />
                <SubmitBtn className="btn btn-primary" >
                    {t("actions.save")}
                </SubmitBtn>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Général</h3>
                        </div>
                        <div className="card-body">

                            <div className="row">
                                <div className="col-sm-6">
                                    <InputField className="mb-2" label="Numéro" name="numero" type="number" />
                                    <InputField className="mb-2" label="Identification" name="reference" />
                                    <InputField className="mb-2" label="Immatriculation client" name="numeroClient" />

                                    <SelectField
                                        className="mb-3"
                                        name="typeCategorie.id"
                                        label="Catégorie"
                                        isSearchable={true}
                                        onChangeValue={(d) => setTypeCategorie(d)}
                                        // la resource externe sous forme de dataSource pour charger les données
                                        externalResource={{ dataSource: dsTypeCategorie, labelKey: "libelle", valueKey: "id" }}
                                    />

                                    <SelectField
                                        className="mb-3"
                                        name="typeEquipement.id"
                                        label="Type d'équipement"
                                        isSearchable={true}
                                        // la resource externe sous forme de dataSource pour charger les données
                                        externalResource={{
                                            dataSource: typeCategorie > 0 ? dsTypeEqu! : dsTypeEquipement, labelKey: "libelle", valueKey: "id"
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <InputField className="mb-2" label="Date de mise en service" name="miseService" />
                                    <InputField className="mb-2" label="Date de fabrication" name="fabrication" />

                                    <SelectField
                                        className="mb-3"
                                        name="fabriquant.id"
                                        label="Fabriquant"
                                        isSearchable={true}
                                        // la resource externe sous forme de dataSource pour charger les données
                                        externalResource={{ dataSource: dsFabriquand, labelKey: "nom", valueKey: "id" }}
                                    />
                                </div>
                            </div>

                            <div style={{ pointerEvents: props.isCreate ? "none" : "auto", opacity: props.isCreate ? 0.5 : 1 }}>

                                {
                                    props.isCreate &&
                                    <h3 style={{ textAlign: "center" }} >Veuillez enregistrer l'équipement avant d’ajouter une image</h3>
                                }
                                <UploadFileField
                                    name="idFile"
                                    fileType={[MimeTypeEnum.imageAll]}
                                    resourceFile={ResourceDataProviderEnum.EQUIPEMENT_FILE}
                                    paramsComplementFile={{ id: currentParamsRoute.id }}
                                    disabledDeleteApi={true}
                                    loadFiles={{
                                        keyNameFile: "name",
                                        keyUrlImgFile: "url",
                                        useApi: true
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="col-md-6">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Description</h3>
                                </div>
                                <div className="card-body">
                                    <InputField className="mb-2" label="Fixation" name="fixation" />
                                    <InputField className="mb-2" label="Ossature" name="ossature" />
                                    <InputField className="mb-2" label="Dimensions" name="dimensions" />
                                    <InputField className="mb-2" label="Hauteur" name="hauteur" />
                                    <InputField className="mb-2" label="Déport" name="deport" />
                                    <CheckField label="Isoler cet équipement" className="mt-3" name="isole" />
                                    {
                                        showMemoIsole &&
                                        <TextareaField name="isoleMemo" />
                                    }

                                </div>

                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Documents fournis</h3>
                                </div>
                                <div className="card-body">
                                    <CheckField className="mb-2" label="Certificat de conformité" name="certifConf" />
                                    <CheckField className="mb-2" label="Notice utilisation" name="noticeUtil" />
                                    <CheckField className="mb-2" label="Notice de montage" name="noticeMont" />
                                    <CheckField className="mb-2" label="Notice d’entretien" name="noticeEntr" />
                                </div>

                            </div>
                            <br></br>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Informations complémentaires</h3>
                                </div>
                                <div className="card-body">
                                    <TextareaField className="mb-2" name="memo" />
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Form>
    )
}