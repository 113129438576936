import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { useEffect, useState } from "react";
import { ControleTypeEnum } from "../../../enum/actions/ControleTypeEnum";
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../enum/RouteEnum";
import { ControleDto, MaintenanceDto, UtilisateurSiteDto, } from "../../../_generated/clientdto.generated";
import { BaseContainer } from "../../layout/BaseContainer";
import { ControleInformationHead } from "../controle/ControleInformationHead";
import { MaintenanceForm } from "./MaintenanceForm";
import * as Yup from 'yup';
import { useModal } from "@6tm/leeloo-react/component/hook/useModal";
import { useRefreshCurrentPage } from "../../hook/useRefreshCurrentPage";

export const MaintenanceEditOrAdd = () => {

    const { currentParamsRoute, currentRouteName, goTo } = useRouter();
    const toast = useToast();

    const [consultationMode, setConsultationMode] = useState(false);

    let typeControle = ControleTypeEnum.ControlePrincipal;
    let urlEdit = RouteEnum.MAINTENANCE_ANNUEL_EDIT;
    let urlDate = ResourceDataProviderEnum.CONTROLE_ANNUEL_DATE_CONTROLE;
    if (currentRouteName == RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT || currentRouteName == RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE) {
        typeControle = ControleTypeEnum.ControleOperationnel;
        urlEdit = RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT;
        urlDate = ResourceDataProviderEnum.CONTROLE_OPERATIONNEL_DATE_CONTROLE;
    }
    else if (currentRouteName == RouteEnum.MAINTENANCE_ROUTINE_EDIT || currentRouteName == RouteEnum.MAINTENANCE_ROUTINE_CREATE) {
        typeControle = ControleTypeEnum.ControleRoutine;
        urlEdit = RouteEnum.MAINTENANCE_ROUTINE_EDIT;
        urlDate = ResourceDataProviderEnum.CONTROLE_ROUTINE_DATE_CONTROLE;
    }


    const dsControle = useDataSource<ControleDto>({
        resource: urlDate,
        params: {
            id: currentParamsRoute.CtrlID
        },
        singleResult: true
    });
    const oneDataControle = (dsControle.oneData as ControleDto);
    const [isFicheTransmise, setIsFicheTransmise] = useState(false);

    const [idMaintenance, setIdMaintenance] = useState(currentParamsRoute?.id > 0 ? currentParamsRoute?.id : 0);
    const dsMaintenance = useDataSource<MaintenanceDto>({
        resource: ResourceDataProviderEnum.MAINTENANCE,
        params: {
            id: currentParamsRoute?.id > 0 ? currentParamsRoute?.id : 0
        },
        singleResult: true,
        noLoadAfterInit: currentParamsRoute?.id > 0 ? false : true
    });
    const oneDataMaintenance = (dsMaintenance.oneData as MaintenanceDto);

    useEffect(() => {
        if (dsMaintenance.isReady && oneDataMaintenance && oneDataMaintenance.dateTransmission && oneDataMaintenance.utilisateurSite && oneDataMaintenance.utilisateurSite.nom) {
            setIsFicheTransmise(true);
        }
    }, [dsMaintenance.isReady && oneDataMaintenance])

    const [isLoad, setIsLoad] = useState(false);
    const [saveInfo, setSaveInfo] = useState<MaintenanceDto>({
        idControle: currentParamsRoute.CtrlID, typeControle: typeControle, dateTransmission: new Date().toISOString(), dateSignature: new Date().toISOString()
        , intervention: { interventionDate: new Date().toISOString() }
    })

    const { confirm } = useModal();
    // Récupérer les infos de l'utilisateur connecté
    const dsValidation = useDataSource<UtilisateurSiteDto>({
        resource: ResourceDataProviderEnum.USER_CONNECTE,
        params: {
        },
        singleResult: true,
    });
    const oneUserData = (dsValidation.oneData as UtilisateurSiteDto);

    function textConfirmation(arg0: string, isCancel?: boolean) {
        const value = arg0
        var textTrans = "Êtes-vous sûr de vouloir transmettre la fiche en date du";
        if (isCancel) {
            textTrans = "Êtes-vous sûr de vouloir annuler la transmission du"
        }
        const confirmText = `${textTrans} ${new Date(value).toLocaleDateString()} ?`
        return <div>
            {confirmText}
            <dl className="row">
                <dt className="col-sm-3 form-label">Par M.</dt>
                <dd className="col-sm-9">{oneUserData.nom}</dd>

                <dt className="col-sm-3 form-label">Organisation</dt>
                <dd className="col-sm-9">
                    {oneUserData.organisation?.libelle}
                </dd>
            </dl>
        </div>
    }
    const refresh = useRefreshCurrentPage();
    return (
        <BaseContainer>

            <ControleInformationHead
                controle={oneDataControle}
                eqmtId={currentParamsRoute.EqmtId}
                label="Fiche de maintenance"
                typeControle={null} />
            <Form
                initialValue={saveInfo}
                id={currentParamsRoute?.id}
                resource={ResourceDataProviderEnum.MAINTENANCE}
                profilerName="formMaintenance"
                onSubmit={async (e, ret) => {
                    if (ret.submitTag == "valider") {
                        e!.isValid = true;
                    }
                    if (ret.submitTag == "invalider" || ret.submitTag == "enregistrer") {
                        e!.isValid = false;
                    }
                    if (ret.submitTag == "transmettre") {
                        const response = await confirm(textConfirmation(e!.dateTransmission!));
                        if (response) {
                            e!.isFicheTransmise = true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (ret.submitTag == "cancelTransmission") {
                        const response = await confirm(textConfirmation(e!.dateTransmission!, true));
                        if (response) {
                            e!.isFicheTransmise = false;
                            setIsFicheTransmise(false)
                        }
                        else {
                            return false;
                        }
                    }
                    setIsLoad(true)
                }}
                onSubmitEndUpdate={(e, ret) => {
                    // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                    if (ret?.newId) {
                        goTo(urlEdit,
                            {
                                dossierId: currentParamsRoute.dossierId,
                                siteId: currentParamsRoute.siteId,
                                espaceId: currentParamsRoute.espaceId,
                                EqmtId: currentParamsRoute.EqmtId,
                                CtrlID: currentParamsRoute.CtrlID,
                                id: ret.newId.toString()
                            });
                        setIdMaintenance(ret.newId.toString());
                    }
                    else {
                        if (ret.submitTag == "invalider") {
                            setConsultationMode(false)

                        }
                        if (ret.submitTag == "valider") {
                            setConsultationMode(true)

                        }
                        if (ret.submitTag == "transmettre" || ret.submitTag == "valider" || ret.submitTag == "invalider") {
                            dsMaintenance.refreshData();

                        }
                    }

                    toast("form.infosUpdated", "success");
                    setIsLoad(false)
                }}
                onDataApiLoaded={async (d: MaintenanceDto | undefined) => {

                    if (d && d.isValid) {
                        setConsultationMode(d.isValid)
                    }

                    if (d && d.dateTransmission && d.utilisateurSite && d.utilisateurSite.nom) {
                        setIsFicheTransmise(true)
                    }

                    if (d && !d.intervention) {
                        d!.intervention! = { interventionDate: new Date().toISOString() };
                    }
                    if (d && !d.dateSignature) {
                        d.dateSignature = new Date().toISOString();
                    }
                    setIsLoad(false)

                }}
                validationSchema={{

                }}
            >

                <MaintenanceForm
                    consultationMode={consultationMode}
                    isFicheTransmise={isFicheTransmise}
                    maintenance={oneDataMaintenance}
                    isLoad={isLoad}
                    oneUserData={oneUserData}
                    onNewComment={(key, comment) => {
                        if (key == "tache") {

                            setSaveInfo({ tache: comment })
                        } else {
                            setSaveInfo({ resultat: comment })
                        }

                    }}
                />
            </Form>
        </BaseContainer>
    );
}