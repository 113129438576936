import { BaseContainer } from "../../layout/BaseContainer";
import { ControleInformationHead } from "../controle/ControleInformationHead";
import { EquipementFormPage } from "./EquipementFormPage";

export const EquipementAddPage = () => {

    return (

        <BaseContainer>

            <ControleInformationHead
                controle={{}}
                eqmtId={0}
                label="Création d'un équipement"
                typeControle={null} />

            <EquipementFormPage
                isCreate={true} />

        </BaseContainer >

    );
}