export enum TypeDocumentEnum {
    "Synthèse des maintenances à effectuer" = 1,
    "Organiser la synthèse par site" = 2,
    "Registre de sécurité du dossier" = 3,
    "Registre de sécurité par site" = 4,
    "Plans de sites" = 5,
    "Plans d'espaces" = 6,
    "Fiches d'identification des équipements" = 7,
    "Fiches de contrôle" = 8,
    "Fiches de maintenance" = 9
}