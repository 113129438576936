import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn"
import { InputField } from "@6tm/leeloo-react/component/form/field/InputField"
import { Form } from "@6tm/leeloo-react/component/form/Form";
import { useAuthProvider } from "@6tm/leeloo-react/component/hook/useAuthProvider";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink"
import { RouteLeelooEnum } from "@6tm/leeloo-react/enum/RouteLeelooEnum"
import { useState } from "react";
import logo from "./../../../img/logo.png";
import * as Yup from 'yup';

export const ResetPasswordForm = (props: { displayPassOublie: boolean, resourceDataProviderEnum: string, routeEnum: string }) => {

    const { authData } = useAuthProvider()! as any;

    const { currentParamsRoute, currentRouteName, goTo } = useRouter();
    const [saveInfo, setSaveInfo] = useState({ login: currentParamsRoute.token != null ? "" : authData.login, token: currentParamsRoute.token, password: "", confirmPassword: "" })
    const toast = useToast();
    const passwordRegex = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[#!@$%^&*()\/-_+.=~|]){1,}).{8,50}$/;
    const [pass, setPass] = useState("");
    const leastOneUpperCase = /(?=.*?[A-Z])/;
    const leastOneLowerCase = /(?=.*?[a-z])/;
    const leastOneDigit = /(?=.*?[0-9])/;
    const leastOneSpecialCharacter = /(?=.*?[#?!@$%^&*+.()\/_~+=|])/;
    return (
        <Form<any>
            initialValue={saveInfo}
            resource={props.resourceDataProviderEnum}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                goTo(props.routeEnum)
                toast("Votre mot de passe a été mis à jour avec succès", "success");

            }}
            onChangeData={(e, ret) => {

                setPass(e.password)

            }}
            validationSchema={{
                login: Yup.string().trim().required("Ce champ est requis").nullable(),
                password: Yup.string().trim().matches(passwordRegex, "Mot de passe invalide").required("Ce champ est requis").nullable(),
                confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Les mots de passe doivent être identique')
            }}

        >
            <div className="text-center mb-4">
                <img src={logo} alt="Normetec" width={100}></img>
            </div>
            <div className="card card-md">
                <div className="card-body">
                    {
                        props.displayPassOublie == true ?
                            <h2 className="card-title text-center mb-3">Veuillez noter votre nouvel identifiant et choisir un nouveau mot de passe</h2> : <h2 className="card-title text-center mb-3">Réinitialisation de votre mot de passe</h2>
                    }
                    {
                        currentParamsRoute.token && <InputField label="Identifiant" name="login" className="mb-3"
                        />
                    }
                    {
                        !currentParamsRoute.token && <InputField label="Identifiant" name="login" className="mb-3" disabled
                        />
                    }


                    <InputField label="Nouveau mot de passe" name="password" type="password" className="mb-3"
                    />

                    Pour votre sécurité, il faut utiliser un mot de passe contenant : <br />
                    <div style={{ color: pass?.length >= 8 ? "green" : "" }}>
                        - Minimum 8 caractères
                    </div>
                    <div style={{ color: leastOneUpperCase.test(pass) ? "green" : "" }}>
                        - Minimum une majuscule
                    </div>
                    <div style={{ color: leastOneLowerCase.test(pass) ? "green" : "" }}>
                        - Minimum une minuscule
                    </div>
                    <div style={{ color: leastOneDigit.test(pass) ? "green" : "" }}>
                        - Minimum un chiffre
                    </div>
                    <div style={{ color: leastOneSpecialCharacter.test(pass) ? "green" : "" }}>
                        - Minimum un caractère spécial
                    </div>
                    <InputField label="Ressaisir le nouveau mot de passe" type="password" name="confirmPassword" className="mb-2" />

                    <div className="card card-md">
                        <div className="card-body">
                            <SubmitBtn className="btn btn-primary w-100" label="Modification de votre mot de passe" />
                        </div>
                    </div>
                    {
                        props.displayPassOublie &&
                        <div className="mb-3">
                            <RouterLink routeName={RouteLeelooEnum.requestForgotPassword} className="form-text mb-3">
                                Mot de passe oublié ?
                            </RouterLink>
                        </div>
                    }

                </div>
            </div>
        </Form>
    )
}