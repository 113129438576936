import { Icon } from "@6tm/leeloo-react/component/display/Icon"
import { DatePickerField } from "@6tm/leeloo-react/component/form/field/DatePickerField"
import { SelectField } from "@6tm/leeloo-react/component/form/field/SelectField"
import { TextareaField } from "@6tm/leeloo-react/component/form/field/TextareaField"
import { ContextForm } from "@6tm/leeloo-react/component/form/Form"
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource"
import { useModal } from "@6tm/leeloo-react/component/hook/useModal"
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter"
import { useContext, useState } from "react"
import { ResourceDataProviderEnum } from "../../../enum/ResourceDataProviderEnum"
import { FournisseurDto, MaintenanceDto, UtilisateurSiteDto } from "../../../_generated/clientdto.generated"
import { useAuth } from "../../hook/useAuth"
import { ZoneObservationComOffCanvas } from "../../offCanvas/ZoneObservationComOffCanvas"
import { FormHead } from "../partiel/FormHead"
import { SubmitBtn } from "@6tm/leeloo-react/component/form/action/SubmitBtn"
import { RouteEnum } from "../../../enum/RouteEnum"

type Props = {
    consultationMode: boolean,
    isFicheTransmise: boolean,
    maintenance: MaintenanceDto,
    oneUserData: UtilisateurSiteDto,
    isLoad: boolean,
    onNewComment: (nameFieldOutput: string, comment: string) => void,
}

export const MaintenanceForm = (props: Props) => {

    const { currentParamsRoute, currentRouteName, goTo, goBack } = useRouter();

    const clickRetour = () => {

        let url = "";
        // "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controle/:id"
        if (currentRouteName == RouteEnum.MAINTENANCE_ANNUEL_CREATE) {
            url = RouteEnum.CONTROLE_EDIT;
        }
        // "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleRoutine/:id",
        else if (currentRouteName == RouteEnum.MAINTENANCE_ROUTINE_CREATE) {
            url = RouteEnum.CONTROLE_EDIT_ROUTINE;
        }
        // "/dossiers/:dossierId/sites/:siteId/espaces/:espaceId/equipement/:EqmtId/controleOperationnel/:id",
        else if (currentRouteName == RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE) {
            url = RouteEnum.CONTROLE_EDIT_OPERATIONNEL;
        }

        if (url != "") {
            const newUrl = url.replace(":dossierId", currentParamsRoute.dossierId)
                .replace(":siteId", currentParamsRoute.siteId)
                .replace(":espaceId", currentParamsRoute.espaceId)
                .replace(":EqmtId", currentParamsRoute.EqmtId)
                .replace(":id", currentParamsRoute.CtrlID) + '?ori=validation';
            goTo(newUrl);
        }
        else {
            goBack();
        }

    }
    const auth = useAuth();
    const handleShowTache = () => setOffCanvasCommentRef({
        show: true,
        nameRefKpi: offCanvasCommentRef.nameRefKpi,
        nameFieldOutput: "tache"
    });
    const handleShowResultat = () => setOffCanvasCommentRef({
        show: true,
        nameRefKpi: offCanvasCommentRef.nameRefKpi,
        nameFieldOutput: "resultat"
    });

    const [offCanvasCommentRef, setOffCanvasCommentRef] = useState<{
        show: boolean,
        nameRefKpi: string | null,
        nameFieldOutput: string
    }>({
        show: false,
        nameRefKpi: null,
        nameFieldOutput: ""
    });
    const dsFournisseurs = useDataSource<FournisseurDto>({
        resource: ResourceDataProviderEnum.FOURNISSEUR,
        params: {
        }
    });
    const { confirm } = useModal();
    const form = useContext(ContextForm);

    function nameUser() {

        if (!props.isFicheTransmise) {
            return ""
        }

        if (props.maintenance?.utilisateurSite) {
            return props.maintenance?.utilisateurSite?.nom;
        }
        else if (form.values?.utilisateurSite?.nom) {
            return form.values?.utilisateurSite?.nom
        }
        else if (props.isFicheTransmise && props.oneUserData.nom) {
            return props.oneUserData.nom
        }
    }
    function nameUserSignataire() {

        if (props.maintenance?.utilisateurSignataire) {
            return props.maintenance?.utilisateurSignataire?.nom;
        }
        else if (form.values?.utilisateurSignataire?.nom) {
            return form.values?.utilisateurSignataire?.nom
        } else if (form.values?.isValid && props.oneUserData.nom) {
            return props.oneUserData.nom
        }
    }
    function organisationLabel() {
        if (!props.isFicheTransmise) {
            return ""
        }
        if (props.maintenance?.utilisateurSite?.organisation?.libelle) {
            return props.maintenance?.utilisateurSite?.organisation?.libelle;
        }
        else if (form.values?.utilisateurSite?.organisation?.libelle) {
            return form.values?.utilisateurSite?.organisation?.libelle
        } else if (props.isFicheTransmise && props.oneUserData?.organisation?.libelle) {
            return props.oneUserData?.organisation?.libelle
        }
    }

    return (

        <div>
            <FormHead
                enabledNextBtn={false}
                prependSaveComponent={<>
                    <button className="btn btn-link" onClick={() => clickRetour()}><Icon name="keyboard_arrow_left" /> Retour</button>
                    {!props.consultationMode && !props.isFicheTransmise &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Transmettre" tagSubmit="transmettre" />
                    }
                    {!props.consultationMode && props.isFicheTransmise &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Annuler la transmission" tagSubmit="cancelTransmission" />
                    }
                    {!props.consultationMode &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Enregistrer" tagSubmit="enregistrer" />
                    }
                    {!props.consultationMode && props.isFicheTransmise &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Valider" tagSubmit="valider" />
                    }
                    {props.consultationMode && auth.isAdmim() &&
                        <SubmitBtn className="btn btn-brand-primary" icon="save" label="Invalider" tagSubmit="invalider" />
                    }
                </>} />
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="col-md-8">
                                <h3 className="card-title">Mesures correctives à mettre en oeuvre</h3>
                            </div>
                            <div className="col-md-4" style={{ textAlign: "right" }}>
                                {
                                    !props.consultationMode &&
                                    <h3 className="card-title"><button className="btn btn-brand-primary" onClick={handleShowTache}>
                                        <Icon name="uil uil-list-ul" /> Commentaire</button></h3>
                                }
                            </div>

                        </div>
                        <div className="card-body">
                            <TextareaField<MaintenanceDto> name="tache" disabled={props.consultationMode} minRows={4} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <div className="col-md-8">
                                <h3 className="card-title">Résultat des mesures correctives</h3>
                            </div>
                            <div className="col-md-4" style={{ textAlign: "right" }}>
                                {
                                    !props.consultationMode && <h3 className="card-title"><button className="btn btn-brand-primary" onClick={handleShowResultat}>
                                        <Icon name="uil uil-list-ul" /> Commentaire</button></h3>
                                }

                            </div>
                        </div>
                        <div className="card-body">
                            <TextareaField<MaintenanceDto> name="resultat" disabled={props.consultationMode} minRows={4} />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Transmission</h3>
                        </div>
                        <div className="card-body">
                            <DatePickerField<MaintenanceDto>
                                label="Fiche Transmise le"
                                name="dateTransmission"
                                className="col-md-6 mb-3"
                                disabled={props.isFicheTransmise}
                            />
                            <dl className="row">
                                <dt className="col-sm-2 form-label">Par M.</dt>
                                <dd className="col-sm-9">{nameUser()}</dd>

                                <dt className="col-sm-2 form-label">Organisation</dt>
                                <dd className="col-sm-9">
                                    {organisationLabel()}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Travaux effectués</h3>
                        </div>
                        <div className="card-body">
                            <DatePickerField
                                label="Travaux effectués le"
                                name="intervention.interventionDate"
                                className="col-md-6 mb-3"
                                disabled={props.consultationMode}
                            />

                            <SelectField
                                className="mb-3"
                                name="intervention.fournisseurId"
                                label="Par"
                                isSearchable={true}
                                disabled={props.consultationMode}
                                // la resource externe sous forme de dataSource pour charger les données
                                externalResource={{ dataSource: dsFournisseurs, labelKey: "nom", valueKey: "id" }}
                            />

                            <DatePickerField
                                label="Validé le"
                                name="dateSignature"
                                className="col-md-6 mb-3"
                                disabled={props.consultationMode}
                            />
                            <dl className="row">
                                <dt className="col-sm-2 form-label">Par</dt>
                                <dd className="col-sm-9">{nameUserSignataire()}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>


            <ZoneObservationComOffCanvas
                {...offCanvasCommentRef}
                idEquipement={currentParamsRoute.EqmtId}
                nameRefKpi="Bibliothèque de commentaires"
                handleClose={() => setOffCanvasCommentRef({
                    show: false,
                    nameFieldOutput: "",
                    nameRefKpi: "Bibliothèque de commentaires"
                })}
                onNewComment={(comment) => {

                    props.onNewComment(offCanvasCommentRef.nameFieldOutput, comment)
                }}
            />

        </div>
    )

}