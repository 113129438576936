import { RouterLink } from "@6tm/leeloo-react/component/router/RouterLink";
import { RouterMenuLink } from "@6tm/leeloo-react/component/router/RouterMenuLink";
import { MenuRouteConfig } from "../../../config/MenuRouteConfig";

/**
 * Menu Gauche
 * @returns
 */
export const Menu = () => {
	//	const { appAsideForceUnfolded } = useSelector((s: StateType) => s.config);
	return (
		<nav className="main-nav js-main-nav">
			<RouterMenuLink />
		</nav>
	);
};
