
import { useDataSource } from "@6tm/leeloo-react/component/hook/useDataSource";
import { useRouter } from "@6tm/leeloo-react/component/hook/useRouter";
import { ContextForm, Form } from "@6tm/leeloo-react/component/form/Form";
import { ResourceDataProviderEnum } from "../../../../enum/ResourceDataProviderEnum";
import { RouteEnum } from "../../../../enum/RouteEnum";
import { ControleDto } from "../../../../_generated/clientdto.generated";
import { useAuth } from "../../../hook/useAuth";
import { useTrans } from "@6tm/leeloo-react/component/hook/useTrans";
import { useToast } from "@6tm/leeloo-react/component/hook/useToast";
import { useContext, useEffect, useState } from "react";

import { ControleEditValidationSignatureForm } from "./ControleEditValidationSignatureForm";

type Props = {
    resourceDataProviderEnum: string
    controleEdit: string,
    isValidForm: boolean,
    setSubmitForm: React.MutableRefObject<(typeForm: string) => Promise<void>>;
    onConsultation: (valid: boolean) => void
}
export const ControleEditValidationSignature = (props: Props) => {

    const { currentParamsRoute, goTo } = useRouter();
    const auth = useAuth();
    const { t } = useTrans();
    const toast = useToast();
    const [consultationMode, setConsultationMode] = useState(false);
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    let urlEditMaintenance = RouteEnum.MAINTENANCE_ANNUEL_EDIT;
    let urlCreateMaintenance = RouteEnum.MAINTENANCE_ANNUEL_CREATE;

    if (props.resourceDataProviderEnum == ResourceDataProviderEnum.CONTROLE_OPERATIONNEL) {
        urlEditMaintenance = RouteEnum.MAINTENANCE_OPERATIONNEL_EDIT
        urlCreateMaintenance = RouteEnum.MAINTENANCE_OPERATIONNEL_CREATE
    }
    else if (props.resourceDataProviderEnum == ResourceDataProviderEnum.CONTROLE_ROUTINE) {
        urlEditMaintenance = RouteEnum.MAINTENANCE_ROUTINE_EDIT
        urlCreateMaintenance = RouteEnum.MAINTENANCE_ROUTINE_CREATE
    }

    const [maintenanceUrl, setMaintenanceUrl] = useState<RouteEnum>(urlCreateMaintenance);
    const [maintenanceId, setMaintenanceId] = useState<number>(0);

    const [isControleSigne, setIsControleSigne] = useState(false);

    const [saveInfo, setSaveInfo] = useState<ControleDto>({
        dateSignature: new Date().toISOString(), dateControle: new Date().toISOString(), conforme: false, maintenance
            : false, miseHorsService: false
    })

    useEffect(() => {
        props.onConsultation(consultationMode);
    }, [consultationMode]);

    const dsValidation = useDataSource<ControleDto>({
        resource: props.resourceDataProviderEnum,
        params: {
            id: currentParamsRoute?.id
        },
        singleResult: true,
        noLoadAfterInit: currentParamsRoute?.id > 0 ? false : true
    });
    const oneDataValidation = (dsValidation.oneData as ControleDto);

    return (

        <Form
            initialValue={saveInfo}
            id={currentParamsRoute?.id!}
            resource={props.resourceDataProviderEnum + "/ValidationSignature"}
            profilerName="formControleValidation"
            onSubmit={async (e, ret) => {
                if (ret.submitTag == "valider") {
                    e!.isValid = true;
                }
                if (ret.submitTag == "invalider") {
                    e!.isValid = false;
                }
                if (!e?.eqmtId) {
                    e!.eqmtId = currentParamsRoute.EqmtId;
                }
            }}
            onChangeData={(e, ret) => {
                if (e && e.maintenance && e.utilisateurSite) {

                    setMaintenanceMode(true)
                }
                else {
                    setMaintenanceMode(false)
                }

                if (e) {
                    setSaveInfo(e)
                }

                if (e && e.id! > 0 && e.maintenance && e.isValid) {
                    setMaintenanceMode(true)
                }

            }}
            onSubmitEndUpdate={(e, ret) => {
                // si on a le newId c'est qu'on est sur la apge de creation, donc on veut rediriger vers la page d'edition
                if (ret?.newId) {
                    goTo(props.controleEdit,
                        {
                            dossierId: currentParamsRoute.dossierId,
                            siteId: currentParamsRoute.siteId,
                            espaceId: currentParamsRoute.espaceId,
                            EqmtId: currentParamsRoute.EqmtId,
                            id: ret.newId.toString()
                        });
                }
                if (ret.submitTag == "invalider") {
                    setConsultationMode(false)
                }
                if (ret.submitTag == "valider") {
                    setConsultationMode(true)
                    setIsControleSigne(true)
                }
                if (ret.submitTag == "transmettre") {
                    setIsControleSigne(true)
                    dsValidation.refreshData()
                }

                toast("form.infosUpdated", "success");
            }}
            onDataApiLoaded={async (d: ControleDto | undefined) => {
                if (d && d.isValid) {
                    setConsultationMode(d.isValid)
                }
                if (d && d.maintenance) {

                    setMaintenanceMode(d.maintenance)
                    if (d.ficheMaintenances) {
                        setMaintenanceUrl(urlEditMaintenance)
                        setMaintenanceId(d.ficheMaintenances.id!)
                    }
                }
                if (d && d.dateSignature && d.utilisateurSite && d.utilisateurSite.nom) {
                    setIsControleSigne(true)
                }

                if (d && !d.dateSignature)
                    d!.dateSignature = new Date().toISOString()
            }}
            disabled={!props.controleEdit}
            validationSchema={{
            }}
        >

            <ControleEditValidationSignatureForm
                consultationMode={consultationMode}
                isSignature={isControleSigne}
                maintenanceUrl={maintenanceUrl}
                maintenanceMode={maintenanceMode}
                maintenanceId={maintenanceId}
                dataValidation={oneDataValidation}
                isValidForm={props.isValidForm}
                setSubmitForm={props.setSubmitForm}

            />
        </Form>
    );
}